/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FiEdit2 } from 'react-icons/fi';
import { Tooltip } from 'antd';
import parse from 'html-react-parser';

import { isCurrentUserAnOwner } from '../../utilities/authentication';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { twoDecimalPlaceValue } from '../../helpers/twoDecimalPlaceValue';

const discountCalculate = user => {
  const discCalc = Number(user.customerId.labourRate) * (Number(user.customerId.discount) / 100);
  return discCalc;
};

const BasicInfo = ({ user }) => {
  const [toggle, setToggle] = useState(true);
  const [toggleNotes, setToggleNotes] = useState(true);

  const toggleReadMore = () => {
    setToggle(!toggle);
  };

  const toggleReadMoreNotes = () => {
    setToggleNotes(!toggleNotes);
  };

  return (
    <div className="col-span-4 sm:col-span-4 md:col-span-4 lg:col-span-1 lg:border-r border-gray-3 h-auto pr-5">
      <div className="my-10 flex flex-col">
        <div className="flex flex-row items-center">
          <h6 className="text-text text-h6 font-bold mr-10">
            {user.customerId.userId.firstName} {user.customerId.userId.lastName}
          </h6>
          <Link to={`/customer/${user._id}/update`}>
            <Tooltip placement="topLeft" title="edit">
              <FiEdit2 className="text-base" />
            </Tooltip>
          </Link>
        </div>
        <div className="flex flex-row items-center">
          <span
            className={`rounded-full h-1.5 w-1.5 ${user.customerId.status ? 'bg-green' : 'bg-error'} mr-2`}
          ></span>
          <p className="text-subtitle-2 text-gray-1 font-semibold">
            {user.customerId.status ? 'Active' : 'Inactive'}
          </p>
        </div>
        <div className="flex flex-col mt-5">
          <p className="text-body-2 text-text font-normal">
            {formatPhoneNumberIntl(user.customerId.userId.mobile)}
          </p>
          <p className="text-body-2 text-text font-normal">{user.customerId.userId.email}</p>
        </div>
          <div className="flex flex-col mt-5 w-44">
          <span className="mb-1">
            <p className="text-body-2 text-text font-semibold">
               Shop Labour Rate: ${Number(user.customerId.labourRate || 0).toFixed(2)}
              {/* Shop Labour Rate: ${twoDecimalPlaceValue(user.customerId.labourRate.toString()) || 0} */}
            </p>
            <p className="text-body-2 text-text font-semibold">
              Discount: $
              {Number(discountCalculate(user)).toFixed(2) +
                ` (${Number(user.customerId.discount || 0).toFixed(2)}%)`}
            </p>
          </span>
          <hr className="text-text" />
          <span className="mt-1">
            <p className="text-body-2 text-text font-semibold">
              Labour Rate: $ {Number(user.customerId.labourRate - discountCalculate(user) || 0).toFixed(2)}
            </p>
          </span>
        </div>
      
        {isCurrentUserAnOwner() && (
          <div className="mt-5">
            <p className="text-body-2 text-text">
              <b>Shop&apos;s Private Notes(for Customers): </b>
              <div className={toggleNotes ? 'contentShowLess' : 'contentShowLess hidden-content'}>
                {user.customerId.notes ? parse(user.customerId.notes) : ''}
              </div>
            </p>
            {user.customerId.notes ? (
              <div>
                <span onClick={toggleReadMoreNotes} className="read-or-hide">
                  {user.customerId.notes.length > 60 ? (toggleNotes ? '...read more' : ' show less') : ''}
                </span>
              </div>
            ) : (
              ''
            )}
          </div>
        )}
        <div className="mt-5">
          <p className="text-body-2 text-text notes">
            <b>Customer&apos;s Public Notes: </b>
            <div className={toggle ? 'contentShowLess' : 'contentShowLess hidden-content'}>
              {user.customerId.customerPublicNotes ? parse(user.customerId.customerPublicNotes) : ''}
            </div>
          </p>
          {user.customerId.customerPublicNotes ? (
            <div>
              <span onClick={toggleReadMore} className="read-or-hide">
                {user.customerId.customerPublicNotes.length > 60
                  ? toggle
                    ? '...read more'
                    : ' show less'
                  : ''}
              </span>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="mt-5">
          <p className="text-body-2 text-text">
            <b>Service Count : </b>
            {user.customerId.oilChange}
          </p>
        </div>
        <div className="mt-5">
          <p className="text-text">
            {user.customerId.freeOilChange && (
              <p>{`${user.customerId.userId.firstName} your next oil change is free up to $50.00`}</p>
            )}
          </p>
        </div>

        <div className="mt-5">
          <Link
            to={`/reports/${user._id}`}
            className="text-primary font-bold text-button p-4 rounded-sm border border-primary hover:rounded-none"
          >
            View Reports
          </Link>
        </div>
      </div>
    </div>
  );
};

BasicInfo.propTypes = {
  user: PropTypes.object,
};

export default BasicInfo;
