/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, createContext, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Layout, Menu, Dropdown, Space, Form, Select } from 'antd';
import {RenderContentContextt} from '../../routes/AdminRoute'
import Avatar from '../../assets/avatar_user.jpg';
import { AiFillAppstore } from 'react-icons/ai';

import {
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  CarOutlined,
  CloseCircleOutlined,
  PieChartOutlined,
  ShopOutlined,
  FileOutlined,
  SettingOutlined,
  BranchesOutlined,
  ApartmentOutlined,
  PoweroffOutlined,
} from '@ant-design/icons';
import { AiOutlineHome } from 'react-icons/ai';
import { FiUnlock } from 'react-icons/fi';
import { deleteToken, getTokenPayload } from '../../utilities/token';
import { isCurrentUserACustomer, isCurrentUserABusinessUser } from '../../utilities/authentication';
// import Customer from './Customer/customer';
// import UserFields from './UserFields';
// import SuperAdmin from './SuperAdmin/superAdmin';
// import SuperAdminFields from './SuperAdmin/superAdminFields';
// import CustomerFields from './Customer/customerFields';

const { Header, Sider, Content } = Layout;
export const RenderContentContext = createContext();

const Layout2 = props => {
  const history = useHistory();
  const { id, name, firstName, lastName, email } = getTokenPayload();
  const [, forceUpdate] = useState({});
  const [renderContent, setRenderContent] = useState('');

  const { updatedData,profileImage,editProfile } = useContext(RenderContentContextt)||{};

  const [names, setNames] = useState('');
  const sidebarContent = [
    'Dashboard',
    'customer',
    'business-user',
    'garage-owner',
    'super-admin',
    'garages',
    'vehicles',
    'network-report-requests',
    'invoices',
    'settings',
  ];
  let link = '';
  // if (isCurrentUserAnAdmin()) link = '/admin/dashboard';
  // if (isCurrentUserACustomer() || isCurrentUserABusinessUser()) link = `/customer/${id}`;
  // if (isCurrentUserAnOwner()) link = '/dashboard';

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);


useEffect(() => {
   updatedData()
},[])

  const [collapsed, setCollapsed] = useState('false');

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const handleMenuClick = e => {
    setRenderContent(e.key);
  };

  const logout = () => {
    deleteToken();
    localStorage.clear();
    history.push('/');
  };
  const handleClickHome=()=>{
    history.push('/');
  }
  const handleClick = () => {
    localStorage.setItem('email', email);
    history.push('/user/reset-password');
  };
  const menu = (
    <Menu 
    // arrow={true}
    selectable ={true}
    // trigger={['click']}
      items={
        [
        {
          label: (
           <>
            <AiOutlineHome className="mr-2" />
            <a onClick={handleClickHome}>Home</a>
          </>
          ),
          key: '2',
        },
        {
          label: (
            <>
              <AiFillAppstore className="mr-2" />
              <Link to='/admin/dashboard'>Dashboard
              </Link>
            </>
          ),
          key: '4',
        },
        {
          type: 'divider',
        },
        {
          label: (
           <>
             <FiUnlock className="mr-2" />
                  <a onClick={handleClick}>Reset Password</a>
          </>
          ),
          key: '3',
        },
        {
          label: (
            <Link to="/admin/settings">
              <SettingOutlined />
              Settings
            </Link>
          ),
          key: '0',
        },
        {
          type: 'divider',
        },
        {
          label: (
            <a className="text-danger" onClick={() => logout()}>
              <PoweroffOutlined className="text-danger" /> Logout{' '}
            </a>
          ),
          key: '1',

        }
      ]}
       
    />
  );

  /********/
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const items = [
    getItem(<Link to="/admin/dashboard">Dashboard</Link>, 'dashboard', <PieChartOutlined />),
    getItem('Users', 'sub1', <UserOutlined />, [
      getItem(<Link to="/admin/all-users">All Users</Link>, 'all-users'),
      getItem(<Link to="/admin/customer">Customer</Link>, 'customer'),
      getItem(<Link to="/admin/business-user">Business User</Link>, 'business-user'),
      getItem(<Link to="/admin/garage-owner">Garage Owner</Link>, 'garage-owner'),
      getItem(<Link to="/admin">Admin</Link>, 'super-admin'),
    ]),
    getItem(<Link to="/admin/garages">Garages</Link>, 'garages', <ShopOutlined />),
    getItem(<Link to="/admin/vehicles">Vehicles</Link>, 'vehicles', <CarOutlined />),
    getItem(
      <Link to="/coming-soon">Network Report Requests</Link>,
      'network report requests',
      <BranchesOutlined />
    ),
    getItem(
      <Link to="/coming-soon">Network Average Vehicles</Link>,
      'network average vehicles',
      <ApartmentOutlined />
    ),
    getItem(<Link to="/admin/invoices">Invoices</Link>, 'invoices', <FileOutlined />),
    getItem(<Link to="/admin/settings">Settings</Link>, 'settings', <SettingOutlined />),
  ];

  return (
    <>
      <RenderContentContext.Provider value={{ sidebarContent, setRenderContent }}>
        <div id="components-layout-demo-custom-trigger" className="MainLayout">
          <Layout>
            <Sider className="site-layout-background sidebar-left" trigger={null} collapsible collapsed={collapsed}>
              <div className="logo">
                {''}
                {React.createElement(collapsed ? CloseCircleOutlined : CloseCircleOutlined, {
                  className: 'trigger',
                  onClick: toggle,
                })}
                <img
                  className="w-24"
                  src={require('../../assets/autohound-logo-orange.png').default}
                  onClick={e => history.push('/')}
                  alt="logo"
                />{' '}
              </div>
              <Menu
                theme="light"
                mode="inline"
                defaultSelectedKeys={['1']}
                items={items}
                onClick={e => handleMenuClick(e)}
              />
            </Sider>
            <Layout className="site-layout">
              <Header className="site-layout-background">

                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                  className: 'trigger',
                  onClick: toggle,
                })}
                <Dropdown overlay={menu}   >
                  <a onClick={e => e.preventDefault()}>
                    <Space>
                      <div className="user-profile">
                        {' '}
                        <img
                          className="w-24"
                          // src="https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250"
                          src={profileImage == undefined || profileImage == '' ? Avatar : profileImage}
                          alt="Rounded avatar"
                        />{' '}
                      </div>
                      {localStorage.getItem('Fname') === null ? (
                        <span className="ml-3 mt-2">
                          <span className="text-truncate">{name}</span>
                        </span>
                      ) : (
                        <span className="ml-3 mt-2">
                          <span className="text-truncate">
                            {editProfile.firstName + ' ' + editProfile.lastName}
                          </span>
                        </span>
                      )}
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
              </Header>
              <Content
                className="main-layout site-layout-background"
                style={{
                  margin: '24px 16px',
                  padding: '0px',
                  minHeight: 280,
                }}
              >
                {props.children}
              </Content>
            </Layout>
          </Layout>
        </div>
      </RenderContentContext.Provider>
    </>
  );
};

export default Layout2;
