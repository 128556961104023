/* eslint-disable prettier/prettier */
import React from 'react';
import { TailSpin } from 'react-loading-icons';

const Loader = () => (
  <div className="pt-10 flex justify-center">
    <h5 className="text-h5 text-text font-bold ml-2">
      <TailSpin fill="#E89178" stroke="#E89178" height={50} />
    </h5>
  </div>
);

export default Loader;
