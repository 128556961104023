/* eslint-disable prettier/prettier */
import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

const LinkRow = ({ children, to }) => {
  const history = useHistory();

  const goTo = link => {
    history.push(link);
  };

  return (
    <tr
      onClick={() => goTo(to)}
      className="p-5 text-left bg-white grid grid-cols-4 gap-5 border-b border-secondary custom-row"
    >
      {children}
    </tr>
  );
};

LinkRow.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
};

export default LinkRow;
