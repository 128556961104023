/* eslint-disable prettier/prettier */
export const handleErrorMessages = (e, setFlashMessage) => {
  let message = '';
  if (e.customMessage?.length > 0) {
    message = e.customMessage;
  } else if (!e?.response && e.message?.length > 0) {
    message = e.message;
  } else {
    const hasDetails = e?.response?.data?.error?.details?.length > 0;
    if (!hasDetails) {
      message = e?.response?.data?.error;
    } else {
      message = e?.response.data.error.details[0].message;
    }
  }
  setFlashMessage.error(message);
  return message;
};

export const handleSuccessMessages = (response, setFlashMessage) => {
  let message = response?.customMessage || response?.data?.message;
  setFlashMessage.success(message);
  return message;
};
