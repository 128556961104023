/* eslint-disable prettier/prettier */
export function twoDecimalPlaceValue(value) {
  value = value.toString();
  let result;
  let val = value.split('.');
  if (value && val.length > 1 && val[1].length == 1) {
    result = value + '0';
  } else if (value.indexOf('.') >= 0) {
    result = value.substr(0, value.indexOf('.')) + value.substr(value.indexOf('.'), 3);
  } else if (val.length == 1) {
    result = value + '.00';
  }
  return result;
}
