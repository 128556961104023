/* eslint-disable prettier/prettier */
import HttpService from '../services/http';

const addVehicle = (body, id) => {
  const http = new HttpService(`/vehicle/${id}`);
  return http.post(body);
};

const getVehicle = id => {
  const http = new HttpService(`/vehicle/${id}`);
  return http.get();
};

const updateVehicle = (body, vehicleId) => {
  const http = new HttpService(`/vehicle/${vehicleId}`);
  return http.put(body);
};

const getUserVehicles = userId => {
  const http = new HttpService(`/user/${userId}/vehicles`);
  return http.get(userId);
};

const getCustomerVehicles = customerId => {
  const http = new HttpService(`/vehicle/customers/${customerId}`);
  return http.get(customerId);
}

export default { addVehicle, getVehicle, updateVehicle, getUserVehicles, getCustomerVehicles };
