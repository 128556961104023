/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Box from '../../components/Box/Box';
import OnePage from '../../components/Pages/OnePage';

import { deleteToken } from '../../utilities/token';
import UserAPIs from '../../api/user';
import RequiredAsterisk from '../../components/RequiredAsterisk';
import { useAlert } from 'react-alert';
import { AiFillEye } from 'react-icons/ai';
import { keyupValidation } from '../../helpers/validationMessages';
import { handleErrorMessages, handleSuccessMessages } from '../../helpers/flashMessages';

const ResetPassword = () => {
  const [resetPassword, setResetPassword] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');
  const [errors, setErrors] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const handleErrors = () => {
    let err = { ...errors };
    if (resetPassword.oldPassword == '') err.oldPassword = '* Enter old password';
    else if(resetPassword.oldPassword.length < 7 )err.oldPassword = '* Enter atleast 7-digits';
    else if( resetPassword.oldPassword && resetPassword.oldPassword.length >= 7)err.oldPassword = '';
    if (resetPassword.newPassword == '') err.newPassword = '* Enter new password';
    else if( resetPassword.newPassword.length < 7) err.newPassword = '* Enter atleast 7-digits';
    else if(resetPassword.newPassword && resetPassword.newPassword.length >= 7) err.newPassword = '';
    if (resetPassword.confirmPassword == '') err.confirmPassword = '* Re-enter password';
    else if(resetPassword.confirmPassword.length < 7) err.confirmPassword = '* Enter atleast 7-digits';
    else if(resetPassword.confirmPassword && resetPassword.confirmPassword.length >= 7)err.confirmPassword = '';
    if (
      resetPassword.oldPassword &&
      resetPassword.newPassword &&
      resetPassword.oldPassword == resetPassword.newPassword
    )
      err.newPassword = '* Old password and new password can not be same';
    if (resetPassword.newPassword && resetPassword.confirmPassword && resetPassword.newPassword != resetPassword.confirmPassword)
      err.confirmPassword = '* Confirm password and new password did not match';
    setErrors(err);
  };

  const alert = useAlert();
  const history = useHistory();

  const logout = () => {
    deleteToken();
    history.push('/');
  };

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const [newPasswordShown, setNewPasswordShown] = useState(false);

  const toggleNewPasswordVisiblity = () => {
    setNewPasswordShown(newPasswordShown ? false : true);
  };
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };

  const keyDown = e => {
    if (resetPassword.oldPassword != '') setErrors({ ...errors, oldPassword: '' });
    if (resetPassword.newPassword != '') setErrors({ ...errors, newPassword: '' });
    if (resetPassword.confirmPassword != '') setErrors({ ...errors, confirmPassword: '' });
  };

  const updateUserPassword = async e => {
    e.preventDefault();
    handleErrors();
    if ((resetPassword.confirmPassword && resetPassword.newPassword && resetPassword.oldPassword) != '') {
      try {
        if (resetPassword.newPassword != resetPassword.confirmPassword) {
          setErrors({ ...errors, confirmPassword: 'New password and confirm password should be same!' });
        } else if (resetPassword.oldPassword === resetPassword.newPassword) {
          setErrors({ ...errors, confirmPassword: 'Old password and new password can not be same!' });
        } else {
          const body = {
            email: localStorage.getItem('email'),
            oldPassword: resetPassword.oldPassword,
            newPassword: resetPassword.newPassword,
          };
          const response = await UserAPIs.resetUserPassword(body);
          handleSuccessMessages(response, alert);
          logout();
        }
      } catch (e) {
        // const message = handleErrorMessages(e, alert);
        // setErrors({ ...errors, confirmPassword: e });
        const message = handleErrorMessages(e, alert);
        setError(message);
  
      }
    }
  };

  return (
    <OnePage className="plans">
      <div className="container px-8 mt-10 h-hero">
        <Box title="Reset Password" subtitle="Enter your new password">
          <form onSubmit={e => updateUserPassword(e)} noValidate>
            <div>
              <div className="mt-10 flex flex-col">
                <label className="text-small text-text">
                  Old Password<RequiredAsterisk />
                </label>
                <div className="input-group post">
                  <input
                    onChange={e => setResetPassword({ ...resetPassword, oldPassword: e.target.value })}
                    value={resetPassword.oldPassword}
                    type={passwordShown ? 'text' : 'password'}
                    className="px-4 py-3 mt-1 w-100 bg-background text-body-2 text-subtext"
                    name="password"
                    required
                    placeholder="**********"
                    onKeyDown={handleErrors}
                    onKeyPress={handleErrors}
                    onKeyUp={handleErrors}
                    // minLength="7"
                    // onKeyDown={e => keyDown(e)}
                  />
                  <span className="z-10 absolute text-center text-subtext absolute bg-transparent rounded text-base items-center justify-center w-8 p-2.5 h-100">
                    <button className="btn-viewPass" onClick={togglePasswordVisiblity} type="button">
                      <AiFillEye className="text-sm" />
                    </button>
                  </span>
                  {errors && errors.oldPassword !== '' ? (
                    <p className="text-error font text-sm">{errors.oldPassword}</p>
                  ) : null}
                </div>
                <div className="mt-6 flex flex-col">
                  <label className="text-small text-text">
                    New Password <RequiredAsterisk />
                  </label>
                  <div className="input-group post">
                    <input
                      onChange={e => setResetPassword({ ...resetPassword, newPassword: e.target.value })}
                      value={resetPassword.newPassword}
                      type={newPasswordShown ? 'text' : 'password'}
                      className="px-4 py-3 mt-1 w-100 bg-background text-body-2 text-subtext"
                      name="password"
                      required
                      placeholder="**********"
                      minLength="7"
                      onKeyDown={handleErrors}
                      onKeyPress={handleErrors}
                      onKeyUp={handleErrors}
                      // onKeyDown={e => keyDown(e)}
                    />
                    <span className="z-10 absolute text-center text-subtext absolute bg-transparent rounded text-base items-center justify-center w-8 p-2.5 h-100">
                      <button className="btn-viewPass" onClick={toggleNewPasswordVisiblity} type="button">
                        <AiFillEye className="text-sm" />
                      </button>
                    </span>
                    {errors && errors.newPassword !== '' ? (
                      <p className="text-error font text-sm">{errors.newPassword}</p>
                    ) : null}
                  </div>
                </div>
                <div className="mt-6 flex flex-col">
                  <label className="text-small text-text">
                    Confirm Password <RequiredAsterisk />
                  </label>
                  <div className="input-group post">
                    <input
                      onChange={e => setResetPassword({ ...resetPassword, confirmPassword: e.target.value })}
                      value={resetPassword.confirmPassword}
                      type={confirmPasswordShown ? 'text' : 'password'}
                      className="px-4 py-3 mt-1 w-100 bg-background text-body-2 text-subtext"
                      name="password"
                      required
                      placeholder="**********"
                      minLength="7"
                      onKeyDown={handleErrors}
                      onKeyPress={handleErrors}
                      onKeyUp={handleErrors}
                      // onKeyDown={e => keyDown(e)}
                    />
                    <span className="z-10 absolute text-center text-subtext absolute bg-transparent rounded text-base items-center justify-center w-8 p-2.5 h-100">
                      <button className="btn-viewPass" onClick={toggleConfirmPasswordVisiblity} type="button">
                        <AiFillEye className="text-sm" />
                      </button>
                    </span>
                    {errors && errors.confirmPassword !== '' ? (
                      <p className="text-error font text-sm">{errors.confirmPassword}</p>
                    ) : null}
                  </div>
                </div>
                {/* {error?.length > 0 && <p className="text-sm text-error">{error}</p>} */}
                <button
                  type="submit"
                  className="mt-10 px-5 py-3 text-white rounded-sm bg-primary hover:bg-primary-light"
                >
                  Submit
                </button>

                <Link
                to="/forgot-password"
                className="mt-3 text-sm font-medium text-center underline text-gray-1"
              >
                Forgot/Reset Password?
              </Link>
              </div>
            </div>
          </form>
        </Box>
      </div>
    </OnePage>
  );
};

export default ResetPassword;
