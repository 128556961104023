/* eslint-disable prettier/prettier */
import React from 'react';

import Slogan from './Slogan';
import Footer from '../../components/Footer';
import ServicesSection from './ServicesSection';
import PlansSection from './PlansSection';

import useTitle from '../../hooks/useTitle';

const LandingPage = () => {
  useTitle('Welcome!');

  return (
    <section>
      <Slogan />
      <ServicesSection />
      <PlansSection />
      <Footer />
    </section>
  );
};

export default LandingPage;
