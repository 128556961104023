/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';

import RequiredAsterisk from './RequiredAsterisk';
import moment from 'moment';

const date = moment(new Date()).format('YYYY-MM-DD');

const DateInput = ({ label, name, value, min, onChange, required, disabled,requiredfield }) => (
  <div className="flex flex-col">
    <label className="inline text-small text-text">
      {label}
      {!!required &&  <RequiredAsterisk />}
      {requiredfield && <RequiredAsterisk />}
    </label>
    <input
      onChange={onChange}
      value={value}
      type="date"
      className="px-4 py-3 mt-1 bg-white text-body-2 text-subtext focus:outline-none custom-datepicker-icon form-input rounded"
      name={name}
      placeholder="(MM-DD-YYYY)"
      required={required}
      disabled={disabled}
      max={date}
      min={`${min - 1}-01-01`}
    />
  </div>
);

DateInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  min: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  requiredfield:PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DateInput;
