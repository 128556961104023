/* eslint-disable prettier/prettier */
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from './components/AlertTemplate';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH);

const fonts = [
  {
    family: 'Mulish',
    src: 'url(https://fonts.gstatic.com/s/mulish/v1/1Ptyg83HX_SGhgqO0yLcmjzUAuWexZNR8aevHZ47LTdNwA.woff)',
    weight: 500,
  },
];
import 'react-quill/dist/quill.snow.css';
import 'antd/dist/antd.css';
import './index.css';
import App from './App';

const history = createBrowserHistory();
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '10px',
  // you can also just use 'scale'
  transition: transitions.FADE,
  containerStyle: {
    top: '10%',
    zIndex: 2000,
  },
};

ReactDOM.render(
  <Elements stripe={stripePromise} fonts={fonts}>
    <Router history={history}>
      <AlertProvider template={AlertTemplate} {...options}>
        <App />
      </AlertProvider>
    </Router>
  </Elements>,
  document.getElementById('root')
);
