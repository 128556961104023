/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Layout, Menu, Dropdown, Space, Input, Form, Row, Col } from 'antd';
import { FiArrowLeft } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { Button, Select, AutoComplete } from 'antd';
// import FormHeader from '../../../components/FormHeader';
import TextInput from '../../../../components/TextInput';
import RadioInput from '../../../../components/RadioInput';
import PropTypes from 'prop-types';
import RequiredAsterisk from '../../../../components/RequiredAsterisk';
import AdminAPIs from '../../../../api/admin';
import PhoneInput, { isPossiblePhoneNumber, parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import { handleErrorMessages, handleSuccessMessages } from '../../../../helpers/flashMessages';
import { useAlert } from 'react-alert';

const SuperAdminFields = ({
  operation,
  submitHandler,
  data,
  updateState,
  setAdmin,
  admin,
  phone,
  setPhone,
}) => {
  const customStyles = {
    valueContainer: (base) => ({
      ...base,
      padding: "8px 10px"
    })
  };
  const history = useHistory()
  const updatedData = data;
  const alert = useAlert();
  const [form] = Form.useForm();
  const [options, setOptions] = useState([]);
  const [lastNameSuggestions, setLastNameSuggestions] = useState([])
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [, forceUpdate] = useState({});
  const { Option, OptGroup } = Select;
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
  });
  const emailString = /^\s*(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
  const results = localStorage.getItem('usersName');
  var names = [JSON.parse(results) || []];
  const firstNameOptions = names[0].map((items, i) => {
    return { label: [items.firstName], value: items.firstName, data: items };
  });
  const lastNameOptions = names[0].map((items, i) => {
    return { label: [items.lastName], value: items.lastName, data: items };
  });

  const handleSearch = async (value) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    if(value){
    setTypingTimeout(() =>
      setTimeout(async () => {
        const response = await AdminAPIs.getFirstNameSuggestions(value);
        const {usersName} = response.data;
        let firstNames = [];
        let lastNames = [];
        firstNames = usersName && usersName?.map((data, i) => {
          lastNames.push({key :i, value:data.lastName,})
          return { key :i, value:data.firstName, obj: data}
          })
        setOptions(firstNames);
        setLastNameSuggestions(lastNames);
      }, 1000)
    )};
   
  };
  // const countryCodeIndex = data && data.mobile ? countryCodes().findIndex(d => data?.mobile.substring(1, 4).match('^' + d.code)) : -1;
  // const countryCodeLen = countryCodeIndex !== -1 ? countryCodes()[countryCodeIndex].code.length >= 3 ? 9 : 10 : 10;
  const isValidName = name => {
    return name && /^[a-zA-Z ]{2,30}$/.test(name);
  };

  const handleErrors = e => {
    let err = { ...errors };
    // data = e || data;
    // let phone = phone ? phone : data?.mobile;
    if (data?.firstName == '') err.firstName = '* Enter first name';
    if (data?.firstName !== '' && !isValidName(data?.firstName)) err.firstName = '* Please enter a valid name';
    else if (data?.firstName != '' && isValidName(data?.firstName)) err.firstName = '';
    if (data?.lastName == '') err.lastName = '* Enter last name';
    if (data?.lastName !== '' && !isValidName(data?.lastName)) err.lastName = '* Please enter a valid name';
    else if (data?.lastName != '') err.lastName = '';
    if (data?.email == '' || data?.email == undefined) err.email = '* Enter email';
    else if (!data?.email?.match(emailString)) err.email = '* Enter a valid email';
    else if (data.email != '') err.email = '';
    if (phone == undefined || data?.mobile == ''&& phone == '') err.mobile = '* Enter mobile number';
    else if (!isValidPhoneNumber(phone || data?.mobile)) err.mobile = '* Enter valid phone number';
    else if (isValidPhoneNumber(phone || data?.mobile)) err.mobile = '';
    // console.log((!isValidPhoneNumber(data.mobile||phone)), !(phone.length > 0 && countryCodeLen === 9 ? phone.slice(phone.indexOf(countryCodes()[countryCodeIndex].code) + 1).length >= 9 : phone.slice(phone.indexOf(countryCodes()[countryCodeIndex].code) + 1).length >= 10), phone.slice(phone.indexOf(countryCodes()[countryCodeIndex].code) + 1))
    setErrors(err);
  };
  const handleSubmit = e => {
    e.preventDefault();
    data.mobile = phone || data.mobile;
    setIsValidFirstName(isValidName(data?.firstName));
    setIsValidLastName(isValidName(data?.lastName));
    handleErrors();
    if (
      errors.firstName == '' &&
      errors.lastName == '' &&
      errors.email == '' &&
      isValidPhoneNumber(data.mobile) &&
      // (data.mobile.length > 0 && (countryCodeLen === 9 ? data.mobile.slice(data.mobile.indexOf(countryCodes()[countryCodeIndex].code) + 1).length >= 9 : data.mobile.slice(data.mobile.indexOf(countryCodes()[countryCodeIndex].code) + 1).length >= 10) === true) &&
      (data.email &&
        data.firstName &&
        data.lastName &&
        data.mobile) != ''
    ) {
    if (isValidName(data?.firstName) && isValidName(data?.lastName)) {
        const updatedAdmin = { ...data, mobile: phone };
       submitHandler(updatedAdmin);
    }}
  };

  const handleOnCancelClick = (e) => {
    e.preventDefault()
    history.push("/admin")
  }

  const handlePhone = (e) => {
    setPhone(e);
    handlePhoneErrors(e)
  }
  const handlePhoneErrors = (e) => {
    let err = { ...errors };
    let phone = e;
    if (phone == undefined || phone == '' && data.mobile == '') err.mobile = '* Enter mobile number';
    else if (!isValidPhoneNumber(phone || data?.mobile)) err.mobile = '* Enter valid phone number';
    else if ( isValidPhoneNumber(phone)) err.mobile = '';
    setErrors(err)
  }

  const onChangeError = (event)=>{
    event.persist()
     if (event.target.name === "firstName"){
       if (event.target.value == '') setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Enter first name` }));
       else if (event.target.value !== '' && !isValidName(event.target.value)) setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Please enter a valid name` }));
       else if (event.target.value != '') setErrors((prev) => ({ ...prev, [event?.target?.name]: '' }))
     }
 
     if(event.target.name === "lastName"){
     if (event.target.value == '') setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Enter last name` }));
     else if (event.target.value !== '' && !isValidName(event.target.value)) setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Please enter a valid name` }));
     else if (event.target.value != '') setErrors((prev) => ({ ...prev, [event?.target?.name]: '' }))
     }

     if(event.target.name === "mobile"){
      if(event.target.value == ''||phone == undefined || phone == '' && data.mobile == '') setErrors((prev)=>({...prev, [event?.target?.name]:`* Enter mobile number`}))
      else if (!isValidPhoneNumber((phone || data.mobile))) setErrors((prev)=>({...prev, [event?.target?.name]:`* Enter valid phone number`}))
      else if (isValidPhoneNumber(phone || data.mobile)) setErrors((prev)=>({...prev, [event?.target?.name]:''}))
     }

     if(event.target.name === 'email'){
      if (data.email == '') setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Enter email` }));
      else if (!data.email.match(emailString)) setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Enter a valid email` }));
      else if (data.email != '') setErrors((prev) => ({ ...prev, [event?.target?.name]: `` }));
    }
   }

  return (
    <>
      <div className="filter--outer mb-4">
        <div className="d-flex top--heading1">
          <h2 className="text-2xl font-semibold text-gr  ay-700 dark:text-gray-200">
            <Link to="/admin">
              <FiArrowLeft className="inline-block align-middle mr-2 text-base" />
              <p className="inline-block align-middle">
                {operation === 'add' ? 'Add Admin' : 'Edit Admin'}
              </p>
            </Link>
            {/* </button> */}
          </h2>
        </div>
      </div>
      <div className="FormWrapper">
        <form className="flex flex-col px-5 py-6 bg-white app_form" onSubmit={e => handleSubmit(e)} noValidate>
          <Row className="grid gap-4 grid-cols-1 md:grid-cols-3 sm:grid-cols-2 lg:grid-cols-4">
            <div className="flex flex-col input__wrap">
              {/* <label className="inline text-small text-text mb-1">
                First Name
                <RequiredAsterisk />
              </label>
              <CreatableSelect
                isClearable
                inputProps={{
                  required: true,
                  pattern: '^[a-zA-Z ]{2,30}$',
                  onInvalid: 'this.setCustomValidity("First name must be alphabetical and non-empty")'
                }} 
                options={firstNameOptions}
                name="firstName"
                value={
                  {value: data?.firstName, label: data?.firstName}
                }
                onkeypress={val => {
                  console.log('val', val?.value, val?.label);
                  // data.firstName = val?.value;
                  // const userData = val?.data || {};
                  // (setAdmin || setAdmin)({ ...(admin || data), firstName: val?.value, ...userData});
                  // setPhone(userData?.mobile || data?.mobile)
                  // handleAdminValidation(userData)
                }}
                styles={customStyles}
                onBlur={handleErrors}
              /> */}

            <TextInput
              label="First Name"
              onChange={updateState}
              name="firstName"
              value={data?.firstName}
              required={true}
              placeholder="First name"
              onBlur={onChangeError}
              onkeypress={onChangeError}
            />
              {errors && errors.firstName !== '' ? (
                  <p className="text-error font text-sm">{errors.firstName}</p>
                ) : null}
            </div>
            {/* <div className="flex flex-col input__wrap">
              <label className="inline text-small text-text mb-1">
                Last Name
                <RequiredAsterisk />
              </label>
              <CreatableSelect
                isClearable
                inputProps={{
                  required: true,
                  pattern: '^[a-zA-Z ]{2,30}$',
                  oninvalid: 'this.setCustomValidity("Last name must be alphabetical and non-empty")'
                }}
             
                options={lastNameOptions}
                name="lastName"
                value={
                  {value: data?.lastName, label: data?.lastName}
                }
                onChange={val => {
                  data.lastName = val?.value;
                  const userData = val?.data || {};
                  (setAdmin || setAdmin)({ ...(admin || data), lastName: val?.value, ...userData });          
                }}
                styles={customStyles}
                onBlur={handleErrors}
                onFocus={handleErrors}
              /> */}
            <div>
            <TextInput
              label="Last Name"
              onChange={updateState}
              name="lastName"
              value={data?.lastName}
              required={true}
              placeholder="Last name"
              onBlur={onChangeError}
              onkeypress={onChangeError}
              />
             {errors && errors.lastName !== '' ? (
                  <p className="text-error font text-sm">{errors.lastName}</p>
                ) : null}
            </div>
              {/* {!isValidLastName ? (
                <p className="text-error font text-sm">* Please enter a valid last name</p>
              ) : null} */}
              {/* {errors && errors.lastName !== '' ? (
                  <p className="text-error font text-sm">{errors.lastName}</p>
                ) : null} */}
            {/* </div>  */}
            <div>
              <label>
                Mobile Number
                <RequiredAsterisk />
              </label>
              <PhoneInput
                label="Mobile"
                international
                countryCallingCodeEditable={true}
                defaultCountry="CA"
                onChange={ e => handlePhone(e)}
                name="mobile"
                value={phone != '' ? phone : data?.mobile}
                required={true}
                placeholder="123-456-7890"
                // maxLength="16"
                // minLength="10"
                // onkeypress={handleErrors}
                onBlur={onChangeError}
                onkeypress={onChangeError}
              />
               {errors && errors.mobile !== '' ? (
                  <p className="text-error font text-sm">{errors.mobile}</p>
                ) : null}
            </div>
            <div>
              
              <TextInput
                label="Email"
                onChange={(e) => {updateState(e, 'email')}}
                name="email"
                value={data?.email}
                required={true}
                placeholder="john.doe@mail.com"
                onBlur={onChangeError}
                onkeypress={onChangeError}
              />
              {errors && errors.email !== '' ? (
                    <p className="text-error font text-sm">{errors.email}</p>
                  ) : null}
            </div>
            <div className="flex flex-col">
              <label className="inline text-small text-text mb-1">Status</label>
            <div className="flex flex-row mt-3 mb-10">
              <RadioInput
                label="Active"
                defaultChecked={admin?.status === true}
                onClick={e => updateState(e)}
                value="true"
                name="status"
              />
              <RadioInput
                label="Inactive"
                defaultChecked={admin?.status === false}
                onClick={e => updateState(e)}
                value="false"
                name="status"
              />
            </div>
            </div>
            {/* <TextInput
              label="Street"
              onChange={e => updateState(e)}
              name="street"
              value={admin?.street}
              required={false}
              placeholder="e.g 100 Bayview Ave"
            />
            <TextInput
              label="City"
              onChange={e => updateState(e)}
              name="city"
              value={admin?.city}
              required={false}
              placeholder="Toronto"
            />
            <TextInput
              label="Province"
              onChange={e => updateState(e)}
              name="province"
              value={admin?.province}
              required={false}
              placeholder="Ontario"
            />
            <TextInput
              label="Postal Code"
              onChange={e => updateState(e)}
              name="postalCode"
              value={admin?.postalCode}
              required={false}
              placeholder="e.g M1M 1M1"
            /> */}
          </Row>
          <div className="text-right">
          <button onClick={handleOnCancelClick}
              className="px-5 py-3 mt-5 mx-2 w-36 text-white text-button rounded-sm bg-primary hover:bg-opacity-80"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-5 py-3 mt-5 w-36 text-white text-button rounded-sm bg-primary hover:bg-opacity-80"
            >
              {operation === 'add' ? 'Add Admin' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
SuperAdminFields.propTypes = {
  title: PropTypes.string,
  operation: PropTypes.string,
  submitHandler: PropTypes.func,
  data: PropTypes.object,
  error: PropTypes.string,
  updateState: PropTypes.func,
  setAdmin: PropTypes.func,
  admin: PropTypes.object,
  setPhone: PropTypes.func,
  phone: PropTypes.string,
};

export default SuperAdminFields;