/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiEdit2 } from 'react-icons/fi';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

import {
  isCurrentUserACustomer,
  isCurrentUserAnAdmin,
  isCurrentUserABusinessUser,
  isCurrentUserAnOwner,
} from '../../../utilities/authentication';

import DropDownText from './DropdownText';

const Vehicle = ({ vehicle, customerId }) => {
  const [clicked, setClicked] = useState(false);

  const items = [
    { 'Year Manufactured': vehicle?.yearManufactured },
    { Make: vehicle?.make },
    { Model: vehicle?.model },
    { 'Engine Size': `${vehicle?.engine} L` },
    { 'Body Style': vehicle?.body },
    { VIN: vehicle?.vin },
    { 'A/C': `${vehicle.ac ? 'Yes' : 'No'}` },
    { Color: vehicle?.color },
    { Transmission: vehicle?.transmission },
    { Driver: vehicle?.driverName },
    { Notes: isCurrentUserAnOwner() ? vehicle.notes : vehicle?.customerPrivateNotes },
    // { customerPrivateNotes: vehicle?.customerPrivateNotes },
  ];
  return (
    <>
      <div className="cursor-pointer w-100">
        <div
          className="flex flex-col p-5 text-secondary bg-secondary hover:bg-opacity-80"
          onClick={() => setClicked(!clicked)}
        >
          <div className="items-center">
            <p className="float-left font-semibold text-subtitle-2 text-text green_row_wrap">
              {vehicle.active ? (
                <span className={`rounded-full h-1.5 w-1.5 bg-green mr-2 d-inline-flex`}></span>
              ) : (
                <span className={`rounded-full h-1.5 w-1.5 bg-error mr-2 d-inline-flex`}></span>
              )}
              {`${vehicle.plateNumber ? ` ${vehicle.plateNumber}` : ''}`}
              {`${vehicle.yearManufactured ? ` / ${vehicle.yearManufactured}` : ''}`} / {vehicle.make} /{' '}
              {vehicle.model} / {vehicle.vin}
            </p>
            <Link
              to={`/customer/${customerId}/vehicle/${vehicle._id}/update`}
              className="float-right text-sm font-semibold text-subtext"
            >
              <Tooltip placement="topLeft" title="edit">
                <FiEdit2 className="text-base" />
              </Tooltip>
            </Link>
          </div>
        </div>
        <div
          className={`bg-white h-auto p-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 ${
            clicked ? '' : 'hidden'
          }`}
        >
          {items.map((item, i) => {
            const label = Object.keys(item)[0];
            const value = item[label];

            return <DropDownText key={i.toString()} label={label} value={value} />;
          })}
        </div>
      </div>
    </>
  );
};

Vehicle.propTypes = {
  vehicle: PropTypes.object,
  customerId: PropTypes.string,
};

export default Vehicle;
