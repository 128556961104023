/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';

import RequiredAsterisk from './RequiredAsterisk';

const TextArea = ({
  label,
  subtext,
  name,
  value,
  onChange,
  requiredfield,
  required,
  placeholder = 'Placeholder',
  maxlength,
}) => (
  <div className="flex flex-col w-full col-span-full">
    <label className="inline text-small text-text mb-1">
      {label}
      {required === true && <RequiredAsterisk />}
      {requiredfield === true && <RequiredAsterisk/>}
    </label>
    {subtext?.length > 0 && <p className="italic text-text text-xs">{subtext}</p>}
    <textarea
      onChange={onChange}
      value={value}
      type="text"
      className="px-4 py-3 h-20 bg-white text-body-2 text-subtext focus:outline-none rounded"
      name={name}
      placeholder={placeholder}
      required={required}
      maxLength={maxlength}
    />
  </div>
);

TextArea.propTypes = {
  label: PropTypes.string,
  subtext: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  maxlength: PropTypes.number,
  onChange: PropTypes.func,
  requiredfield :PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default TextArea;
