/* eslint-disable prettier/prettier */
import HttpService from '../services/http';

const getUser = email => {
  const http = new HttpService(`/user/profile/${email}`);
  return http.get();
};

const verifyEmail = email => {
  const http = new HttpService(`/user/verify/email`);
  return http.post({ email });
};

const verifyMobile = mobile => {
  const http = new HttpService(`/user/verify/mobile`);
  return http.post({ mobile });
};

const verifyEnteredPasscode = body => {
  const http = new HttpService(`/user/verify/passcode`);
  return http.post({
    email: body.email,
    passcode: body.passcode,
  });
};

const verifyEnteredOtp = body => {
  const http = new HttpService(`/user/verify/otp`);
  return http.post({
    email: body.email,
    mobile: body.mobile,
    passcode: body.passcode,
  });
};

const registerUser = body => {
  const http = new HttpService(`/user/register`);
  return http.post(body);
};

const loginUser = body => {
  const http = new HttpService(`/user/login`);
  return http.post(body);
};

const addCustomer = body => {
  const http = new HttpService(`/admin/customers`);
  return http.post(body);
};

const getCustomers = filters => {
  const {
    pagination: { current, pageSize },
    search,
    emailType,
    status,
    role,
    sortFilters: { sortField, sortOrder },
  } = filters;
  let queryString = `q=${search}&status=${status}&role=${role}&emailType=${emailType}&limit=${pageSize}&page=${current}&sortField=${
    sortField || 'createdAt'
  }&sortOrder=${sortOrder || 'descend'}`;
  const http = new HttpService(`/admin/customers?${queryString}`);
  return http.get();
};

const getCustomer = id => {
  const http = new HttpService(`/user/profile/${id}`);
  return http.get();
};

const updateCustomer = (body, userId) => {
  const http = new HttpService(`/user/profile/${userId}`);
  return http.post(body);
};

const checkIfUserWithEmailExists = email => {
  const http = new HttpService(`/user/check/${email}`);
  return http.unsecuredGet();
};

const processForgotPassword = email => {
  const http = new HttpService(`/user/forgot-password/${email}`);
  return http.unsecuredPost();
};

const updateUserPassword = body => {
  const http = new HttpService(`/user/update-password`);
  return http.unsecuredPost(body);
};

const resetUserPassword = body => {
  const http = new HttpService(`/user/reset-password`);
  return http.unsecuredPost(body);
};

const changeUserPassword = body => {
  const http = new HttpService(`/user/reset-password`);
  return http.post(body);
};

const uploadImage = body => {
  const http = new HttpService(`/user/profile_pic`);
  return http.put(body);
}
const deleteEmaillogs =(id)=>{
  const http = new HttpService(`/email/delete/${id}`);
  return http.put();
}
const deleteMultipleEmaillogs =([id])=>{
  const http = new HttpService(`/email/delete/${[id]}`);
  return http.put();
}

export default {
  getUser,
  verifyEnteredPasscode,
  verifyEnteredOtp,
  verifyEmail,
  verifyMobile,
  loginUser,
  addCustomer,
  getCustomers,
  getCustomer,
  registerUser,
  updateCustomer,
  uploadImage,
  checkIfUserWithEmailExists,
  processForgotPassword,
  updateUserPassword,
  resetUserPassword,
  changeUserPassword,
  deleteEmaillogs,
  deleteMultipleEmaillogs
};
