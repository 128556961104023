/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiPlus, FiSearch, FiEdit2 } from 'react-icons/fi';
import { Tooltip, Select, Table, Button, Checkbox, Collapse } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import _ from 'lodash';

import UserAPIs from '../../api/user';
import GarageAPIs from '../../api/garage';
import AdminAPIs from '../../api/admin';

import OnePage from '../../components/Pages/OnePage';
import SendEmailFields from './sendEmailFields';

import useTitle from '../../hooks/useTitle';
import { handleErrorMessages, handleSuccessMessages } from '../../helpers/flashMessages';
import { useAlert } from 'react-alert';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { useCallback } from 'react';
//import cross from '../../assets/cross.png'

const Dashboard = () => {
  const [error, setError] = useState({});
  const [table, setTable] = useState([]);
  const [garage, setGarage] = useState('');
  const [isEditingGarage, setIsEditingGarage] = useState(false);
  const [garageName, setGarageName] = useState('');
  const [labourRate, setLabourRate] = useState('');
  const [garageId, setGarageId] = useState('');
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [options, setOptions] = useState({
    search: '',
    status: '',
    role: '',
    emailType: '',
    pagination: { current: 1, pageSize: 10, pageSizeOptions: [10, 20, 50] },
    sortFilters: {},
  });
  const [emailFormData, setEmailFormData] = useState({
    emails: selectedEmails ? selectedEmails : [],
    subject: '',
    files: null,
  });
  const [text, setText] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [collapse, setCollapse] = useState([]);
  const { Option } = Select;
  const { Panel } = Collapse;

  function openCustomerInNewTab(e) {
    e.preventDefault();
    window.open(e.target.getAttribute('href'), '_blank');
  }

  useTitle('Dashboard');
  //const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);
  const columns = [
    {
      title: (
        <Checkbox
          onChange={e => onSelectAll(e)}
          checked={
            selectedRows.length > 0 &&
            _.isEqual(
              _.sortBy(table.map(x => x.user._id)),
              _.sortBy(
                _.intersectionWith(
                  selectedRows,
                  table.map(x => x.user._id),
                  _.isEqual
                )
              )
            )
          }
        />
      ),
      dataIndex: 'user',
      key: 'select',
      // eslint-disable-next-line react/display-name
      render: user => (
        <Checkbox onChange={e => onSelectRow(e, user)} checked={selectedRows.includes(user._id)} />
      ),
    },
    {
      title:"S no.",
      dataIndex: 'sno',
      key:"index",
      render:(value, item, index) => (options.pagination.current - 1) * options.pagination.pageSize + index + 1
    },
    {
      title: 'First Name',
      dataIndex: 'user',
      key: 'firstName',
      sorter: true,
      render: user => `${user.firstName}`,
    },
    {
      title: 'Last Name',
      dataIndex: 'user',
      key: 'lastName',
      sorter: true,
      // className: 'change-table-color',
      render: user => `${user.lastName}`,
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      sorter: true,
      width: '20%',
    },
    {
      title: 'Phone Number',
      dataIndex: 'user',
      key: 'mobile',
      render: user => `${formatPhoneNumberIntl(user.mobile)}`,
    },
    {
      title: 'Email Address',
      dataIndex: 'user',
      key: 'email',
      render: user => `${user.email}`,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: status => `${status ? 'Active' : 'Inactive'}`,
    },
    {
      title: 'Action',
      dataIndex: 'user',
      // eslint-disable-next-line react/display-name
      render: user => (
        <Link to={`/customer/${user?._id}`} onClick={openCustomerInNewTab} className="text-primary underline">
          View Details
        </Link>
      ),
    },
  ];

  const filterSortedTable = e => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    const query = e.target.value;
    const updatedOptions = {
      ...options,
      search: query.trim(),
      pagination: { ...options.pagination, current: 1 },
    };
    setOptions(updatedOptions);
    setTypingTimeout(() =>
      setTimeout(() => {
        getUserList(updatedOptions);
      }, 1000)
    );
  };

  // const removeSearch = (e)=>{
  //   const updatedOptions = {
  //     search: '',
  //     pagination: { ...options.pagination, current: 1 },
  //   };
  //   setOptions(updatedOptions);
  // }
  const updateGarageName = async () => {
    try {
      setIsEditingGarage(false);
      if (garageName !== garage) {
        const response = await AdminAPIs.updateDashboardGarageName(garageId, garageName);
        setGarage(response.data.garage.name);
        setGarageName(response.data.garage.name);
        handleSuccessMessages(response, alert);
      }
    } catch (e) {
      const message = handleErrorMessages(e, alert);
      setError(message);
    }
  };

  const updateLabourRate = async () => {
    if (labourRate) {
      try {
        const response = await AdminAPIs.updateGarageLabourRate({
          garageId,
          labourRate
        });
        localStorage.setItem('globalLabourRate', labourRate);
        handleSuccessMessages(response, alert);
    } catch (e) {
      const message = handleErrorMessages(e, alert);
      setError(message);
    }  
    }
    
  };

  const cancelUpdate = () => {
    setGarageName(garage);
    setIsEditingGarage(!isEditingGarage);
  };

  const getLabourRate =useCallback(async () => {
    try {
      const { data } = await GarageAPIs.getGarageGlobalLabourRate(garageId);
      const globalLabourRate = data.garage?.labourRate ? data.garage?.labourRate : '';
      localStorage.setItem('globalLabourRate', globalLabourRate);
      setLabourRate(globalLabourRate)
    } catch (error) {
      console.error(error);
    }
  }, [garageId]);

  useEffect(() =>{
    getLabourRate();
  }, [getLabourRate])

  const getUserList = async options => {
    try {
      setLoading(true);
      const response = await UserAPIs.getCustomers(options);
      const { customers, name, _id } = response.data.garage;
      setGarage(name);
      setGarageName(name);
      setGarageId(_id);
      setTable(customers.docs);
      setOptions({
        ...options,
        pagination: {
          current: customers.page,
          pageSize: customers.limit,
          total: customers.totalDocs,
          showSizeChanger: true,
        },
      });
    } catch (e) {
      console.error('e');
    }
    setLoading(false);
  };

  const handleChange = (pagination, filters, sorter) => {
    const updatedOptions = { ...options, pagination };
    setOptions(updatedOptions);
    getUserList({
      ...updatedOptions,
      sortFilters: {
        sortField: sorter?.columnKey,
        sortOrder: sorter?.order,
      },
    });
  };

  useEffect(() => {
    getUserList(options);
  }, []);

  useEffect(() => {
    setEmailFormData({ ...emailFormData, emails: selectedEmails });
  }, [selectedEmails.length]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
    setEmailFormData({ emails: selectedEmails ? selectedEmails : [], subject: emailFormData.subject });
  };

  const onSelectAll = e => {
    if (e.target.checked) {
      const test = [...new Set([...selectedRows, table.map(n => n.user._id)].flat())];
      const testEmail = [...new Set([...selectedEmails, table.map(n => n.user.email)].flat())];
      setSelectedRows(test);
      setSelectedEmails(testEmail);
    } else {
      const selectedIds = selectedRows.filter(n => !table.map(x => x.user._id).includes(n));
      const selectedEmail = selectedEmails.filter(n => !table.map(x => x.user.email).includes(n));
      setSelectedRows(selectedIds);
      setSelectedEmails(selectedEmail);
    }
  };
  const onSelectRow = async (e, user) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, user._id]);
      setSelectedEmails([...selectedEmails, user.email]);
    } else {
      const selectedIds = selectedRows.filter(n => n !== user._id);
      const selectedEmail = selectedEmails.filter(n => n !== user.email);
      setSelectedRows(selectedIds);
      setSelectedEmails(selectedEmail);
    }
  };
  const collapseShowHideFunc = key => {
    setCollapse(key);
  };
  const handleFilterClick = () => {
    setCollapse([]);
    getUserList({ ...options, pagination: { ...options.pagination, current: 1 } });
  };
  const handleClearAllClick = () => {
    setCollapse([]);
    const updatedOptions = {
      ...options,
      status: '',
      role: '',
      pagination: { ...options.pagination, current: 1 },
    };
    setOptions(updatedOptions);
    getUserList(updatedOptions);
  };
  return (
    <>
      <OnePage background="background">
        {table ? (
          <div className="px-5 md:px-20 flex flex-col relative mt-10">
            <div className="flex flex-row mb-5 relative flex-wrap">
              <h1 className="text-base text-text font-regular">Garage Name: {garage}</h1>
              <button className="pl-2" onClick={() => setIsEditingGarage(!isEditingGarage)}>
                <Tooltip placement="topLeft" title="edit">
                  <FiEdit2 className="text-base" />
                </Tooltip>
              </button>
              <div className="update-labour-rate-wrapper">
                <input
                  type="number"
                  name="labourRate"
                  placeholder="Labour Rate"
                  className="py-2 px-2 mr-3 text-gray-1 font-normal rounded-r-none"
                  onChange={e => setLabourRate(e.target.value)}
                  required
                  value={labourRate}
                />
                <button
                  onClick={() => updateLabourRate()}
                  className="bg-primary hover:bg-opacity-80 py-2 px-4 text-white rounded-sm "
                >
                  Update Labour Rate
                </button>
              </div>
            </div>
            {isEditingGarage && (
              <form onSubmit={() => updateGarageName()}>
                <div className="flex flex-row flex-wrap justify-start">
                  <input
                    type="text"
                    placeholder="Enter a Garage Name"
                    className="w-60 p-2 text-gray-1 font-normal rounded-r-none my-1.5"
                    onChange={e => setGarageName(e.target.value)}
                    required
                    value={garageName}
                  />
                  <button
                    type="submit"
                    className="bg-primary hover:bg-opacity-80 p-2 text-white text-sm rounded-r-sm my-1.5 ml-1.5"
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    className="bg-secondary p-2 text-text text-sm rounded-r-sm my-1.5 ml-1.5"
                    onClick={() => cancelUpdate()}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            )}
            <h1 className="text-h5 text-text font-semibold my-4">Customers</h1>
            <div className="filter--wrapper flex flex-wrap justify-start items-center">
              <div className="d-inline-flex gap-2 flex-wrap">
                <div className="float-left flex flex-row mr-auto position-relative">
                  <span className="z-10 absolute text-center text-subtext absolute bg-transparent rounded text-base items-center justify-center w-8 p-2.5 h-100">
                    <FiSearch className="text-sm" />
                  </span>
                  <input
                    onChange={e => filterSortedTable(e)}
                    name="customer"
                    placeholder="Find customer"
                    className="pl-8 py-2 px-4 text-gray-1 font-normal rounded-r-none p-r-40"
                  />
                  {/* <button type="button" className="btn bg-transparent cross_btn">
                    <img src={cross} alt="" />
                  </button> */}
                </div>
                <div className="filter_content w-auto d-flex">
                  <Collapse activeKey={collapse} onChange={collapseShowHideFunc} className="margin_0">
                    <Panel
                      showArrow={false}
                      header={
                        <>
                          <FilterOutlined className="text-sm mr-2" />
                          Filters
                        </>
                      }
                      key="1"
                      className="site-collapse-custom-panel"
                    >
                      <div className="card card-body">
                        <div className="row">
                          <div className="col-12 filter_header d-flex flex-wrap position-relative">
                            <h4 className="title">Filter By</h4>
                            <button className="clearfilter_btn btn-default" onClick={handleClearAllClick}>
                              Clear all
                            </button>
                          </div>
                          <div className="col-12 col-md-6 col-lg-4 col-xl-2 fltr_cell">
                            <label className="label">Status</label>
                            <Select
                              className="text-1 font-normal rounded-r-none active-inactive-filter"
                              name="status"
                              defaultValue="all"
                              value={options.status}
                              onChange={e => setOptions({ ...options, status: e })}
                            >
                              <Option value="">All</Option>
                              <Option value={true}>Active</Option>
                              <Option value={false}>Inactive</Option>
                            </Select>
                          </div>
                          <div className="col-12 col-md-6 col-lg-4 col-xl-2 fltr_cell">
                            <label className="label">Role</label>
                            <Select
                              className="text-1 font-normal rounded-r-none active-inactive-filter"
                              name="role"
                              defaultValue="All"
                              value={options.role}
                              style={{ width: '100%' }}
                              onChange={e => setOptions({ ...options, role: e })}
                            >
                              <Option value="">All</Option>
                              <Option value="customer">Customer</Option>
                              <Option value="business_user">Business User</Option>
                            </Select>
                          </div>
                          <div className="col-12 col-md-6 col-lg-4 col-xl-2 fltr_cell">
                            <label className="label">Email</label>
                            <Select
                              className="text-1 font-normal rounded-r-none active-inactive-filter"
                              name="email"
                              defaultValue="All"
                              value={options.emailType}
                              style={{ width: '100%' }}
                              onChange={e => setOptions({ ...options, emailType: e })}
                            >
                              <Option value="">All</Option>
                              <Option value="real">Valid</Option>
                              <Option value="fake">Invalid</Option>
                            </Select>
                          </div>
                          <div className="col-12 col-md-6 col-lg-4 col-xl-2 fltr_cell d-flex justify-content-end">
                            <label className="label"></label>
                            <button
                              type="button"
                              className="bg-primary hover:bg-opacity-80 py-2 px-4 text-white rounded-sm"
                              onClick={handleFilterClick}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </Panel>
                  </Collapse>
                </div>
              </div>
              <div className="d-inline-flex flex-wrap">
                <div className="float-right my-3 mr-3">
                  {selectedRows && selectedRows.length > 0 ? (
                    <button
                      className="bg-primary hover:bg-opacity-80 py-2 px-4 text-white rounded-sm "
                      onClick={showModal}
                    >
                      <p className="inline-block align-middle">Send Email</p>
                    </button>
                  ) : (
                    <button className="bg-secondary_disabled cursor-not-allowed hover:bg-opacity-80 py-2 px-4 text-white rounded-sm ">
                      <p className="inline-block align-middle">Send Email</p>
                    </button>
                  )}
                </div>
                <div className="float-right my-3 mr-3">
                  <button className="bg-primary hover:bg-opacity-80 py-2 px-4 text-white rounded-sm ">
                    <Link to="/email/emailLog"> Email Logs </Link>
                  </button>
                </div>
                <div className="float-right my-3">
                  <button className="bg-primary hover:bg-opacity-80 py-2 px-4 text-white rounded-sm ">
                    <Link to="/customer/add">
                      <FiPlus className="inline-block align-middle mr-2 text-base" />
                      <p className="inline-block align-middle">Add Customer</p>
                    </Link>
                  </button>
                </div>
              </div>
            </div>
            {/* {!!sortedTable && <CustomerTable customers={sortedTable} sortByField={setSortField} />} */}
            <div style={{ marginBottom: 16 }}>
              <Button
                className="bg-primary hover:bg-opacity-80 py-1 px-4 text-white rounded-sm"
                onClick={() => {
                  setSelectedRows([]);
                  setSelectedEmails([]);
                }}
                disabled={!selectedRows.length > 0}
              >
                Reset
              </Button>
              <span style={{ marginLeft: 8 }}>
                {selectedRows.length > 0 ? `Selected ${selectedRows.length} items` : ''}
              </span>
            </div>
            <div className="wktable">
              <Table
                columns={columns}
                rowKey={record => record._id}
                dataSource={table}
                pagination={options.pagination.total > 10 ? options.pagination : false}
                sortDirections={['ascend', 'descend']}
                loading={loading}
                onChange={handleChange}
                scroll={{ scrollToFirstRowOnChange: true }}
              />
            </div>
          </div>
        ) : (
          <h1>{error.message}</h1>
        )}
      </OnePage>
      <SendEmailFields
        emailFormData={emailFormData}
        setEmailFormData={setEmailFormData}
        text={text}
        setText={setText}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        setSelectedEmails={setSelectedEmails}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </>
  );
};

export default Dashboard;
