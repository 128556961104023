/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';

import RequiredAsterisk from './RequiredAsterisk';

const TextInput = ({
  label,
  subtext,
  name,
   value,
  onChange,
  onblur,
  onkeypress,
  requiredfield,
  required,
  maxLength,
  minLength,
  ...rest
}) => (
  <div className="flex flex-col">
    <label className="inline text-small text-text mb-1">
      {label}
      {required === true && <RequiredAsterisk />}
      {requiredfield === true && <RequiredAsterisk />}
    </label>
    {subtext?.length > 0 && <p className="italic text-text text-xs">{subtext}</p>}
    
    <input
      onChange={onChange}
      onKeyUp={onkeypress}
      onBlur={onblur}
      value={value}
      type="text"
      className="px-4 py-3 bg-white text-body-2 text-text focus:outline-none rounded"
      name={name}
      required={required}
      maxLength={maxLength}
      minLength={minLength}
      {...rest}
    />
  </div>
);

TextInput.propTypes = {
  label: PropTypes.string,
  subtext: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onkeypress: PropTypes.func,
  onblur: PropTypes.func,
  requiredfield : PropTypes.bool,
  required: PropTypes.bool,
  minLength: PropTypes.string,
  maxLength: PropTypes.string,
};

export default TextInput;
