/* eslint-disable prettier/prettier */
import jwt from 'jsonwebtoken';

export function getToken() {
  return localStorage.getItem('token');
}

export function setToken(token) {
  localStorage.setItem('token', token);
}

export function getTokenPayload() {
  const currentToken = getToken();
   if(currentToken)
   {
    return jwt.decode(currentToken)?.data;
   }
  else{
    return false
  }


}

export function deleteToken() {
  localStorage.removeItem('token');
}

export const isTokenValid = token => {
  const validatedToken = jwt.decode(token, { complete: true });
  const isTokenExpired = isExpired(token);

  return validatedToken && !isTokenExpired;
};

export const isExpired = token => {
  const isExistingToken = token && jwt.decode(token);
  let isExpired = false;

  if (isExistingToken) {
    const expiry = jwt.decode(token).exp;
    const now = new Date();

    isExpired = now.getTime() > expiry * 1000;
  }

  return isExpired;
};

export const isCurrentTokenValid = () => {
  const token = getToken();

  return token && isTokenValid(token);
};
