/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext } from 'react';
import { Form, Row } from 'antd';
import { FiArrowLeft } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Button, Select } from 'antd';
// import FormHeader from '../../../components/FormHeader';
import TextInput from '../../../components/TextInput';
import PropTypes from 'prop-types';
import RequiredAsterisk from '../../../components/RequiredAsterisk';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
//import { handleErrorMessages, handleSuccessMessages } from '../../../../helpers/flashMessages';
import AdminAPIs from '../../../api/admin';
import { useAlert } from 'react-alert';
import _ from 'lodash';

const GarageFields = ({ operation, submitHandler, data, updateState, setOwner }) => {
  const updatedData = data;
  const [garages, setGarages] = useState([]);
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [adminData, setAdminData] = useState({
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
    mobile: data?.mobile ?? '',
    email: data?.email ?? '',
    garage: data?.garage ?? '',
    role: 'owner',
  });
  const { Option, OptGroup } = Select;
  const [phone, setPhone] = useState(adminData.mobile);
  adminData.mobile = phone;
  function handleChange(value) {
    console.log(`selected ${value}`);
  }
  // const updateState = e => {
  //   const key = e.target.name;
  //   let value = e.target.value;
  //   setAdminData({ ...adminData, [key]: value });
  // };

  // // To disable submit button at the beginning.
  // useEffect(() => {
  //   forceUpdate({});
  // }, []);
  const [collapsed, setCollapsed] = useState('false');
  /********/

  useEffect(() => {
    (async () => {
      try {
        const response = await AdminAPIs.getGarages();
        // setAdmins(response.data.admins);
        const { garages } = response.data;
        setGarages(garages);
      } catch (e) {
        // const message = handleErrorMessages(e);
        // setError(message);
      }
    })();
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    submitHandler(adminData);
  };

  return (
    <>
      <div className="filter--outer mb-4">
        <div className="d-flex top--heading1">
          <h2 className="text-2xl font-semibold text-gr  ay-700 dark:text-gray-200">
            <Link to="/admin/garages">
              <FiArrowLeft className="inline-block align-middle mr-2 text-base" />
              <p className="inline-block align-middle">
                {operation === 'add' ? 'Add Garage' : 'Edit Garage'}
              </p>
            </Link>
            {/* </button> */}
          </h2>
        </div>
      </div>
      <div className="FormWrapper">
        <form className="flex flex-col px-5 py-6 bg-white app_form" onSubmit={e => handleSubmit(e)}>
          <Row className="grid gap-4 grid-cols-1 md:grid-cols-3 sm:grid-cols-2 lg:grid-cols-4">
            <TextInput
              label="First Name"
              onChange={updateState}
              name="firstName"
              value={data?.firstName}
              // value={adminData?.firstName}
              required={true}
              placeholder="John"
            />
            <TextInput
              label="Last Name"
              onChange={updateState}
              name="lastName"
              value={data?.lastName}
              // value={adminData?.lastName}
              required={true}
              placeholder="Doe"
            />
            <div>
              <label>
                Mobile Number
                <RequiredAsterisk />
              </label>
              <PhoneInput
                label="Mobile"
                international
                countryCallingCodeEditable={true}
                defaultCountry="CA"
                // onChange={setPhone}
                name="mobile"
                value={data?.mobile}
                onChange={e => {
                  // e.preventDefault();
                  setOwner({ ...data, mobile: e });
                }}
                // value={phone != '' ? phone : adminData?.mobile}
                required={true}
                placeholder="123-456-7890"
                maxLength="16"
                minLength="10"
              />
            </div>
            <TextInput
              label="Email"
              onChange={updateState}
              name="email"
              value={data?.email}
              // value={adminData?.email}
              required={true}
              placeholder="john.doe@mail.com"
            />
            <TextInput
              label="Garage"
              onChange={updateState}
              name="garage"
              value={data?.ownerId?.garage?.name}
              // value={adminData?.garage}
              required={true}
              placeholder="test"
            />
            {/* <label>
              Garages
              <RequiredAsterisk />
            </label>
            <Select
              className=" mb-4 bg-white text-body-2 text-text focus:outline-none rounded"
              label="Garages"
              name="garages"
              // value={customer?.role}
              onChange={e => setAdminData({ ...adminData, garage: e })}
              required={true}
            >
              {garages.map((garage, i) => (
                <option key={i.toString()} value={garage._id}>
                  {_.startCase(garage.name)}
                </option>
              ))}
            </Select> */}
            {/* <TextInput
              label="Street"
              onChange={e => updateState(e)}
              name="street"
              value={admin?.street}
              required={false}
              placeholder="e.g 100 Bayview Ave"
            />
            <TextInput
              label="City"
              onChange={e => updateState(e)}
              name="city"
              value={admin?.city}
              required={false}
              placeholder="Toronto"
            />
            <TextInput
              label="Province"
              onChange={e => updateState(e)}
              name="province"
              value={admin?.province}
              required={false}
              placeholder="Ontario"
            />
            <TextInput
              label="Postal Code"
              onChange={e => updateState(e)}
              name="postalCode"
              value={admin?.postalCode}
              required={false}
              placeholder="e.g M1M 1M1"
            /> */}
          </Row>
          <div className="text-right">
            <button
              type="submit"
              className="px-5 py-3 mt-5 w-36 text-white text-button rounded-sm bg-primary hover:bg-opacity-80"
            >
              {operation === 'add' ? 'Add Garage' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
GarageFields.propTypes = {
  title: PropTypes.string,
  operation: PropTypes.string,
  submitHandler: PropTypes.func,
  data: PropTypes.object,
  updateState: PropTypes.func,
  setOwner: PropTypes.func,
};

export default GarageFields;
