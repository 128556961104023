/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React from 'react';
import Navbar from '../Navbar/Navbar';

const Layout1 = props => {
  return (
    <>
      <Navbar />
      {props.children}
    </>
  );
};

export default Layout1;
