/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';

import RequiredAsterisk from './RequiredAsterisk';

const NumberInput = ({ label, subtext, name, value, onChange,requiredfield,required, ...rest }) => (
  <div className="flex flex-col">
    <label className="inline text-small text-text">
      {label}
      {required === true && <RequiredAsterisk />}
      {requiredfield ===true &&<RequiredAsterisk />}
    </label>
    {subtext?.length > 0 && <p className="italic text-text text-xs">{subtext}</p>}
    <input
      onChange={onChange}
      value={value}
      type="number"
      className="px-4 py-3 mt-1 bg-white text-body-2 text-subtext focus:outline-none rounded"
      name={name}
      placeholder="Placeholder"
      required={required}
      {...rest}
    />
  </div>
);

NumberInput.propTypes = {
  label: PropTypes.string,
  subtext: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  requiredfield:PropTypes.bool,
  required: PropTypes.bool,
};

export default NumberInput;
