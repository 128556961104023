/* eslint-disable prettier/prettier */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ExpenseBreakdownTableData from './ExpensebreakdownTableData';
/* eslint-disable react/display-name */
const CustomerExpenseBreakdown = React.forwardRef(({ reports }, ref) => {
  const headers = ['Year', 'Sales', 'Discount Level', 'Discount', 'Service Discount'];
  return (
    <>
      {reports ? (
        <div className="table-responsive bg-white my-5 table-striped p-5" ref={ref}>
          <div style={{
            width: '100%',
          }}>
          <div className="text-center font-bold " >Autohound Total Sales and Discount Breakdown</div>
          <div className="font-bold text-center">Autohound Total Discounted Services</div>
          </div>
          <table className="table-auto">
            <thead>
              <tr className="p-5 font-medium text-left bg-white border-b text-text text-subtitle-2 border-secondary">
                {headers.map((header, i) => (
                  <th key={i.toString()} className="p-5 text-center cursor-pointer">
                    {header}
                  </th>
                ))}
              </tr>
              <tr className="p-5 font-medium text-left bg-white border-b text-primary text-body-2 border-secondary">
                <th className="p-5 text-center cursor-pointer">Totals</th>
                <th className="p-5 text-center cursor-pointer">${reports.lifetimeAmount}</th>
                <th className="p-5 text-center cursor-pointer"></th>
                <th className="p-5 text-center cursor-pointer">${reports.lifetimeDiscount}</th>
                <th className="p-5 text-center cursor-pointer">${reports.lifetimeOilChange}</th>
              </tr>
            </thead>
            <tbody>
              {reports.breakdown.map((report, i) => (
                <ExpenseBreakdownTableData key={i} report={report} />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="mt-10 text-sm text-center text-error"> No reports for the category </p>
      )}
    </>
  );
});

CustomerExpenseBreakdown.propTypes = {
  reports: PropTypes.object,
  ref: PropTypes.object,
};

export default CustomerExpenseBreakdown;
