/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';

const Box = ({ title, subtitle, children, progress }) => (
  <div className="loginBox flex-col h-auto px-5 sm:px-10 md:px-10 lg:px-10 py-10 bg-white rounded shadow-xl w-90">
    <div className="grid justify-items-center">
      {!!progress && (
        <div className="flex flex-row h-1 w-52">
          {/* {[0, 1, 2, 3, 4, 5].map(num => (
            <div
              key={num}
              className={`${progress >= num ? 'bg-primary' : 'bg-gray-2'} h-full w-full rounded-2xl mx-1`}
            ></div>
          ))} */}
              {[0, 1].map(num => (
            <div
              key={num}
              className={`${progress >= num ? 'bg-primary' : 'bg-gray-2'} h-full w-full rounded-2xl mx-1`}
            ></div>
          ))}
        </div>
      )}
      <h3 className="pt-5 font-semibold text-center text-h3 text-text">{title}</h3>
      <div className="text-center">
        <p className="px-5 mt-5 font-semibold text-subtitle-2 text-gray-1">{subtitle}</p>
      </div>
    </div>
    <div className="flex flex-col">{children}</div>
  </div>
);

Box.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.object.isRequired,
  progress: PropTypes.number,
};

export default Box;
