/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import InvoiceFields from './InvoiceFields';
import { BasicError } from '../../../components/Error';

import UserAPIs from '../../../api/user';
import InvoiceAPIs from '../../../api/invoice';

import useTitle from '../../../hooks/useTitle';
import { handleErrorMessages, handleSuccessMessages } from '../../../helpers/flashMessages';
import { useAlert } from 'react-alert';

const AddInvoice = () => {
  useTitle('Add Invoice');

  const [vehicles, setVehicles] = useState([]);
  const [oilChange, setOilChange] = useState(0);
  const [error, setError] = useState({
    message: '',
  });

  const { userId } = useParams();
  const history = useHistory();
  const alert = useAlert();

  useEffect(() => {
    (async () => {
      try {
        const response = await UserAPIs.getCustomer(userId);
        const { vehicles, oilChange } = response.data.user.customerId;
        const userHasVehicles = !!vehicles && vehicles.length > 0;

        if (!userHasVehicles) {
          setError({ message: 'User has no vehicles and therefore cannot add an invoice.' });
        } else {
          setOilChange(oilChange);
          setVehicles([...vehicles]);
        }
      } catch (e) {
        setError(e);
      }
    })();
  }, [userId]);

  const addInvoice = async (chosenCarId, prevCarId, invoiceData) => {
    try {
      const response = await InvoiceAPIs.addInvoice(userId, chosenCarId, invoiceData);
      handleSuccessMessages(response, alert);
      history.push(`/customer/${userId}`);
    } catch (e) {
      const message = handleErrorMessages(e, alert);
      setError({ message });
      console.error(e);
    }
  };

  return (
    <div className="invoices">
      {vehicles.length > 0 ? (
        <InvoiceFields
          operation="add"
          title="Add Invoice"
          customerId={userId}
          cars={vehicles}
          handler={addInvoice}
          oilChange={oilChange}
        />
      ) : (
        <BasicError error={error} />
      )}
    </div>
  );
};

export default AddInvoice;
