/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { handleErrorMessages, handleSuccessMessages } from '../../../../helpers/flashMessages';

import AdminAPIs from '../../../../api/admin';
import { keyupValidationAdmin } from '../../../../helpers/validationMessages';
import SuperAdminFields from './superAdminFields';

const AddSuperAdmin = () => {
  const [error, setError] = useState('');
  const history = useHistory();
  const [phone, setPhone] = useState('');
  const alert = useAlert();

  const [admin, setAdmin] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    status: true,
    role: 'admin',
  });

  const updateState = (e, type) => {
    const key = e.target.name;
    let value = e.target.value;    
    if (key == 'mobile') {
      setPhone(value);
    }
    if (key === 'status') {
      value = value === 'true'; // converts the string into a Boolean value
    }
    setAdmin({ ...admin, [key]: value });
    keyupValidationAdmin(value, type, key);
  };

  const addAdmin = async () => {
    try {
      if(admin._id) {
        delete admin._id;
      }
      const response = await AdminAPIs.addAdmin(admin);
      if(response.status === 200){
        const userName = JSON.parse(localStorage.getItem('usersName'));
        const userNameIndex = userName.findIndex(data => data.id === response.data.customer._id);
        if(userNameIndex !== -1){
          userName[userNameIndex].firstName = response.data.customer.firstName;
          userName[userNameIndex].lastName = response.data.customer.lastName;
          userName[userNameIndex].email = response.data.customer.email;
          userName[userNameIndex].mobile = response.data.customer.mobile;
          localStorage.setItem('usersName', JSON.stringify(userName));
        }else{
          let newArr = [...userName, { id: response.data.customer._id, firstName:response.data.customer.firstName, lastName: response.data.customer.lastName, email: response.data.customer.email, mobile: response.data.customer.mobile  }]
          localStorage.setItem('usersName', JSON.stringify(newArr));
        }
      }
      handleSuccessMessages(response, alert);
      history.push('/admin');
    } catch (e) {
      const message = handleErrorMessages(e, alert);
      setError(message);
    }
  };

  return (
    <div>
      <SuperAdminFields
        operation="add"
        updateState={updateState}
        setAdmin={setAdmin}
        admin={admin}
        submitHandler={addAdmin}
        data={admin}
        setPhone={setPhone}
        phone={phone}
      />
    </div>
  );
};

export default AddSuperAdmin;
