/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import UserAPIs from '../../api/user';

import Box from '../../components/Box/Box';
import OnePage from '../../components/Pages/OnePage';
import RequiredAsterisk from '../../components/RequiredAsterisk';
import { useAlert } from 'react-alert';
import { handleErrorMessages } from '../../helpers/flashMessages';
import { keyupValidation } from '../../helpers/validationMessages';

const VerifyPasscode = () => {
  const [error, setError] = useState('');
  const [passcode, setPasscode] = useState('');
  const { email } = useParams();
  const history = useHistory();
  const alert = useAlert();
  const [errors, setErrors] = useState('');

  const handleErrors = () => {
    if (passcode == '') setErrors('* Enter six digits passcode');
    else if (passcode.length != 6) setErrors('* Enter a valid passcode');
    else setErrors('');
  };

  const keyDown = () => {
    setErrors('');
  };

  const validatePasscode = async e => {
    e.preventDefault();
    handleErrors();
    if (passcode != '' && errors == '') {
      const body = {
        email,
        passcode,
      };
      try {
        await UserAPIs.verifyEnteredPasscode(body);
        history.push('/forgot-password/update-password');
      } catch (err) {
        setPasscode('');
        // const message = handleErrorMessages(err, alert);
        setErrors('* Incorrect passcode');
      }
    }
  };

  return (
    <OnePage className="plans">
      <div className="container px-8 mt-10 h-hero">
        <Box title="Enter Code" subtitle={`Please enter the 6-digit verification sent to: ${email}`}>
          <form onSubmit={e => validatePasscode(e)} noValidate>
            <div className="mt-10 flex flex-col">
              <label className="text-small text-text">
                Verification Code <RequiredAsterisk />
              </label>
              <input
                onChange={e => setPasscode(e.target.value)}
                maxLength="6"
                name="passcode"
                value={passcode}
                type="text"
                className="px-4 py-3 mt-1 bg-background text-body-2 text-subtext"
                required
                onKeyUp={handleErrors}
                onKeyDown={keyDown}
              />
              {errors && errors !== '' ? <p className="text-error text-sm mb-4">{errors}</p> : null}
              {/* {error?.length > 0 && <p className="text-sm text-error">{error}</p>} */}
              <button
                type="submit"
                className="mt-7 px-5 py-3 text-white rounded-sm bg-primary hover:bg-primary-light"
              >
                Submit
              </button>
            </div>
          </form>
        </Box>
      </div>
    </OnePage>
  );
};

export default VerifyPasscode;
