/* eslint-disable prettier/prettier */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { isCurrentTokenValid } from '../utilities/token';
import Navbar from '../components/Navbar/Navbar';
import Layout1 from '../components/Layouts/layout1';

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <>
          <Navbar />
          <Component {...props} />
        </>
      )}
    />
  );
};
PublicRoute.propTypes = {
  component: PropTypes.func,
};

export default PublicRoute;
