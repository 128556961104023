/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';

import BasicError from './BasicError';

const PageError = ({ message }) => (
  <div className="flex items-center justify-center">
    <BasicError customError={message} />
  </div>
);

PageError.propTypes = {
  message: PropTypes.string,
};

export default PageError;
