/* eslint-disable prettier/prettier */
import { Input } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import RequiredAsterisk from '../RequiredAsterisk';
import { Link} from 'react-router-dom';

const ChangePassword = ({ state, errors, handleChange, handleUpdate }) => {
    const { currentPassword, newPassword, confirmPassword } = state;
    const [passwordShown, setPasswordShown] = useState(false);
    const [newpasswordShown, setNewPasswordShown] = useState(false);
    const [confirmPasswordShown, setconfirmPasswordShown] = useState(false);

    const togglePasswordVisiblity = () => {
      setPasswordShown(!passwordShown);
    };

    const toggleNewPasswordVisiblity = () => {
      setNewPasswordShown(!newpasswordShown);
    };

    const toggleConfirmPasswordVisiblity = () => {
      setconfirmPasswordShown(!confirmPasswordShown);
    };
  return (
    <div className="container">
      <div className="p-2 sm:p-8">
        <h1 className="text-xl font-bold mb-5">Change Password</h1>
        <div className="grid grid-cols-3 griD-one  gap-4">
          <div className="">
            <label>
              Current Password
              <RequiredAsterisk />
            </label>
            <Input
              type={passwordShown ? 'text' : 'password'}
              name="currentPassword"
              placeholder="Enter your Current Password"
              value={currentPassword}
              onChange={handleChange}
            />
            <span className="z-10 absolute text-center text-subtext absolute bg-transparent rounded text-base items-center justify-center w-8 p-1.5 h-50">
              <button className="btn-viewPass" onClick={togglePasswordVisiblity} type="button">
                {passwordShown ? (
                  <AiFillEyeInvisible className="text-sm" />
                ) : (
                  <AiFillEye className="text-sm" />
                )}
              </button>
            </span>
            {errors && errors.currentPassword !== '' ? (
              <p className="text-error font text-sm">{errors.currentPassword}</p>
            ) : null}
          </div>
          <div className="">
            <label>
              New Password
              <RequiredAsterisk />
            </label>
            <Input
              type={newpasswordShown ? 'text' : 'password'}
              name="newPassword"
              placeholder="Enter your New Password"
              value={newPassword}
              onChange={handleChange}
            />
            <span className="z-10 absolute text-center text-subtext absolute bg-transparent rounded text-base items-center justify-center w-8 p-1.5 h-50">
              <button className="btn-viewPass" onClick={toggleNewPasswordVisiblity} type="button">
                {newpasswordShown ? (
                  <AiFillEyeInvisible className="text-sm" />
                ) : (
                  <AiFillEye className="text-sm" />
                )}
              </button>
            </span>
            {errors && errors.newPassword !== '' ? (
              <p className="text-error font text-sm">{errors.newPassword}</p>
            ) : null}
          </div>
          <div className="">
            <label>
              Confirm Password
              <RequiredAsterisk />
            </label>
            <Input
              type={confirmPasswordShown ? 'text' : 'password'}
              name="confirmPassword"
              placeholder="Confirm your new password"
              value={confirmPassword}
              onChange={handleChange}
            />
            <span className="z-10 absolute text-center text-subtext absolute bg-transparent rounded text-base items-center justify-center w-8 p-1.5 h-50">
              <button className="btn-viewPass" onClick={toggleConfirmPasswordVisiblity} type="button">
                {confirmPasswordShown ? (
                  <AiFillEyeInvisible className="text-sm" />
                ) : (
                  <AiFillEye className="text-sm" />
                )}
              </button>
            </span>
            {errors && errors.confirmPassword !== '' ? (
              <p className="text-error font text-sm">{errors.confirmPassword}</p>
            ) : null}
          </div>
        </div>

        <div className="col-12 dflex">
          <div className="forgot-link">
            <Link
              to="/forgot-password"
              className="mt-3 text-sm font-medium text-center underline text-gray-1"
            >
              Forgot/Reset Password?
            </Link>
          </div>
          <button
            type="submit"
            className="px-5 py-3 mt-4 w-36 text-white text-button rounded-sm bg-primary hover:bg-opacity-80"
            onClick={handleUpdate}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

ChangePassword.propTypes = {
    state: PropTypes.object,
    errors: PropTypes.object,
    handleChange: PropTypes.func,
    handleUpdate: PropTypes.func,
};

export default ChangePassword;
