/* eslint-disable prettier/prettier */
import React, { useState, useCallback, createContext } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import OnePage from '../../components/Pages/OnePage';

import EnterMobile from './EnterEmail';
import EnterCode from './EnterCode';
import EnterBasicInformation from './EnterBasicInformation';
import EnterPlanAndPayment from './EnterPlanAndPayment';
import TOS from './TOS';
import SelectRole from './SelectRole';

import useTitle from '../../hooks/useTitle';

const START_AT_MOBILE_SIGNUP = 0;

export const SignupContext = createContext();

const Signup = () => {
  useTitle('Sign Up');

  const [step, setStep] = useState(START_AT_MOBILE_SIGNUP);
  const [data, setData] = useState({
    role: '',
    mobile: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    garage: '',
    chosenGarage: '',
    company: '',
    newGarage: true,
  });

  const goToNextStep = () => {
    const FIRST_STEP = 0;
    const LAST_STEP = 5;
    const isWithinSignupRange = step >= FIRST_STEP && step <= LAST_STEP;

    if (isWithinSignupRange) {
      setStep(step + 1);
    }
  };

  const updateUserData = useCallback((key, value) => {
    setData(data => ({ ...data, [key]: value }));
  }, []);

  return (
    <SignupContext.Provider value={{ step, goToNextStep, data, updateUserData }}>
      <OnePage background="background">
        <div className="container px-8 h-hero mt-16">
          {
            {
              0: <SelectRole />,
              // 1: <EnterMobile />,
              // 2: <EnterCode />,
              1: <TOS />,
              // 4: <EnterBasicInformation />,
              // 5: <EnterPlanAndPayment />,
            }[step]
          }
        </div>
      </OnePage>
    </SignupContext.Provider>
  );
};

Signup.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(Signup);
