/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { handleErrorMessages, handleSuccessMessages } from '../../../../helpers/flashMessages';

import AdminAPIs from '../../../../api/admin';

import BusinessUserFields from './businessUserFields';

const AddBusinessUser = () => {
  const [error, setError] = useState('');
  const history = useHistory();
  const alert = useAlert();

  const [businessUser, setBusinessUser] = useState({
    company: '',
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    status: true,
    garage: '',
    street: '',
    city: '',
    province: '',
    postalCode: '',
    discount: '',
    labourRate: '',
    role: 'business_user',
  });
  const [companyName, setCompanyName] = useState('');
  const [phone, setPhone] = useState('');

  // const updateStateCompany = e => {
  //   const key = e.target.name;
  //   let value = e.target.value;
  //   if (key === 'company') {
  //     setCompanyName(value);
  //   }
  //   setBusinessUser(prevState => {
  //     return { ...prevState, [key]: value };
  //   });
  // };

    const updateState = e => {
      const key = e.target.name;
      let value = e.target.value;
      if (key === 'mobile') {
        setPhone(value);
      }
      if (key == 'labourRate') {
        value =
          value.indexOf('.') >= 0
            ? value.substr(0, value.indexOf('.')) + value.substr(value.indexOf('.'), 3)
            : value;
      }
      if(key === "status") {
        value = value === 'true'
      }
      setBusinessUser((prevState)=>{
        return{ ...prevState, [key]: value }
      });
    };

    //  const updateStateRadio = e => {
    //    const key = e.target.name;
    //    let status = e.target.checked;
    //    if (key === 'status') {
    //      setPhone(status);
    //    }
    //    setBusinessUser(prevState => {
    //      return { ...prevState, [key]: status };
    //    });
    //  };

  const addBusinessUser = async () => {
    const businessData = {
            firstName: businessUser.firstName,
            lastName: businessUser.lastName,
            mobile: businessUser.mobile,
            email: businessUser.email,
            garage: businessUser.garage,
            role: businessUser.role,
            customerData: {
              city: businessUser.city,
              province: businessUser.province,
              street: businessUser.street,
              postalCode: businessUser.postalCode,
              labourRate: businessUser.labourRate,
              status: businessUser.status,
              discount: businessUser.discount,
              company: businessUser.company,
            },
          };
    try {
      const response = await AdminAPIs.addBusinessUsers(businessData);
      if(response.status === 200){
        const userName = JSON.parse(localStorage.getItem('usersName'));
        const userNameIndex = userName.findIndex(data => data.id === response.data.customer._id);
        if(userNameIndex !== -1){
          userName[userNameIndex].firstName = response.data.customer.firstName;
          userName[userNameIndex].lastName = response.data.customer.lastName;
          userName[userNameIndex].email = response.data.customer.email;
          userName[userNameIndex].mobile = response.data.customer.mobile;
          localStorage.setItem('usersName', JSON.stringify(userName));
        }else{
          let newArr = [...userName, { id: response.data.customer._id, firstName:response.data.customer.firstName, lastName: response.data.customer.lastName, email: response.data.customer.email, mobile: response.data.customer.mobile  }]
          localStorage.setItem('usersName', JSON.stringify(newArr));
        }
      }
      handleSuccessMessages(response, alert);
      history.push('/admin/business-user');
    } catch (e) {
      const message = handleErrorMessages(e, alert);
      setError(message);
    }
  };

  return (
    <div>
      <BusinessUserFields
        operation="add"
        updateState={updateState}
        // updateStateRadio={updateStateRadio}
        // updateStateCompany={updateStateCompany}
        setBusinessUser={setBusinessUser}
        businessUser={businessUser}
        submitHandler={addBusinessUser}
        data={businessUser}
        // companyName={companyName}
        // setCompanyName={setCompanyName}
        phone={phone}
        setPhone={setPhone}
      />
    </div>
  );
};

export default AddBusinessUser;
