/* eslint-disable prettier/prettier */
import HttpService from '../services/http';

const sendBulkEmails = formData => {
  const http = new HttpService(`/email/bulk/emails`);
  return http.post(formData);
};
const getEmailLogs = filters => {
  const {
    pagination: { current, pageSize },
    search,
    sortFilters: { sortField, sortOrder },
  } = filters;
  let queryString = `q=${search}&limit=${pageSize}&page=${current}&sortField=${sortField || ''}&sortOrder=${
    sortOrder || ''
  }`;
  const http = new HttpService(`/email/emailLog?${queryString}`);
  return http.get();
};

export default {
  sendBulkEmails,
  getEmailLogs,
};
