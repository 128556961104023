/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { handleErrorMessages, handleSuccessMessages } from '../../../helpers/flashMessages';

import AdminAPIs from '../../../api/admin';

import GarageFields from './garageFields';
//import DemoContext from './garageOwner';
import DemoContext from "../Users/GarageOwner/garageOwner"

const AddGarage = () => {
  const [error, setError] = useState('');
  const [value, setValue] = useState();
  const ipRef = useRef(0);
  const data = useContext(DemoContext);
  const [res, setRes] = useState(0);
  const history = useHistory();
  const alert = useAlert();

  const [owner, setOwner] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    garage: '',
    role: 'owner',
  });

  const updateState = e => {
    const key = e.target.name;
    let value = e.target.value;
    setOwner({ ...owner, [key]: value });
  };

  const addOwner = async () => {
    try {
      const response = await AdminAPIs.addOwner(owner);
      handleSuccessMessages(response, alert);
      history.push('/admin/garages');
    } catch (e) {
      const message = handleErrorMessages(e, alert);
      setError(message);
    }
  };
  return (
    <div>
      <GarageFields
        operation="add"
        updateState={updateState}
        setOwner={setOwner}
        submitHandler={addOwner}
        value={value}
        data={owner}
      />
    </div>
  );
};

export default AddGarage;
