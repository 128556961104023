/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Modal, Input } from 'antd';

import UserAPIs from '../../api/user';

import CustomerFields from './CustomerFields';
import { BasicError } from '../../components/Error';
import RequiredAsterisk from '../../components/RequiredAsterisk';

import useTitle from '../../hooks/useTitle';
import { useAlert } from 'react-alert';
import { handleErrorMessages, handleSuccessMessages } from '../../helpers/flashMessages';
import _ from 'lodash';

const EditCustomer = () => {
  useTitle('Edit Customer');
  const [error, setError] = useState('');
  const [customer, setCustomer] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updatedCustomerDetails, setUpdatedCustomerDetails] = useState('');
  const [passcode, setPasscode] = useState('');
  const [counter, setCounter] = useState(59);

  const history = useHistory();
  const { id } = useParams();
  const alert = useAlert();

  const onSubmit = async customerDetails => {
    
    const { email } = customer;
    const isEmailSame = _.isEqual(email, customerDetails.email);
    if (!isEmailSame) {
      try {
        const response = await UserAPIs.verifyEmail(customerDetails.email);
        //const responseOnMobile = await UserAPIs.verifyMobile(customerDetails.mobile);
        handleSuccessMessages(response, alert);
        setIsModalVisible(true);
        setUpdatedCustomerDetails(customerDetails);
      } catch (error) {
        const message = handleErrorMessages(error, alert);
        setError(message);
      }
    } else {
      updateCustomer(customerDetails);
    }
  };

  const updateCustomer = async customerDetails => {
    try {
      const { firstName, lastName, company, email, mobile, role, customerId } = customerDetails;

      const response = await UserAPIs.updateCustomer(
        {
          firstName,
          lastName,
          company,
          email,
          mobile: mobile || '',
          role,
          customerData: {
            city: customerId.city,
            province: customerId.province,
            street: customerId.street,
            postalCode: customerId.postalCode,
            labourRate: customerId.labourRate || 0,
            notes: customerId.notes,
            customerPrivateNotes: customerId.customerPrivateNotes,
            customerPublicNotes: customerId.customerPublicNotes,
            status: customerId.status,
            discount: customerId.discount || 0,
            discountYear: customerId.discountYear || 2024,
            company: role == 'customer' ? '' : customerId.company,
          },
        },
        customerDetails._id
      );
      if (role == 'business_user' || role == 'customer') {
        localStorage.setItem('Fname', customerDetails.firstName);
        localStorage.setItem('Lname', customerDetails.lastName);
      }
      handleSuccessMessages(response, alert);
      history.push(`/customer/${id}`);
    } catch (e) {
      const message = handleErrorMessages(e, alert);
      setError(message);
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    } else if (!isModalVisible) {
      setCounter(59);
    }
  }, [isModalVisible, counter]);

  useEffect(() => {
    (async () => {
      try {
        const response = await UserAPIs.getCustomer(id);
        const { user } = response.data;

        setCustomer({ ...user });
      } catch (e) {
        const message = handleErrorMessages(e);
        setError(message);
      }
    })();
  }, [id]);
  const handleCancel = () => {
    setIsModalVisible(false);
    setPasscode('');
  };
  const onFinish = async e => {
    e.preventDefault();
    if (!passcode || passcode == '') {
      return handleErrorMessages({ customMessage: 'Please enter the passcode!' }, alert);
    }
    const { email, mobile } = updatedCustomerDetails;
    const body = {
      email: email,
     // mobile: mobile,
      passcode: passcode,
    };
    try {
      await UserAPIs.verifyEnteredPasscode(body);
      updateCustomer(updatedCustomerDetails);
    } catch (err) {
      const message = handleErrorMessages(err, alert);
      setError(message);
    }
  };

  const resendOtp = async e => {
    e.preventDefault();
    const { email, mobile } = updatedCustomerDetails;
    try {
      const response = await UserAPIs.verifyEmail(email);
     // const responseOnMobile = await UserAPIs.verifyMobile(mobile);
      handleSuccessMessages(response, alert);
    } catch (error) {
      const message = handleErrorMessages(error, alert);
      setError(message);
    }
  };

  return (
    <>
      {Object.keys(customer).length > 0 ? (
        <CustomerFields
          title="Edit Customer"
          operation="edit"
          submitHandler={onSubmit}
          data={customer}
          error={error}
        />
      ) : (
        <BasicError error={error} />
      )}
      <Modal
        title="Verify OTP"
        visible={isModalVisible}
        onCancel={handleCancel}
        maskClosable={false}
        footer={null}
        preserve={false}
        destroyOnClose={true}
        bodyStyle={{ height: 270 }}
      >
        <p className="text-center font-medium text-base mb-5">
          Please enter the passcode sent to this email:
          <span className="block text-primary text-sm"> {updatedCustomerDetails?.email} </span>
        </p>
        <form className="flex flex-col" onSubmit={e => onFinish(e)}>
          <div>
            <label className="text-bold">
              Passcode <RequiredAsterisk />
            </label>
            <div className="max-h-3px">
              <Input
                name="passcode"
                value={passcode}
                className="mt-1 mb-3"
                onChange={e => setPasscode(e.target.value)}
              />
            </div>
          </div>
          {counter > 0 ? (
            <div className="timer_count text-center mt-2">
              <p className="text-gray-1">
                Resend OTP in <span className="text-primary font-bold">{counter}s</span>
              </p>
            </div>
          ) : (
            <div className="timer_count text-center mt-2">
              <a
                className="underline cursor-pointer"
                onClick={e => {
                  setCounter(59);
                  resendOtp(e);
                }}
              >
                Resend OTP
              </a>
            </div>
          )}
          <div>
            <button
              type="submit"
              className="px-5 py-3 mt-5 text-white rounded-sm bg-primary hover:bg-primary-light modal-button"
            >
              Submit
            </button>
            <button
              type="button"
              className="px-5 py-3 mt-5 mr-3 text-white rounded-sm bg-secondary_disabled modal-button"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default EditCustomer;
