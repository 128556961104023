/* eslint-disable prettier/prettier */
import React, { useContext,useState } from 'react';
import { useHistory } from 'react-router-dom';
import {  Modal, Pagination } from 'antd';
// import { ExclamationCircleOutlined } from '@ant-design/icons';
// import { handleErrorMessages, handleSuccessMessages } from '../../helpers/flashMessages';

import { SignupContext } from './Signup';
import TermsUsage from './TermsUsage';

const TOS = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();

  const { goToNextStep } = useContext(SignupContext);
 
  const goBackToHomePage = () => {  
       setIsModalOpen(true);

    //    setTimeout(() => {
    //     setIsModalOpen(false)
    //     history.push('/');
    //  }, 2000);
     
    
  };
   const handleClose=()=>
   {
    setIsModalOpen(false)
   }


  return (
      <>
      
      <Modal className='model-popup' closeable={true}  onCancel={handleClose} visible={isModalOpen} footer={null} >
        <p>Please visit your nearest Auto Hound Facility to create your account.</p>
      </Modal>
    

    <div className="px-4">
      <form className="flex flex-col">
        <div className="overflow-auto h-100">
          <TermsUsage />
          <div className="flex">
            <button
              onClick={() => goBackToHomePage()}
              type="button"
              className="mr-2 px-5 py-3 mt-5 mb-5 text-sm text-white rounded-sm space-y-4 bg-primary hover:bg-primary-light"
            >
              Agree
            </button>
            {/* <button
              onClick={() => goBackToHomePage()}
              type="button"
              className="px-5 py-3 mt-5 mb-5 text-sm text-white rounded-sm bg-primary hover:bg-primary-light"
            >
              Disagree
            </button> */}
          </div>
        </div>
      </form>
    </div>
    </>
  );
};

export default TOS;
