/* eslint-disable prettier/prettier */
import HttpService from '../services/http';

const getCarReports = (customerId, vehicleId) => {
  const http = new HttpService(`/report/${customerId}/${vehicleId}`);
  return http.get();
};

const getCustomerExpenses = customerId => {
  const http = new HttpService(`/report/customer-expenses?customerId=${customerId}`);
  return http.get();
};

const updateDiscountLevel = (yearlyExpenseId, body) => {
  const http = new HttpService(`/report/update-discount-level/${yearlyExpenseId}`);
  return http.put(body);
};

export default { getCarReports, getCustomerExpenses, updateDiscountLevel };
