/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import InvoiceFields from './InvoiceFields';
import InvoiceAPIs from '../../../api/invoice';
import VehicleAPIs from '../../../api/vehicle';
import UserAPIs from '../../../api/user';

import useTitle from '../../../hooks/useTitle';
import { useAlert } from 'react-alert';
import { handleErrorMessages, handleSuccessMessages } from '../../../helpers/flashMessages';

const EditInvoice = () => {
  useTitle('Edit Invoice');

  const [vehicles, setVehicles] = useState([]);
  const [invoice, setInvoice] = useState({});
  const [oilChange, setOilChange] = useState(0);
  const [error, setError] = useState({
    message: '',
  });

  const { userId, invoiceId } = useParams();
  const history = useHistory();
  const alert = useAlert();

  useEffect(() => {
    (async () => {
      try {
        const [invoiceResponse, vehiclesResponse, userResponse] = await Promise.all([
          InvoiceAPIs.getInvoice(invoiceId),
          VehicleAPIs.getUserVehicles(userId),
          UserAPIs.getCustomer(userId),
        ]);
        
        setOilChange(userResponse.data.user.customerId.oilChange);
        setVehicles(vehiclesResponse?.data?.data?.userWithVehicles?.customerId?.vehicles);
        setInvoice(invoiceResponse?.data?.invoice);

      } catch (e) {
        setError(e);
      }
    })();
  }, [invoiceId, userId]);

  const updateInvoice = async (vehicleId, prevVehicleId, updates) => {
    try {
      
      // if (invoice.type != 'Autohound Repairs') {        
      //   delete updates.oilChange;
      // }  
      
      const response = await InvoiceAPIs.editInvoice(prevVehicleId, userId, vehicleId, invoiceId, updates);
      history.push(`/customer/${userId}`);
      handleSuccessMessages(response, alert);

    } catch (e) {
      const message = handleErrorMessages(e, alert);
      setError({ message });
    }
  };

  return (
    <div className="invoices">
      {vehicles?.length > 0 && Object.entries(invoice)?.length > 0 ? (
  
        <InvoiceFields
          operation="edit"
          title="Edit Invoice"
          invoice={invoice}
          handler={updateInvoice}
          cars={vehicles}
          oilChange={oilChange}
        />
      
      ) : (
        <div className="mt-10 place-self-center">
          <p className="text-xl font-semibold text-text">{error.message}</p>
        </div>
      )}
    </div>
  );
};

export default EditInvoice;
