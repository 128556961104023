/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ExclamationCircleOutlined, FilterOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { readFile, utils } from 'xlsx';
import { Collapse, Table, Switch, Button, Checkbox, Select, Input, Tooltip, Modal } from 'antd';
import { useAlert } from 'react-alert';
import { CSVLink } from 'react-csv';
import {
  DownloadOutlined,
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
  SearchOutlined,
  EditOutlined,
} from '@ant-design/icons';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FiPlus, FiSearch, FiEdit2 } from 'react-icons/fi';
// import SuperAdminFields from './superAdminFields';
import { useHistory } from 'react-router';
import AdminAPIs from '../../../../api/admin';
import { set } from 'lodash-es';
import _ from 'lodash';
import ExportReactCSV from '../../../../helpers/export';
import { lastDayOfMonth } from 'date-fns';
import { id } from 'date-fns/locale';
import { handleErrorMessages, handleSuccessMessages } from '../../../../helpers/flashMessages';

const AllUsers = () => {
  // const { sidebarContent, setRenderContent } = useContext(RenderContentContext);
  const history = useHistory();
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [collapse, setCollapse] = useState([]);
  const [table, setTable] = useState([]);
  const [exportData, setExportData] = useState([]);
  const { Search } = Input;
  const { Panel } = Collapse;
  const { Option, OptGroup } = Select;
  const suffix = (
    <SearchOutlined
      style={{
        fontSize: 16,
        color: '#F0734F',
      }}
    />
  );
  const [dataState, setDataState] = useState([]);
  const handleFile = async e => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = readFile(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsondata = utils.sheet_to_json(worksheet);
    setDataState(
      jsondata.map((e, i) => ({
        role: e.role,
        firstName: e.firstName,
        lastName: e.lastName,
        email: e.email,
        mobile: e.mobile,
      }))
    );
    setTable(
      jsondata.map((e, i) => ({
        role: e.role,
        firstName: e.firstName,
        lastName: e.lastName,
        email: e.email,
        mobile: e.mobile,
        createdAt: e.createdAt,
      }))
    );
  };
  const [options, setOptions] = useState({
    search: '',
    role: '',
    status: '',
    pagination: { current: 1, pageSize: 10, pageSizeOptions: [10, 20, 50] },
    sortFilters: {},
    start: '',
    end: '',
  });
  const { confirm } = Modal;
  const columns = [
    {
      title: (
        <Checkbox
          onChange={e => onSelectAll(e)}
          checked={
            selectedRows.length > 0 &&
            _.isEqual(
              _.sortBy(admins.map(x => x._id)),
              _.sortBy(
                _.intersectionWith(
                  selectedRows,
                  admins.map(x => x._id),
                  _.isEqual
                )
              )
            )
          }
        />
      ),
      dataIndex: '_id',
      key: 'select',
      // eslint-disable-next-line react/display-name
      render: _id => <Checkbox onChange={e => onSelectRow(e, _id)} checked={selectedRows.includes(_id)} />,
    },

    {
      title: 'User Type',
      dataIndex: 'role',
      key: 'role',
      sorter: true,
    },

    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile',
      // sorter: true,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      // defaultSortOrder: 'descend',
      // sortDirections: ['ascend'],
      render: createdAt => (createdAt ? moment(createdAt).format('MMMM Do YYYY') : 'NA'),
    },
    {
      title: 'Status',
      dataIndex: 'customers',
      key: 'status',
      sorter: true,
      render: (customers, data) => {
        if(data?.role === 'customer' || data?.role === 'business_user'){
           if(data.customers)
           {
             return `${data?.customers[0]?.status ? 'Active' : 'Inactive'}`
           }
           else{
             return `${data?.status ? 'Active' : 'Inactive'}`
           }
        }
        else{
          return `${data?.status ? 'Active' : 'Inactive'}`
        }
      }

    },
    { 
      title: 'Actions',
      dataIndex: '_id',
      // eslint-disable-next-line react/display-name
      render: _id => (
        <>
          <Link to={`/admin/all-users/edit/${_id}`} onClick={e => editOnClick(e, _id)}>
            <Tooltip placement="top" title="edit">
              <EditOutlined />
            </Tooltip>
          </Link>
          <Tooltip title="delete">
            <DeleteOutlined className="ml-2" onClick={e => showDeleteConfirm(e, _id)} />
          </Tooltip>
        </>
      ),
    },
  ];
  const columnsCustomer = [
    {
      title: (
        <Checkbox
          onChange={e => onSelectAll(e)}
          checked={
            selectedRows.length > 0 &&
            _.isEqual(
              _.sortBy(admins.map(x => x._id)),
              _.sortBy(
                _.intersectionWith(
                  selectedRows,
                  admins.map(x => x._id),
                  _.isEqual
                )
              )
            )
          }
        />
      ),
      dataIndex: '_id',
      key: 'select',
      // eslint-disable-next-line react/display-name
      render: _id => <Checkbox onChange={e => onSelectRow(e, _id)} checked={selectedRows.includes(_id)} />,
    },

    {
      title: 'User Type',
      dataIndex: 'role',
      key: 'role',
      sorter: true,
    },

    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile',
      // sorter: true,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      // defaultSortOrder: 'descend',
      // sortDirections: ['ascend'],
      render: createdAt => (createdAt ? moment(createdAt).format('MMMM Do YYYY') : 'NA'),
    },
    {
      title: 'Status',
      dataIndex: 'customers',
      key: 'status',
      sorter: true,
      render: (customers, data) => {
        if(data?.role === 'customer' || data?.role === 'business_user'){
           if(data.customers)
           {
             return `${data?.customers[0]?.status ? 'Active' : 'Inactive'}`
           }
           else{
             return `${data?.status ? 'Active' : 'Inactive'}`
           }
        }
        else{
          return `${data?.status ? 'Active' : 'Inactive'}`
        }
      }

    },
    {
      title: 'Actions',
      dataIndex: 'userId',
      // eslint-disable-next-line react/display-name
      render: userId => (
        <>
          <Link to={`/admin/all-users/edit/${userId}`} onClick={e => editOnClick(e, userId)}>
            <Tooltip placement="top" title="edit">
              <EditOutlined />
            </Tooltip>
          </Link>
          <Tooltip title="delete">
            <DeleteOutlined className="ml-2" onClick={e => showDeleteConfirm(e, userId)} />
          </Tooltip>
        </>
      ),
    },
  ];


  const alert = useAlert();
  const onSwitchChange = async (checked, status, id) => {
    try {
      const data = { status: checked };
      const response = await AdminAPIs.updateAdmin(id, data);
      getUserList(options);
    } catch (error) {
      console.log(error);
    }
  };
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const datePicker = () => {
  const onChangedate = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setOptions({
      ...options,
      start: moment(start).format('YYYY-MM-DD'),
      // end: moment(end).add(1, 'days').format('YYYY-MM-DD'),
      end: end != null ? moment(end).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
    });
  };
  // };
  const ID = localStorage.getItem('LoginId');
  const showDeleteConfirm = (e, _id) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'The deleted record will not be recovered.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
        if (_id != ID) {
          const response = AdminAPIs.deleteUser(_id);
          handleSuccessMessages({customMessage: `User deleted successfully.`}, alert);
        } else if(_id == ID) {
          handleErrorMessages({ customMessage: `Logged in user can not be deleted.` }, alert);
        }
        setTimeout(()=>{
          getUserList(options);
        },1000)
        
        setSelectedRows([]);
      },

      onCancel() {
        setSelectedRows([]);
      },
    });
  };
  const showDelete = (e, selectedRows) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'The deleted record will not be recovered.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
        if (!selectedRows.includes(ID)) {
        const response = AdminAPIs.deleteMultipleUsers([selectedRows]);
        handleSuccessMessages({customMessage: `User deleted successfully.`}, alert);
        } else if (selectedRows.includes(ID)) {
          const newIDs = selectedRows.filter(item => item !== ID)
          const response = AdminAPIs.deleteMultipleUsers([newIDs]);
          handleErrorMessages({customMessage: `Logged in user can not be deleted.`}, alert);
          
        }
        setTimeout(()=>{
          getUserList(options);
        },1000)
        setSelectedRows([]);
      },

      onCancel() {
        setSelectedRows([]);
      },
    });
  };

  const collapseShowHideFunc = key => {
    setCollapse(key);
  };
  const handleFilterClick = () => {
    setCollapse([]);
    getUserList(options);
    // getAllUserList(options);
    // getUserList({ ...options, pagination: { ...options.pagination, current: 1 } });
  };
  const handleClearAllClick = () => {
    setCollapse([]);
    const updatedOptions = {
      ...options,
      status: '',
      role: '',
      pagination: { ...options.pagination, current: 1 },
      start: '',
      end: '',
    };
    setStartDate(null);
    setEndDate(null);
    setOptions(updatedOptions);
    getUserList(updatedOptions);
    getAllUserList(updatedOptions);
  };
  const editOnClick = (e, user) => {};
  function handleChange(value) {}

  useEffect(() => {
    getUserList();
    // getAllUserList(options);
  }, []);
  const getUserList = async options => {
    try {
      setLoading(true);
      const response = await AdminAPIs.getUsers(options);
      const { users } = response.data;
      setAdmins(response.data.users.docs);
      setOptions({
        ...options,
        pagination: {
          current: response.data.users.page,
          pageSize: response.data.users.limit,
          total: response.data.users.totalDocs,
          showSizeChanger: true,
        },
      });
    } catch (e) {
      console.error('e');
      setLoading(false);
    }
    setLoading(false);
  };

  const getAllUserList = async options => {
    try {
      setLoading(true);
      const response = await AdminAPIs.getAllUsers(options);
      let finaldata = response.data.users || [] 
        finaldata.map((data)=>{
        ['_id','password','profilePic','customerId','isDeleted','__v','customers'].map(item=>delete data[item])
        data.createdAt=moment(data.createdAt).format('DD-MM-yyyy')
       })
        setExportData(finaldata);
    } catch (e) {
      setLoading(false);
      console.error('e');
    }
    setLoading(false);
  };
  const handleExport = async options => {
    try {
      setLoading(true);
      // debugger // eslint-disable-line no-debugger
      const response = await AdminAPIs.getAllUsers(options);
      if(response.data.users.length >0)
      {
       
       let finaldata = response.data.users || [];
       let updateData = [];
       finaldata.forEach((el) => {
         const { user,province,postalCode,city} = el;
         updateData.push({
          FistName: user.firstName,
          LastName: user.lastName,
          CityProvince: `${city} ${province ? `/${province}` :''}`,
          PostalCode: postalCode,
          Role:user.role
        });
       });
       setExportData(updateData);
       document.getElementById('csvLink').click();
      }else if(response.data.users.docs.length >0)
      { 
        let finaldata = response.data.users.docs || [];
        let updateData = [];
        finaldata.forEach((el) => {
          const { firstName ,role,lastName} = el;
          updateData.push({
            FistName: firstName,
            LastName: lastName,
            CityProvince: '',
            PostalCode: '',
            Role:role
          });
        });
        setExportData(updateData);
        document.getElementById('csvLink').click();
      }
    } catch (e) {
      setLoading(false);
      console.error('e');
    }
    setLoading(false);
  };
  
  const handleExportAll = async options => {
    try {
      setLoading(true);
      // debugger // eslint-disable-line no-debugger
      options.role='';
      options.status='';
      const response = await AdminAPIs.getAllUsers(options);
      if(response.data.users.length >0)
      {
       let finaldata = response.data.users || [];
       let updateData = [];

       finaldata.forEach((el) => {
         const { firstName, lastName ,role,customerId } = el;
         updateData.push({
          FirstName: firstName,
          LastName: lastName,
          CityProvince: `${customerId?.city || ''} ${customerId?.province ? `/${customerId?.province}` : ''}`,
          PostalCode: customerId?.postalCode ? customerId?.postalCode : '' ,
          Role:role
        });
       });

       setExportData(updateData);
       document.getElementById('csvLinkAll').click();

      }
    } catch (e) {
      setLoading(false);
      console.error('e');
    }
    setLoading(false);
  };

  useEffect(() => {
    getUserList(options);
    // getAllUserList(options);
  }, []);

  function onChange(pagination, filters, sorter, extra) {
    // const {current, pageSize} = pagination;
    // const updatedOptions = { ...options, current, pageSize };
    const updatedOptions = { ...options, pagination };
    setOptions(updatedOptions);
    getUserList({
      ...updatedOptions,
      sortFilters: {
        sortField: sorter?.columnKey,
        sortOrder: sorter?.order,
      },
    });
  }

  const onSelectAll = e => {
    if (e.target.checked) {
      const test = [...new Set([...selectedRows, admins.map(n => n._id)].flat())];
      setSelectedRows(test);
    } else {
      const selectedIds = selectedRows.filter(n => !admins.map(x => x._id).includes(n));
      setSelectedRows(selectedIds);
    }
  };
  const onSelectRow = async (e, _id) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, _id]);
    } else {
      const selectedIds = selectedRows.filter(n => n !== _id);
      setSelectedRows(selectedIds);
    }
  };

  const filterSortedTable = e => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    const query = e.target.value;
    const updatedOptions = {
      ...options,
      search: query.trim(),
      pagination: { ...options.pagination, current: 1 },
    };
    setOptions(updatedOptions);
    setTypingTimeout(() =>
      setTimeout(() => {
        getUserList(updatedOptions);
      }, 1000)
    );
  };
  return (
    <>
      {/* <Helmet>
        <script
          src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.x.x/dist/alpine.min.js"
          type="text/javascript"
        />
        <script src="http://localhost:3000/js/init-alpine.js" type="text/javascript" />
      </Helmet> */}
      <div className="filter--outer mb-4">
        <div className="d-flex top--heading1">
          <h2 className="text-2xl font-semibold text-gray-700 dark:text-gray-200">All Users</h2>
          <div className="right--btn">
            <button className="bg-primary hover:bg-opacity-80 py-2 px-4 text-white rounded-sm ">
              <Link to="/admin/add-user">
                <FiPlus className="inline-block align-middle mr-2 text-base" />
                <p className="inline-block align-middle">Add User</p>
              </Link>
            </button>
          </div>
        </div>
        <div className="d-flex filter--wrap my-5  align-center">
          <div className="d-flex mb-0 mb-sm-0  gap-2">
            <div className="del--icon" style={selectedRows.length === 0 ? { cursor: 'default' } : {}}>
              <DeleteOutlined
                onClick={e => (selectedRows.length === 0 ? {} : showDelete(e, selectedRows))}
                style={selectedRows.length === 0 ? { cursor: 'default' } : {}}
              />
            </div>
            {/* <Select defaultValue="lucy" style={{ width: 200 }} onChange={handleChange}>
              <OptGroup label="Manager">
                <Option value="jack">Jack</Option>
                <Option value="lucy">Lucy</Option>
              </OptGroup>
              <OptGroup label="Engineer">
                <Option value="Yiminghe">yiminghe</Option>
              </OptGroup>
            </Select> */}
            <div className="filter_content w-100 d-flex">
              <Collapse activeKey={collapse} onChange={collapseShowHideFunc}>
                <Panel
                  showArrow={false}
                  header={
                    <React.Fragment>
                      <FilterOutlined className="mr-2" style={{ marginBottom: '1px' }} />
                      <span className="anticon anticon-filter">Filters</span>
                    </React.Fragment>
                  }
                  key="1"
                  className="site-collapse-custom-panel"
                >
                  <div className="card card-body">
                    <div className="row">
                      <div className="col-12 filter_header d-flex flex-wrap position-relative">
                        <h4 className="title">Filter By</h4>
                        <button className="clearfilter_btn btn-default" onClick={handleClearAllClick}>
                          Clear all
                        </button>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4 col-xl-2 fltr_cell">
                        <label className="label">Role</label>
                        <Select
                          className="text-1 font-normal rounded-r-none active-inactive-filter"
                          name="role"
                          defaultValue="All"
                          value={options.role}
                          style={{ width: '100%' }}
                          onChange={e => setOptions({ ...options, role: e })}
                        >
                          <Option value="">All</Option>
                          <Option value="customer">Customer</Option>
                          <Option value="business_user">Business User</Option>
                          <Option value="owner">Garage Owner</Option>
                          <Option value="admin">Admin</Option>
                        </Select>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4 col-xl-2 fltr_cell">
                        <label className="label">Status</label>
                        <Select
                          disabled={options.role == ''}
                          className="text-1 font-normal rounded-r-none active-inactive-filter"
                          name="status"
                          defaultValue="All"
                          // value={options.status}
                          value={options.role == '' ? (options.status = '') : options.status}
                          onChange={e => setOptions({ ...options, status: e })}
                        >
                          <Option value="">All</Option>
                          <Option value={true}>Active</Option>
                          <Option value={false}>Inactive</Option>
                        </Select>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4 col-xl-3 fltr_cell">
                        <label className="label">Date</label>
                        <label>
                          <DatePicker
                            className="date-picker"
                            selected={startDate}
                            onChange={onChangedate}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange={true}
                            withPortal
                            placeholderText="MM/DD/YYYY"
                            // inline
                            isClearable={false}
                           
                            maxDate={new Date()}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                          />
                        </label>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4 col-xl-2 fltr_cell d-flex justify-content-end">
                        <label className="label"></label>
                        <button
                          type="button"
                          className="bg-primary hover:bg-opacity-80 py-2 px-4 text-white rounded-sm"
                          onClick={handleFilterClick}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </Panel>
              </Collapse>
            </div>
          </div>
          <div className="d-flex flex-wrap gap-2">
            <label className="custom-field">
              <input type="file" name="file" onChange={e => handleFile(e)} />
              <span className="placeholder ant-btn ant-btn-primary">
                <DownloadOutlined /> Import
              </span>
            </label>

            <Button className="mr-2 placeholder ant-btn ant-btn-primary"   type="primary" icon={<UploadOutlined className='mr-1' />} onClick={() => handleExport(options)}>
              Export 
            </Button>
            <CSVLink
              id="csvLink"
              data={exportData}
              filename={'Users.csv'}
              style={{ display: 'none' }}
            >
              Export
            </CSVLink>
            <Button className="mr-2 placeholder ant-btn ant-btn-primary" type="primary" icon={<UploadOutlined className='mr-1' />} onClick={() => handleExportAll(options)}>
              Export All
            </Button>
            <CSVLink
              id="csvLinkAll"
              data={exportData}
              filename={'Users.csv'}
              style={{ display: 'none' }}
            >
              Export All
            </CSVLink>
            <Search
              className="search--wrap input-search-box  mt-0 mt-sm-0"
              style={{ width: '270px' }}
              placeholder="input search text"
              // enterButton="Search"
              size="large"
              // addonBefore={suffix}
              onChange={e => filterSortedTable(e)}
              // onSearch={onSearch}
            />
          </div>
        </div>
      </div>
      <main className="overflow-y-auto main--table">
        <div className="grid mx-auto">
          <div className="w-full mb-8 overflow-hidden shadow-xs">
            <div className="w-full overflow-x-auto">
              <Table
                columns={ ["customer","business_user"].includes(options.role) ? columnsCustomer :columns}
                rowKey={record => record._id}
                dataSource={dataState.length > 0 ? table : admins}
                // dataSource={table}
                onChange={onChange}
                pagination={options.pagination.total > 10 ? options.pagination : false}
                sortDirections={['ascend', 'descend']}
                loading={loading}
                scroll={{ scrollToFirstRowOnChange: true }}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default AllUsers;
