/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import VehicleAPIs from '../../../api/vehicle';
import InvoiceAPIs from '../../../api/invoice';

import AdminInvoiceFields from './invoiceFields';
import { useAlert } from 'react-alert';
import { handleErrorMessages, handleSuccessMessages } from '../../../helpers/flashMessages';
import { Modal } from 'antd';
import { validationcommon, keyupValidation } from '../../../helpers/validationMessages';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';

const EditAdminInvoice = () => {
  const [errors, setErrors] = useState({
    carUser: '',
    car: '',
    invoiceType: '',
    date: '',
    invoiceNumber: '',
    mileage: '',
    oilChange: '',
    repairDescription: '',
    totalAmount: '',
  });
  const { id: invoiceId } = useParams();

  const { confirm } = Modal;
  const history = useHistory();
  const alert = useAlert();
  const [vehicles, setVehicles] = useState([]);
  const [adminInvoice, setAdminInvoice] = useState({
    _id: '',
    carUser: '',
    car: '',
    invoiceType: '',
    date: '',
    invoiceNumber: '',
    mileage: '',
    repairDescription: '',
    discount: '',
    totalAmount: '',
  });
  const invoiceIdFields = [
    "type",
    "date",
    "number",
    "mileage",
    "repairDescription",
    "discount",
    "totalAmount"
  ];

  useEffect(() => {
    (async () => {
      try {
        if (invoiceId) {
          const { data } = await InvoiceAPIs.getInvoice(invoiceId);

          setAdminInvoice({
            ...adminInvoice,
            _id: data.invoice._id,
            carUser: data?.invoice?.vehicle?.customerId?.userId?._id,
            car: data?.invoice?.vehicle?._id,
            invoiceType: data?.invoice?.type,
            date: data?.invoice?.date,
            invoiceNumber: data?.invoice?.number,
            mileage: data?.invoice?.mileage,
            oilChange: data?.invoice?.oilChange,
            oilChangeDiscount: data?.invoice?.oilChangeDiscount,
            repairDescription: data?.invoice?.repairDescription,
            totalAmount: data?.invoice?.totalAmount,
            discount: data?.invoice?.discount
          });
        }
      } catch (e) {
        setErrors(e);
      }
    })();
  }, [invoiceId]);

  useEffect(() => {
    (async () => {
      try {
        if (adminInvoice.carUser) {
          const userVehicles = await VehicleAPIs.getCustomerVehicles(adminInvoice.carUser);
          const { vehicles,oilChange } = userVehicles.data.data.userWithVehicles.customerId;
          const userHasVehicles = !!vehicles && vehicles.length > 0;
          
            setAdminInvoice({...adminInvoice,oilChange : oilChange})
      
          if (!userHasVehicles) {
            setErrors({ message: 'User has no vehicles and therefore cannot add an invoice.' });
          } else {
            setVehicles([...vehicles]);
          }
        }
      } catch (e) {
        setErrors(e);
      }
    })();
  }, [adminInvoice.carUser]);


  const updateState = (e, type) => {

    const key = e.target.name;
    let value = e.target.value;
    setAdminInvoice({ ...adminInvoice, [key]: value });
    let error = keyupValidation(e, type);
    if (error) {
      setErrors({ ...errors, [key]: error });
      return error;
    } else {
      setErrors({ ...errors, [key]: '' });
      return error;
    }


  };

  const handleErrors = () => {
    let err = { ...errors };
    if (!adminInvoice.carUser) err.carUser = '* Choose the car user';
    else if (adminInvoice.carUser) err.carUser = '';
    if (!adminInvoice.car) err.car = '* Choose the car';
    else if (adminInvoice.car) err.car = '';
    if (adminInvoice.invoiceType == '') err.invoiceType = '* Choose the type of invoice';
    if (adminInvoice.date == '') err.date = '* Select the date';
    if (adminInvoice.repairDescription == '') err.repairDescription = '* Enter repair description';
    if (adminInvoice.totalAmount == '') err.totalAmount = '* Enter total amount';
    if (adminInvoice.invoiceType == 'Autohound Repairs') {
      if (adminInvoice.invoiceNumber == '') err.invoiceNumber = '* Enter invoice number';
      if (adminInvoice.mileage == '') err.mileage = '* Enter mileage';
      if (adminInvoice.oilChange == '') err.oilChange = '* Enter oil change count';
    } else if (adminInvoice.invoiceType != 'Autohound Repairs') {
      err.invoiceNumber = '';
      err.mileage = '';
      err.oilChange = '';
    }
    setErrors(err);
  };

  // const onSubmit = async () => {
  //   handleErrors();
  //   try {
  //     if (adminInvoice.invoiceType == 'Autohound Repairs') {
  //       if (
  //         (adminInvoice.car &&
  //           adminInvoice.carUser &&
  //           adminInvoice.invoiceType &&
  //           adminInvoice.mileage &&
  //           adminInvoice.invoiceNumber.trim() &&
  //           adminInvoice.oilChange &&
  //           adminInvoice.repairDescription &&
  //           adminInvoice.totalAmount &&
  //           adminInvoice.date) != '' &&
  //         errors.carUser == '' &&
  //         errors.car == '' &&
  //         errors.date == '' &&
  //         errors.mileage == '' &&
  //         errors.invoiceNumber == '' &&
  //         errors.oilChange == '' &&
  //         errors.repairDescription == '' &&
  //         errors.totalAmount == '' &&
  //         errors.invoiceType == ''
  //       ) {
  //         if (
  //           adminInvoice.invoiceType != 'Initial Vehicle Purchase' &&
  //           adminInvoice.totalAmount.toString().includes('-')
  //         ) {
  //           return handleErrorMessages({ customMessage: 'Total amount can not be less than 0' }, alert);
  //         }
  //         const invoicePropsData = {
  //           type: adminInvoice.invoiceType,
  //           date: adminInvoice.date,
  //           number: adminInvoice.invoiceNumber,
  //           mileage: adminInvoice.mileage,
  //           repairDescription: adminInvoice.repairDescription,
  //           discount: adminInvoice.discount,
  //           totalAmount: adminInvoice.totalAmount,
  //           oilChange: adminInvoice?.oilChange,
  //           oilChangeDiscount: adminInvoice?.oilChangeDiscount,
  //         };
  //         const response = await InvoiceAPIs.editInvoice(prevVehicleId, adminInvoice.carUser,
  //           adminInvoice.car, invoiceId, invoicePropsData);
  //         handleSuccessMessages(response, alert);
  //         history.push(`/super-admin/invoices`);
  //       }
  //     } else if (adminInvoice.invoiceType != 'Autohound Repairs') {
  //       if (
  //         (adminInvoice.invoiceType &&
  //           adminInvoice.repairDescription &&
  //           adminInvoice.totalAmount &&
  //           adminInvoice.invoiceType) != '' &&
  //         (errors.carUser,
  //         errors.car &&
  //           errors.date &&
  //           errors.repairDescription &&
  //           errors.totalAmount &&
  //           errors.invoiceType) == ''
  //       ) {
  //         if (
  //           adminInvoice.invoiceType != 'Initial Vehicle Purchase' &&
  //           adminInvoice.totalAmount.toString().includes('-')
  //         ) {
  //           return handleErrorMessages({ customMessage: 'Total amount can not be less than 0' }, alert);
  //         }
  //         const invoicePropsData = {
  //           type: adminInvoice.invoiceType,
  //           date: adminInvoice.date,
  //           number: adminInvoice.invoiceNumber,
  //           mileage: adminInvoice.mileage,
  //           repairDescription: adminInvoice.repairDescription,
  //           discount: adminInvoice.discount,
  //           totalAmount: adminInvoice.totalAmount,
  //         };
  //         const response = await InvoiceAPIs.editInvoice(prevVehicleId, adminInvoice.carUser,
  //           adminInvoice.car, invoiceId, invoicePropsData);
  //         handleSuccessMessages(response, alert);
  //         history.push(`/super-admin/invoices`);
  //       }
  //     }
  //   } catch (error) {
  //     const message = handleErrorMessages(error, alert);
  //     setErrors(message);
  //   }
  // };

  const updateInvoice = async (userId, vehicleId, prevVehicleId, updates, oilChange) => {
    try {

      if (updates.invoiceType !== 'Autohound Repairs') {
        delete updates.oilChange;
      }
      if (updates.discount == null) {
        updates.discount = '';
      }
      if (updates.oilChangeDiscount == null) {
        updates.oilChangeDiscount = ''
      }
      let copyObj;
      if (oilChange) {
        let numberOilChange = Number(oilChange)
        copyObj = { ...updates, oilChange: numberOilChange };
      }
      else {
        copyObj = { ...updates };
      }


      const response = await InvoiceAPIs.editInvoice(prevVehicleId, userId, vehicleId, invoiceId, copyObj);
      history.push(`/super-admin/invoices`);
      handleSuccessMessages(response, alert);
    } catch (e) {
      const message = handleErrorMessages(e, alert);
      setErrors({ message });
    }
  };

  return (
    <>


      <AdminInvoiceFields
        operation="edit"
        updateState={updateState}
        setAdminInvoice={setAdminInvoice}
        handler={updateInvoice}
        data={adminInvoice}
        adminInvoice={adminInvoice}
        cars={vehicles}
        errors={errors}
        setErrors={setErrors}
      />
    </>
  );
};

export default EditAdminInvoice;
