/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';

const Services = ({ services }) =>
  services.map((service, index) => (
    <div key={`${service.title}-${index}`} className="flex justify-center flex-col mt-10 mb-2">
      <div className="flex justify-center">{service.icon}</div>
      <h6 className="text-h6 font-bold mt-2">{service.title}</h6>
      <p className="text-body-2 text-text font-regular my-3 w-9/12 self-center">{service.subtitle}</p>
    </div>
  ));

Services.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object),
};

export default Services;
