/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';

import RequiredAsterisk from './RequiredAsterisk';

const CurrencyInput = ({ label, subtext, name, onChange, required, ...rest }) => (
  <div className="flex flex-col">
    <label className="inline text-small text-text mb-1">
      {label}
      {required === true && <RequiredAsterisk />}
    </label>
    {subtext?.length > 0 && <p className="italic text-text text-xs">{subtext}</p>}
    <div className="flex flex-row justify-start relative">
      <span className="z-10 absolute text-center text-subtext absolute bg-transparent rounded text-base items-center justify-center w-8 p-2.5 h-100">
        $
      </span>
      <input
        onChange={onChange}
        // value={value}
        type="number"
        step="0.01"
        className=" relative pr-4 py-3 text-body-2 pl-10 text-subtext w-full focus:outline-none rounded"
        name={name}
        placeholder="0.00"
        required={required}
        {...rest}
      />
    </div>
  </div>
);

CurrencyInput.propTypes = {
  label: PropTypes.string,
  subtext: PropTypes.string,
  name: PropTypes.string,
  // value: PropTypes.any,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

export default CurrencyInput;
