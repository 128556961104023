/* eslint-disable prettier/prettier */
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import moment from 'moment';
export const validationcommon = (value, type) => {
  switch (type) {
    case 'email': {
      if (value) {
        const strings = /^\s*(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
        if (value && value.match(strings) && value.trim()) {
          return true;
        } else {
          return false;
        }
      }
      return 'empty';
    }
    case 'firstName': {
      if ((value || '').trim()) {
        return true;
        /*const alphaNumericOnly = /^[A-Za-z]+$/;
        if (value && value.match(alphaNumericOnly) && value.trim()) {
          return true;
        } else {
          return false;
        }*/
      }
      return 'empty';
    }
    case 'lastName': {
      if ((value || '').trim()) {
        return true;
        /*const alphaNumericOnly = /^[A-Za-z]+$/;
        if (value && value.match(alphaNumericOnly) && value.trim()) {
          return true;
        } else {
          return false;
        }*/
      }
      return 'empty';
    }
    case 'mobile': {
      if (value) {
       // const trueVal = isPossiblePhoneNumber(value);
        if (value && isValidPhoneNumber(value) && value.trim()) {
          return true;
        } else {
          return false;
        }
      }
      return 'empty';
    }
    case 'discount': {
      if (value) {
        const numberOnly = /^(0|[1-9]\d*)(\.\d+)?$/;
        if (value && value.match(numberOnly) && value.trim()) {
          return true;
        } else {
          return false;
        }
      }
      return 'empty';
    }
    case 'labourRate': {
      if (value) {
        const numberOnly = /^(0|[1-9]\d*)(\.\d+)?$/;
        if (value && value.match(numberOnly) && value.trim()) {
          return true;
        } else {
          return false;
        }
      }
      return 'empty';
    }
    case 'year': {
      if (value) {
        const numberOnly = /^[0-9]+$/;
        if (
          value &&
          value.match(numberOnly) &&
          value.trim() &&
          value.length == 4 &&
          moment().format('YYYY') >= value
        ) {
          return true;
        } else {
          return false;
        }
      }
      return 'empty';
    }
    case 'plateNumber': {
      if (value) {
        if (value) {
          return true;
        } else {
          return false;
        }
      }
      return 'empty';
    }
    case 'make': {
      if (value) {
        const alphaNumericOnly = /[a-zA-Z0-9]/;
        if (value && value.match(alphaNumericOnly) && value.trim()) {
          return true;
        } else {
          return false;
        }
      }
      return 'empty';
    }
    case 'model': {
      if (value) {
        const alphaNumericOnly = /[a-zA-Z]/;
        if (value && value.match(alphaNumericOnly) && value.trim()) {
          return true;
        } else {
          return false;
        }
      }
      return 'empty';
    }
    case 'engine': {
      if (value) {
        const numberOnly = /^(0|[1-9]\d*)(\.\d+)?$/;
        if (value && value.match(numberOnly) && value.trim()) {
          return true;
        } else {
          return false;
        }
      }
      return 'empty';
    }
    case 'VIN': {
      if ((value || '').trim()) {
        return true;
        /*const alphaNumericOnly = /^[A-Za-z0-9]*$/;
        if (value && value.match(/[a-zA-Z]/) && value.match(/\d/) && value.trim() && value.length == 17) {
          return true;
        } else {
          return false;
        }*/
      }
      return 'empty';
    }
    case 'password': {
      if (value) {
        if (value && value.trim() && value.length >= 7) {
          return true;
        } else {
          return false;
        }
      }
      return 'empty';
    }
    case 'company': {
      if (value) {
        if (value && value.trim()) {
          return true;
        } else {
          return false;
        }
      }
      return 'empty';
    }
    case 'number': {
      if (value) {
        if (value && value.trim()) {
          return true;
        } else {
          return false;
        }
      }
      return 'empty';
    }
    case 'mileage': {
      const numberOnly = /^[0-9]+$/;
      if (value) {
        if (value && value.match(numberOnly) &&value.trim()) {
          return true;
        } else {
          return false;
        }
      }
      return 'empty';
    }

    // if (value) {
    //   const numberOnly = /^[0-9]+$/;
    //   if (
    //     value &&
    //     value.match(numberOnly) &&
    //     value.trim() &&
    //     value.length == 4 &&
    //     moment().format('YYYY') >= value
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }
    // return 'empty';


    case 'oilChange': {
      if (value) {
        const numberOnly = /^[0-9]+$/;
        if (value.match(numberOnly) && value.trim()) {
          return true;
        } else {
          return false;
        }
      }
      return 'empty';
    }
    case 'repairDescription': {
      if (value) {
        if (value && value.trim()) {
          return true;
        } else {
          return false;
        }
      }
      return 'empty';
    }
    case 'totalAmount': {
      if (value) {
        const numberOnly = /^(0|[1-9]\d*)(\.\d+)?$/;
        if (value && value.match(numberOnly) && value.trim()) {
          return true;
        } else {
          return false;
        }
      }
      return 'empty';
    }
    case 'garage': {
      if (value) {
        if (value && value.trim()) {
          return true;
        } else {
          return false;
        }
      }
      return 'empty';
    }
    case 'owner': {
      if (value) {
        if (value && value.trim()) {
          return true;
        } else {
          return false;
        }
      }
      return 'empty';
    }
    default:
      break;
  }
};
export const keyupValidation = (e, type) => {
  const { name, value } = e.target;
  const res = validationcommon(value, type);
  let error;
  if (res === 'empty') {
    error = '* Enter ' + name;
    return error;
  } else if (res === false) {
    error = '* Enter valid ' + name;
    return error;
  }
};
export const keyupValidationAdmin = (value, type, name) => {
  // const { name, value } = e.target;
  const res = validationcommon(value, type);
  let error;
  if (res === 'empty') {
    error = '* Enter ' + name;
    return error;
  } else if (res === false) {
    error = '* Enter valid ' + name;
    return error;
  }
};
