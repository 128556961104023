/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Box from '../../components/Box/Box';
import OnePage from '../../components/Pages/OnePage';
import { AiFillEye } from 'react-icons/ai';

import UserAPIs from '../../api/user';
import RequiredAsterisk from '../../components/RequiredAsterisk';
import { deleteToken } from '../../utilities/token';
import { useAlert } from 'react-alert';
import { keyupValidation } from '../../helpers/validationMessages';
import { handleErrorMessages, handleSuccessMessages } from '../../helpers/flashMessages';

const EnterNewPassword = () => {
  const [updatePassword, setUpdatePassword] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({
    newPassword: '',
    confirmPassword: '',
  });

  const handleErrors = () => {
    let err = { ...errors };
    if (updatePassword.newPassword == '') err.newPassword = '* Enter password';
    if (updatePassword.confirmPassword == '') err.confirmPassword = '* Enter password';
    if (updatePassword.confirmPassword && updatePassword.confirmPassword.length < 7)
      err.newPassword = '* Enter atleast 7-digits';
    if (updatePassword.newPassword && updatePassword.newPassword.length < 7)
      err.confirmPassword = '* Enter atleast 7-digits';
    if (updatePassword.newPassword != updatePassword.confirmPassword)
      err.confirmPassword = '* Confirm password and new password did not match';
    else if (
      updatePassword.newPassword &&
      updatePassword.newPassword.length >= 7 &&
      updatePassword.confirmPassword &&
      updatePassword.confirmPassword.length >= 7 &&
      updatePassword.newPassword == updatePassword.confirmPassword
    ) {
      err.confirmPassword = '';
      err.newPassword = '';
    }
    setErrors(err);
  };

  const [error, setError] = useState('');
  const history = useHistory();
  const alert = useAlert();
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };

  const logout = () => {
    deleteToken();
    history.push('/');
  };

  const updateUserPassword = async e => {
    e.preventDefault();
    handleErrors();
    if ((errors.confirmPassword && errors.newPassword) == '') {
      try {
        if (updatePassword.newPassword != updatePassword.confirmPassword) {
          return handleErrorMessages({ customMessage: 'Invalid Password!' }, alert);
        }
        const body = {
          email: localStorage.getItem('email'),
          password: updatePassword.newPassword,
        };
        const response = await UserAPIs.updateUserPassword(body);
        handleSuccessMessages(response, alert);
        logout();
      } catch (e) {
        setUpdatePassword({ newPassword: '', confirmPassword: '' });
        // const message = handleErrorMessages(e, alert);
        // setError(message);
      }
    }
  };

  return (
    <OnePage className="plans">
      <div className="container px-8 mt-10 h-hero">
        <Box title="Update Password" subtitle="Enter your new password">
          <form onSubmit={e => updateUserPassword(e)} noValidate>
            <div>
              <div className="mt-10 flex flex-col">
                <label className="text-small text-text">
                  New Password <RequiredAsterisk />
                </label>
                <div className="input-group post">
                  <input
                    onChange={e => setUpdatePassword({ ...updatePassword, newPassword: e.target.value })}
                    value={updatePassword.newPassword}
                    type={passwordShown ? 'text' : 'password'}
                    className="px-4 py-3 mt-1 w-100 bg-background text-body-2 text-subtext"
                    name="password"
                    required
                    placeholder="**********"
                    // minLength="7"
                    onBlur={handleErrors}
                    onKeyDown={handleErrors}
                    onKeyUp={handleErrors}
                    onKeyPress={handleErrors}
                  />
                  <span className="z-10 absolute text-center text-subtext absolute bg-transparent rounded text-base items-center justify-center w-8 p-2.5 h-100">
                    <button className="btn-viewPass" onClick={togglePasswordVisiblity} type="button">
                      <AiFillEye className="text-sm" />
                    </button>
                  </span>
                  {errors && errors.newPassword !== '' ? (
                    <p className="text-error font text-sm">{errors.newPassword}</p>
                  ) : null}
                </div>
                <div className="mt-6 flex flex-col">
                  <label className="text-small text-text">
                    Confirm Password <RequiredAsterisk />
                  </label>
                  <div className="input-group post">
                    <input
                      onChange={e =>
                        setUpdatePassword({ ...updatePassword, confirmPassword: e.target.value })
                      }
                      value={updatePassword.confirmPassword}
                      type={confirmPasswordShown ? 'text' : 'password'}
                      className="px-4 py-3 mt-1 w-100 bg-background text-body-2 text-subtext"
                      name="password"
                      required
                      placeholder="**********"
                      onBlur={handleErrors}
                      minLength="7"
                      onKeyUp={handleErrors}
                    />
                    <span className="z-10 absolute text-center text-subtext absolute bg-transparent rounded text-base items-center justify-center w-8 p-2.5 h-100">
                      <button className="btn-viewPass" onClick={toggleConfirmPasswordVisiblity} type="button">
                        <AiFillEye className="text-sm" />
                      </button>
                    </span>
                    {errors && errors.confirmPassword !== '' ? (
                      <p className="text-error font text-sm">{errors.confirmPassword}</p>
                    ) : null}
                  </div>
                </div>
                {/* {error?.length > 0 && <p className="text-sm text-error">{error}</p>} */}
                <button
                  type="submit"
                  className="mt-10 px-5 py-3 text-white rounded-sm bg-primary hover:bg-primary-light"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </Box>
      </div>
    </OnePage>
  );
};

export default EnterNewPassword;
