/* eslint-disable prettier/prettier */
import React from 'react';
import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import PropTypes from 'prop-types';

const BackButton = ({ link }) => (
  <Link to={link}>
    <FiArrowLeft className="text-3xl text-text" />
  </Link>
);

BackButton.propTypes = {
  link: PropTypes.string,
};

export default BackButton;
