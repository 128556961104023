/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import UserAPIs from '../../api/user';

import CustomerFields from './CustomerFields';

import useTitle from '../../hooks/useTitle';
import { useAlert } from 'react-alert';
import { handleErrorMessages, handleSuccessMessages } from '../../helpers/flashMessages';

const AddCustomer = () => {
  useTitle('Add Customer');
  const [error, setError] = useState('');
  const history = useHistory();
  const alert = useAlert();
  const globalLabourRate = localStorage.getItem('globalLabourRate');

  const defaultData = {
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    customerId: {
      city: '',
      province: '',
      street: '',
      postalCode: '',
      labourRate: globalLabourRate,
      notes: '',
      status: true,
      discount: '0'
    },
    role: 'customer',
  };

  const addCustomer = async customer => {
    customer.email = customer.email.trim();
    try {
      customer['customerData'] =  JSON.parse(JSON.stringify(customer['customerId']));
      //delete customer['customerId'];
      const response = await UserAPIs.addCustomer(customer);
      handleSuccessMessages(response, alert);
      history.push('/dashboard');
    } catch (e) {
      const message = handleErrorMessages(e, alert);
      setError(message);
    }
  };

  return <CustomerFields title="Add customer" submitHandler={addCustomer} data={defaultData} error={error} />;
};

export default AddCustomer;
