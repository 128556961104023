/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FiPlus, FiSearch } from 'react-icons/fi';
import { Tooltip } from 'antd';
import { AiFillPrinter } from 'react-icons/ai';

import Vehicle from './Vehicle/Vehicle';

const Vehicles = ({ vehicles, id }) => {
  const [table, setTable] = useState();
  const [sortedTable, setSortedTable] = useState(vehicles);

  useEffect(() => {
    setTable(vehicles);
  }, []);

  const filterSortedTable = e => {
    const query = e.target.value;
    const sorted = getVehiclesWithMatchingSubstrings(query);
    setSortedTable(sorted);
  };

  const getVehiclesWithMatchingSubstrings = substring => {
    return table.filter(data => compareSearchInputWithVehicles(data, substring));
  };

  const compareSearchInputWithVehicles = (vehicle, substring) => {
    const vin = `${vehicle['vin'].toUpperCase()}`;
    const plateNo = `${vehicle['plateNumber'].toUpperCase()}`;
    return plateNo.includes(substring.toUpperCase()) || vin.includes(substring.toUpperCase());
  };

  return (
    <div className="mt-10">
      <div className="flex flex-col">
        <div className="items-center">
          <div className="float-left my-2">
            <h6 className="text-h6 text-text font-bold">Vehicles</h6>
            {/* <div className="float-left flex flex-row mr-auto">
              <input
                onChange={e => filterSortedTable(e)}
                name="vehicle"
                placeholder="Find vehicle"
                className="py-2 px-4 text-gray-1 font-normal rounded-r-none"
              />
              <button className="bg-primary p-3 text-white rounded-r-sm">
                <FiSearch className="text-sm" />
              </button>
            </div> */}
          </div>
          <div className="float-right">
            <div className="float-left flex flex-row mr-3 sm:mb-2 mb-2">
              <span className="z-10 absolute text-center text-subtext absolute bg-transparent rounded text-base items-center justify-center w-8 p-2.5 h-100">
                <FiSearch className="text-sm" />
              </span>
              <input
                onChange={e => filterSortedTable(e)}
                name="vehicle"
                placeholder="Enter VIN/License number"
                className="pl-8 py-2 px-4 text-gray-1 font-normal rounded-r-none"
              />
            </div>
            <button className="bg-primary hover:bg-opacity-80 py-2 px-4 text-white rounded-sm">
              <Link to={`/customer/${id}/vehicle/add`}>
                <FiPlus className="inline-block align-middle mr-2 text-base" />
                <p className="inline-block align-middle">Add Vehicle</p>
              </Link>
            </button>
          </div>
        </div>
        <div className="flex flex-col custom_scroll w-100">
          {sortedTable && sortedTable.length > 0 ? (
            <>
              {sortedTable.map((vehicle, i) => {
                let vehicleData = vehicle;
                vehicleData.plateNumber = vehicle.plateNumber.toUpperCase();
                vehicleData.vin = vehicle.vin.toUpperCase();
                return (
                  <>
                    <div className="d-flex mt-4" key={i.toString()}>
                      <Vehicle key={i.toString()} vehicle={vehicleData} customerId={id} />
                    </div>
                  </>
                );
              })}
            </>
          ) : (
            <>
              <h1 className="text-text">No vehicles found.</h1>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

Vehicles.propTypes = {
  vehicles: PropTypes.array,
  id: PropTypes.string,
};

export default Vehicles;
