/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { handleErrorMessages, handleSuccessMessages } from '../../../../helpers/flashMessages';

import AdminAPIs from '../../../../api/admin';

import CustomerFields from './customerFields';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { keyupValidation } from '../../../../helpers/validationMessages';

const AddCustomers = () => {
  const [error, setError] = useState('');
  const history = useHistory();
  const alert = useAlert();

  const [customer, setCustomer] = useState({
    firstName: '',
    lastName: '',
    status: true,
    email: '',
    mobile: '',
    garage: '',
    street: '', city: '',
    province: '',
    postalCode: '',
    discount: '',
    labourRate: '',
  });
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    garage: '',
    discount: '',
    labourRate: '',
  });
  const [phone, setPhone] = useState('');

  
  const updateState = (e, type) => {
    const key = e.target.name;
    let value = e.target.value;

    // const isCustomerIdField = customerIdFields.includes(key);
    if (key == 'mobile') {
      setPhone(value);
      customer.mobile = phone;
    }
    if (key == 'labourRate') {
      value =
        value.indexOf('.') >= 0
          ? value.substr(0, value.indexOf('.')) + value.substr(value.indexOf('.'), 3)
          : value;
    }
    // if (isCustomerIdField) {
      if (key === 'status') {
        value = value === 'true'; // converts the string into a Boolean value
      }
      // setCustomerData({ ...customer, customerId: { ...customer.customerId, [key]: value } });
    // } else {
      setCustomer({ ...customer, [key]: value });
    // }
    let error = keyupValidation(e, type);
    let err = { ...errors };
    if (phone == '' && customer.mobile == '') err.mobile = '* Enter mobile number';
    else if (!isPossiblePhoneNumber(phone)) err.mobile = '* Enter valid phone number';
    else if (phone != '' || !isPossiblePhoneNumber(phone)) err.mobile = '';
    setErrors(err);
    if (error) {
      setErrors({ ...errors, [key]: error });
      return error;
    } else {
      setErrors({ ...errors, [key]: '' });
      return error;
    }
  };
  

  const addCustomer = async () => {
    try {
      const customerPropsData = {
        firstName: customer.firstName,
        lastName: customer.lastName,
        mobile: customer.mobile,
        email: customer.email,
        garage: customer.garage,
        role: 'customer',
        customerData: {
          city: customer.city,
          province: customer.province,
          street: customer.street,
          postalCode: customer.postalCode,
          labourRate: customer.labourRate,
          status: customer.status,
          discount: customer.discount,
          company: customer.company,
        },
      };
      const response = await AdminAPIs.addCustomer(customerPropsData);
      if(response.status === 200){
        const userName = JSON.parse(localStorage.getItem('usersName'));
        const userNameIndex = userName.findIndex(data => data.id === response.data.customer._id);
        if(userNameIndex !== -1){
          userName[userNameIndex].firstName = response.data.customer.firstName;
          userName[userNameIndex].lastName = response.data.customer.lastName;
          userName[userNameIndex].email = response.data.customer.email;
          userName[userNameIndex].mobile = response.data.customer.mobile;
          localStorage.setItem('usersName', JSON.stringify(userName));
        }else{
          let newArr = [...userName, { id: response.data.customer._id, firstName:response.data.customer.firstName, lastName: response.data.customer.lastName, email: response.data.customer.email, mobile: response.data.customer.mobile  }]
          localStorage.setItem('usersName', JSON.stringify(newArr));
        }
      }
      handleSuccessMessages(response, alert);
      history.push('/admin/customer');
    } catch (error) {
      const message = handleErrorMessages(error, alert);
      setError(message);
    }
  };
  return (
    <div>
      <CustomerFields
        operation="add"
        customer={customer}
        updateState={updateState}
        setCustomer={setCustomer}
        submitHandler={addCustomer}
        data={customer}
        phone={phone}
        setPhone={setPhone}
      />
    </div>
  );
};

export default AddCustomers;



