/* eslint-disable prettier/prettier */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { isCurrentTokenValid } from '../utilities/token';
import Navbar from '../components/Navbar/Navbar';
import Layout1 from '../components/Layouts/layout1';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const hasLoggedInUser = isCurrentTokenValid();

  return (
    <Route
      {...rest}
      render={props =>
        hasLoggedInUser ? (
          <Layout1>
            <Component {...props} />
          </Layout1>
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );
};
PrivateRoute.propTypes = {
  component: PropTypes.func,
};

export default PrivateRoute;
