/* eslint-disable react/display-name */
/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { twoDecimalPlaceValue } from '../../helpers/twoDecimalPlaceValue';
import { BiHelpCircle } from 'react-icons/bi';

const ReportTable = React.forwardRef(({ reports, chosenReport,carDetails ,receiptMessage}, ref) => {
  const reportNames = [
    'Autohound Report',
    'Additional Vehicle Cost Report',
    'Collision & Modification Report',
    // 'Business Use Tracker Report',
  ];

  const headers = {
    0: ['Repairs', 'RCPA', 'Network Average Repair', 'Network Average RCPA'],
    1: ['Initial Vehicle Purchase', 'Combined Cost', 'Other Shop Repairs', 'Combined Cost'],
    2: ['Body Repair', 'Combined Cost', 'Performance Enhancements', 'Combined Cost'],
    // 3: [
    //   'Insurance Cost',
    //   'License Fees',
    //   'Fuel',
    //   'Mileage In',
    //   'Mileage Out',
    //   'Mileage',
    //   'Total Business Expense',
    // ],
  };

  const reportTypes = Object.keys(reports);
  const currentReportName = reportTypes[chosenReport];
  const currentReport = reports[currentReportName] ?? {};
  const { finalTotals } = currentReport;
  const yearsInReports = currentReport.reports.map(row => row.year);

  const headerHelpTextDict = {
    'NetworkAverageRepair' : 'Network Average Repair is the average yearly repair cost of all the same year, make, model, and engine size vehicles within the network. You can compare what your vehicle repair cost is with the network average cost. If the repair cost data is the same as your vehicle repair cost data, then the data shows there is only one vehicle within the network.',
    'NetworkAverageRCPA': 'Network Average RCPA is the network average repair cost over time in five year intervals.This data not only shows the average repair cost over time but also how long that specific vehicle remains in the network for. The more vehicle repair data accumulated, the more accurate this data becomes.'
  };
  return (
    <>
      {!!currentReport.reports && Object.entries(currentReport.reports).length > 0 ? (
        <div className="table-responsive bg-white my-5 table-striped p-5" ref={ref}>
          <div style={{ padding: '5px', marginTop: '0px' }}  className='print-block hidden'>
            <h3 style={{ fontWeight: 'bold', fontSize: '22px' }}>Vehicle Details:</h3>
            {
              carDetails?.vin ?
                <div style={{ width: '50%', float: 'left' }}>
                  <label style={{ fontWeight: 'bold' }}>VIN:</label>
                  <h4 style={{ fontSize: '18px' }}>{carDetails?.vin}</h4>
                </div> : ''
            }
            {
              carDetails?.yearManufactured ?
                <div style={{ width: '50%', float: 'left' }}>
                  <label style={{ fontWeight: 'bold' }}>Year Manufactured:</label>
                  <h4 style={{ fontSize: '18px' }}>{carDetails?.yearManufactured}</h4>
                </div> : ''
            }
            {
              carDetails?.make ?
                <div style={{ width: '50%', float: 'left' }}>
                  <label style={{ fontWeight: 'bold' }}>Make:</label>
                  <h4 style={{ fontSize: '18px' }}> {carDetails?.make}</h4>
                </div> : ''
            }

            {carDetails?.model ?
              <div style={{ width: '50%', float: 'left' }}>
                <label style={{ fontWeight: 'bold' }}>Model:</label>
                <h4 style={{ fontSize: '18px' }}> {carDetails?.model}</h4>
              </div> : ''
            }
            {carDetails?.engine ?
              <div style={{ width: '50%', float: 'left' }}>
                <label style={{ fontWeight: 'bold' }}>Engine:</label>
                <h4 style={{ fontSize: '18px' }}> {carDetails?.engine}</h4>
              </div> : ''
            }
            {carDetails?.transmission ?
              <div style={{ width: '50%', float: 'left' }}>
                <label style={{ fontWeight: 'bold' }}>Transmission:</label>
                <h4 style={{ fontSize: '18px' }}> {carDetails?.transmission}</h4>
              </div> : ''
            }
            {carDetails?.body ?
              <div style={{ width: '50%', float: 'left' }}>
                <label style={{ fontWeight: 'bold' }}> Body:</label>
                <h4 style={{ fontSize: '18px' }}>{carDetails?.body}</h4>
              </div> : ''
            }

            {
              carDetails?.color ?
                <div style={{ width: '50%', float: 'left' }}>
                  <label style={{ fontWeight: 'bold' }}>Color:</label>
                  <h4 style={{ fontSize: '18px' }}>{carDetails?.color}</h4>
                </div>
                : ''
            }
            {receiptMessage && (
              <div style={{ marginTop: '20px' }} className="report-message text-primary font-bold print-block hidden">
                Receipts should be provided for accuracy of data!
              </div>
            )}
          </div>
          
          <table
            className={
              currentReportName == 'additionalVehicleReport' ||
              // ? 'table-auto compare--table additional-vehicle-table'
              currentReportName == 'collisionModificationReport'
                ? 'table-auto compare--table collision-modification-table'
                : 'table-auto compare--table'
            }
          >
            <thead>
              <tr className="p-5 font-medium text-left bg-white border-b text-text text-subtitle-2 border-secondary">
                <th className="p-5 text-center cursor-pointer change-color">Year</th>
                {headers[chosenReport].map((header, i) => (
                  <th key={i.toString()} className="p-5 text-center cursor-pointer change-color">
                    {header == 'RCPA' ? (
                      <div>
                        {header} <span className="block">(Repair Cost Per Annum)</span>
                      </div>
                    ) : (
                      header
                    )}
                    {['Network Average Repair', 'Network Average RCPA'].indexOf(header) !== -1 ? <span>
                      <Tooltip placement="topRight" className={'table-header-tooltip'} title={headerHelpTextDict[header.split(' ').join('')]}>
                        <BiHelpCircle className="text-base" />
                      </Tooltip>
                      </span>
                    : null}
                  </th>
                ))}
              </tr>
              <tr className="p-5 font-medium text-left bg-white border-b text-primary text-body-2 border-secondary">
                <th className="p-5 text-center cursor-pointer">Totals</th>
                {Object.keys(finalTotals).map((currentKey, i) => {
                  const isAutohoundReport = currentReportName === 'autohoundReport';
                  if (isAutohoundReport) {
                    return (
                      <th key={i.toString()} className="p-5 text-center cursor-pointer">
                        {currentKey === 'total'
                          ? `$${finalTotals[currentKey]}`
                          : `scroll down to specific year for the ${headers[0][i]}`}
                      </th>
                    );
                  } else {
                    return (
                      <th key={i.toString()} className="p-5 text-center cursor-pointer">
                        {[
                          'combinedInitialVehicleRCPA',
                          'combinedOtherShopRCPA',
                          'combinedBodyShopRepareRCPA',
                          'combinedPerformanceEnhancementRCPA',
                        ].includes(currentKey)
                          ? ''
                          : `$${finalTotals[currentKey]}`}
                      </th>
                    );
                  }
                })}
              </tr>
            </thead>
            <tbody>
              {currentReport.reports.map((report, i) => {
                const categories = Object.keys(report).filter(keys => keys !== 'year');
                return (
                  <tr key={i} className="bg-white border-b hover:bg-gray-4 border-secondary">
                    <td className="p-5 font-normal text-center text-gray-1 text-body-2">
                      {yearsInReports[i]}
                    </td>
                    {categories.map((category, x) => (
                      <td
                        key={x.toString()}
                        className="p-5 font-normal text-center text-gray-1 text-body-2"
                      >{`${
                        report[category] === null
                          ? '-'
                          : '$' + twoDecimalPlaceValue(report[category].toString())
                      }`}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="mt-10 text-sm text-center text-error" ref={ref}>
          No reports for the category: {reportNames[chosenReport]}
        </p>
      )}
    </>
  );
});

ReportTable.propTypes = {
  chosenReport: PropTypes.number,
  reports: PropTypes.object,
  carDetails:PropTypes.object,
  receiptMessage:PropTypes.bool,
  ref: PropTypes.object,
};

export default ReportTable;
