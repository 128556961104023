/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { handleErrorMessages, handleSuccessMessages } from '../../../../helpers/flashMessages';

import AdminAPIs from '../../../../api/admin';

import GarageOwnerFields from './garageOwnerFields';
import DemoContext from './garageOwner';

const AddGarageOwner = () => {
  const [error, setError] = useState('');
  const [value, setValue] = useState();
  const ipRef = useRef(0);
  const data = useContext(DemoContext);
  const [res, setRes] = useState(0);
  const history = useHistory();
  const alert = useAlert();
  const [phone, setPhone] = useState('');

  const [owner, setOwner] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    garage: '',
    role: 'owner',
    status: true,
    city: '',
    province: '',
    street: '',
    postalCode: '',
    notes: ''
  });

  const updateState = e => {
    const key = e.target.name;
    let value = e.target.value;
    if (key == 'mobile') {
      setPhone(value);
    }
    if (key === 'status') {
      value = value === 'true'; // converts the string into a Boolean value
    }
    setOwner({ ...owner, [key]: value });
  };

  const addOwner = async () => {
    try {
      const response = await AdminAPIs.addOwner(owner);
      if(response.status === 200){
        const userName = JSON.parse(localStorage.getItem('usersName'));
        const userNameIndex = userName.findIndex(data => data.id === response.data.owner._id);
        if(userNameIndex !== -1){
          userName[userNameIndex].firstName = response.data.owner.firstName;
          userName[userNameIndex].lastName = response.data.owner.lastName;
          userName[userNameIndex].email = response.data.owner.email;
          userName[userNameIndex].mobile = response.data.owner.mobile;
          localStorage.setItem('usersName', JSON.stringify(userName));
        }else{
          let newArr = [...userName, { id: response.data.owner._id, firstName:response.data.owner.firstName, lastName: response.data.owner.lastName, email: response.data.owner.email, mobile: response.data.owner.mobile  }]
          localStorage.setItem('usersName', JSON.stringify(newArr));
        }
      }
      handleSuccessMessages(response, alert);
      history.push('/admin/garage-owner');
    } catch (e) {
      const message = handleErrorMessages(e, alert);
      setError(message);
    }
  };
  return (
    <div>
      <GarageOwnerFields
        operation="add"
        owner={owner}
        updateState={updateState}
        setOwner={setOwner}
        submitHandler={addOwner}
        value={value}
        data={owner}
        phone={phone}
        setPhone={setPhone}
      />
    </div>
  );
};

export default AddGarageOwner;
