/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';

import RequiredAsterisk from './RequiredAsterisk';

const CurrencyTotalAmountInput = ({
  label,
  subtext,
  name,
   value,
  onChange,
  onblur,
  onkeypress,
  requiredfield,
  required,
  maxLength,
  minLength,
  ...rest
}) => (
  <div className="flex flex-col">
    <label className="inline text-small text-text mb-1">
      {label}
      {required === true && <RequiredAsterisk />}
      {requiredfield === true && <RequiredAsterisk />}
    </label>
    {subtext?.length > 0 && <p className="italic text-text text-xs">{subtext}</p>}
    <div className="flex flex-row justify-start relative">
    <span className="z-10 absolute text-center text-subtext absolute bg-transparent rounded text-base items-center justify-center w-8 p-2.5 h-100">
        $
      </span>
   
    <input
      onChange={onChange}
      onKeyUp={onkeypress}
      onBlur={onblur}
      value={value}
      type="text"
      className=" relative pr-4 py-3 text-body-2 pl-10 text-subtext w-full focus:outline-none rounded"
      name={name}
      required={required}
      maxLength={maxLength}
      minLength={minLength}
      {...rest}
    />
    </div>
  </div>
);

CurrencyTotalAmountInput.propTypes = {
  label: PropTypes.string,
  subtext: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onkeypress: PropTypes.func,
  onblur: PropTypes.func,
  requiredfield : PropTypes.bool,
  required: PropTypes.bool,
  minLength: PropTypes.string,
  maxLength: PropTypes.string,
};

export default CurrencyTotalAmountInput;
