/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { handleErrorMessages, handleSuccessMessages } from '../../../../helpers/flashMessages';
import AdminAPIs from '../../../../api/admin';

import UserFields from './userFields';
import { keyupValidationAdmin } from '../../../../helpers/validationMessages';

const AddUser = () => {
  const [error, setError] = useState('');
  const history = useHistory();
  const alert = useAlert();

  const [admin, setAdmin] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    status: true,
    role: '',
    garage: '', 
    labourRate: '',
    discount: '',
    city: '',
    province: '',
    street: '',
    postalCode: '',
    notes: '',
  });
  const [phone, setPhone] = useState('');
  const [isApiCall, setIsApiCall] = useState(false);

  const updateState = (e, type) => {
    const key = e.target.name;
    let value = e.target.value;
    keyupValidationAdmin(value, type, key);
    if (key == 'mobile') {
      setPhone(value);
    }
    if (key == 'labourRate') {
      value =
        value.indexOf('.') >= 0
          ? value.substr(0, value.indexOf('.')) + value.substr(value.indexOf('.'), 3)
          : value;
    }
    if(key === "status") {
      value = value === 'true'
    }
    setAdmin({ ...admin, [key]: value });
  };

  const addAdmin = async () => {
    try {
      if(admin._id){
        delete admin._id
      }
      setIsApiCall(true)
      const response = await AdminAPIs.addUsers(admin);
      if(response.status === 200){
        const userName = JSON.parse(localStorage.getItem('usersName'));
        const userNameIndex = userName.findIndex(data => data.id === response.data.user._id);
        if(userNameIndex !== -1){
          userName[userNameIndex].firstName = response.data.user.firstName;
          userName[userNameIndex].lastName = response.data.user.lastName;
          userName[userNameIndex].email = response.data.user.email;
          userName[userNameIndex].mobile = response.data.user.mobile;
          localStorage.setItem('usersName', JSON.stringify(userName));
        }else{
          let newArr = [...userName, { id: response.data.user._id, firstName:response.data.user.firstName, lastName: response.data.user.lastName, email: response.data.user.email, mobile: response.data.user.mobile  }]
          localStorage.setItem('usersName', JSON.stringify(newArr));
        }
        setIsApiCall(false)
      }
      handleSuccessMessages(response, alert);
      history.push('/admin/all-users');
    } catch (e) {
      const message = handleErrorMessages(e, alert);
      setError(message);
      setIsApiCall(false)
    }
  };

  return (
    <div>
      <UserFields
        operation="add"
        updateState={updateState}
        setAdmin={setAdmin}
        admin={admin}
        submitHandler={addAdmin}
        data={admin}
        phone={phone}
        setPhone={setPhone}
        isApiCall={isApiCall}
      />
    </div>
  );
};

export default AddUser;
