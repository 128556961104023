/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';

import BackButton from './BackButton';

const FormHeader = ({ title, backLink }) => (
  <div className="flex flex-row items-center pb-3">
    <BackButton link={backLink} />
    <h5 className="text-h5 text-text font-bold ml-2">{title}</h5>
  </div>
);

FormHeader.propTypes = {
  title: PropTypes.string,
  backLink: PropTypes.string,
};

export default FormHeader;
