/* eslint-disable prettier/prettier */
import React,{useState,useEffect,createContext} from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { isCurrentTokenValid } from '../utilities/token';
import { isCurrentUserAnAdmin } from '../utilities/authentication';
import Layout2 from '../components/Layouts/layout2';
import UserAPIs from '../api/user';
import { deleteToken, getTokenPayload } from '../utilities/token';
import {  useHistory } from 'react-router-dom';
export const RenderContentContextt = createContext();

const AdminRoute = ({ component: Component, ...rest }) => {
  const isValidUser = isCurrentTokenValid() && isCurrentUserAnAdmin();
  const history = useHistory();
  if(!getTokenPayload())
  {
    history.push('/')
  }
    
   const { id,name,firstName,lastName,email } = getTokenPayload();
   
  const [editProfile, setEditProfile] = useState({
    firstName: "",
    lastName: "",
});
const [profileImage, setProfileImage] = useState('');
const [phone, setPhone] = useState('')

  let updatedData = ()=>{

    (async () => {
      try {
        const { data } = await UserAPIs.getUser(id);
        const { user } = data;
        setEditProfile({
          ...editProfile,
          firstName: user.firstName,
          lastName: user.lastName,
        })
        setProfileImage(`${process.env.REACT_APP_S3_BUCKET_URL}${user.profilePic}`)
        setPhone(user.mobile || "");
      } catch (e) {
        console.log(e);
      }
    })();
  }

  // useEffect(()=>{
  //   updatedData()
  // },[])

  return (
    <Route
      {...rest}
      render={props =>
        isValidUser ? (
          <RenderContentContextt.Provider value={{updatedData,profileImage,editProfile}}>
          <Layout2>
            {' '}
            <Component {...props} />{' '}
          </Layout2>
          </RenderContentContextt.Provider>
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );
};
AdminRoute.propTypes = {
  component: PropTypes.func,
};

export default AdminRoute;
