/* eslint-disable prettier/prettier */
import React from 'react';

import { FiDollarSign, FiHeart, FiShield, FiGlobe, FiAward, FiClock } from 'react-icons/fi';

import Services from './Services';

const ServicesSection = () => {
  const heading = `We offer a complete range of services:`;
  const iconClasses = 'text-primary text-4xl my-5';
  const servicesOffered = [
    {
      icon: <FiDollarSign className={iconClasses} />,
      title: 'Enhance resale',
      subtitle: 'Print your personalized reports for top dollar resale value on your vehicle.',
    },
    {
      icon: <FiClock className={iconClasses} />,
      title: 'Get your time back',
      subtitle: 'Monitor mechanical service repairs for business vehicle fleet use',
    },
    {
      icon: <FiShield className={iconClasses} />,
      title: 'Establish trust',
      subtitle: `Vehicle data only acquired with consumer's consent.`,
    },
    {
      icon: <FiGlobe className={iconClasses} />,
      title: 'Save the planet',
      subtitle:
        'Monitor vehicle cost to prolong vehicle life, reducing manufacturing production to slow down climate change.',
    },
    {
      icon: <FiAward className={iconClasses} />,
      title: 'Compare and compete',
      subtitle:
        'Compare customer spending habits and common repair cost with the same make and model vehicles within the network.',
    },
    {
      icon: <FiHeart className={iconClasses} />,
      title: 'Encourage loyalty',
      subtitle: 'Easily manage your loyalty program to maintain customer retention.',
    },
  ];

  return (
    <div className="container px-8 pb-16">
      <div className="pt-10">
        <div className="flex justify-center pt-5 pb-8">
          <h3 className="text-h3 font-bold text-text">{heading}</h3>
        </div>
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-center">
          <Services services={servicesOffered} />
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
