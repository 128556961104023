/* eslint-disable prettier/prettier */
import HttpService from '../services/http';

const getGarages = () => {
  const http = new HttpService(`/garage`);
  return http.unsecuredGet();
};

const getGarageGlobalLabourRate = (garageId) => {
  const http = new HttpService(`/garage/${garageId}`);
  return http.unsecuredGet();
};

export default { getGarages, getGarageGlobalLabourRate };
