/* eslint-disable prettier/prettier */
import { getToken, getTokenPayload } from './token';

export function isAuthenticated() {
  const token = getToken();

  return !!token;
}

export function isCurrentUserACustomer() {
  const { role } = getTokenPayload();

  return ['customer'].includes(role);
}

export function isCurrentUserABusinessUser() {
  const { role } = getTokenPayload();

  return ['business_user'].includes(role);
}

export function isCurrentUserAnOwner() {
  const { role } = getTokenPayload();

  return role === 'owner';
}

export function isCurrentUserAnAdmin() {
  const { role } = getTokenPayload();

  return role === 'admin';
}
