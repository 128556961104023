/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import OnePage from '../../components/Pages/OnePage';
import Box from '../../components/Box/Box';

import UserAPIs from '../../api/user';
import { TailSpin } from 'react-loading-icons';
import RequiredAsterisk from '../../components/RequiredAsterisk';
import { useAlert } from 'react-alert';
import { keyupValidation } from '../../helpers/validationMessages';
import { handleErrorMessages, handleSuccessMessages } from '../../helpers/flashMessages';

const ForgotPassword = () => {
  const [email, setEmail] = useState();
  const [loginCheck, setLoginCheck] = useState();
  const [error, setError] = useState('');
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const alert = useAlert();
  const [errors, setErrors] = useState('');

  useEffect(() => {
    setEmail(localStorage.getItem('email') ?? '')
    setLoginCheck(localStorage.getItem('email') && localStorage.getItem('token') ? true : false)
    
  }, [])
  const keyDown = e => {
    const value = e.target.value;
    const strings = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = strings.test(email);
    let err = { ...errors };
    if (email == '') {
      err.email = '* Enter email';
    }
    if (email != '') {
      if (email && !valid && !email.trim()) {
        err.email = '* Enter a valid email';
      } else err.email = '';
    }
    setErrors(err);
  };

  const handleErrors = () => {
    const strings = /^\s*(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
    if (email != '' && !email.match(strings)) setErrors('* Enter a valid email');
    if (email == '') setErrors('* Enter email');
    else if (email != '' && email.match(strings) && email.trim()) setErrors('');
  };

  const processForgotPassword = async e => {
    e.preventDefault();
    handleErrors();
    if (email != '') {
      try {
        setLoading(true);
        const response = await UserAPIs.processForgotPassword(email);
        localStorage.setItem('email', email);
        handleSuccessMessages(response, alert);
        history.push(`/forgot-password/verify/${email}`);
      } catch (e) {
        setEmail('');
        const message = handleErrorMessages(e, alert);
        setError(message);
      }
    }
    setLoading(false);
  };

  return (
    <OnePage className="plans">
      <div className="container px-8 mt-10 h-hero">
        <Box title="Forgot Password">
          <form onSubmit={e => processForgotPassword(e)} noValidate>
            <div className="mt-10 flex flex-col">
              <label className="text-small text-text">
                Email <RequiredAsterisk />
              </label>
              <input
                onChange={e => setEmail(e.target.value.trim())}
                value={email}
                disabled={loginCheck}
                type="email"
                className="px-4 py-3 mt-1 bg-background text-body-2 text-subtext"
                name="email"
                required
                placeholder="johndoe@mail.com"
                onKeyDown={handleErrors}
                onKeyPress={handleErrors}
                onKeyUp={handleErrors}
              />
              {errors && errors !== '' ? <p className="text-error font text-sm">{errors}</p> : null}
              <button
                type="submit"
                className="px-5 py-3 mt-10 text-white rounded-sm bg-primary hover:bg-primary-light"
              >
                <span className="flex justify-center">
                  {isLoading ? <TailSpin height={24} /> : 'Continue'}
                </span>
              </button>
              {/* {error?.length > 0 && (
                <div className="mt-5">
                  <p className="text-sm text-center text-error">{error}</p>
                </div>
              )} */}
            </div>
          </form>
        </Box>
      </div>
    </OnePage>
  );
};

export default ForgotPassword;
