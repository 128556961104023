/* eslint-disable prettier/prettier */
import React, { useEffect, useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu, Dropdown } from 'antd';
import { PoweroffOutlined, DownOutlined, SettingOutlined } from '@ant-design/icons';
import { FiUnlock } from 'react-icons/fi';
import { AiOutlineHome, AiFillAppstore } from 'react-icons/ai';
import UserAPIs from '../../api/user'
import { getTokenPayload } from '../../utilities/token';
import { RenderContentContextUser } from '../../routes/PrivateRoute'
import {
  isCurrentUserACustomer,
  isCurrentUserAnAdmin,
  isCurrentUserAnOwner,
  isCurrentUserABusinessUser,
} from '../../utilities/authentication';
import AppContext from '../../AppContext';

const LoggedinNavbar = ({ logout }) => {
  const history = useHistory();


  const { id, name, firstName, lastName, email } = getTokenPayload();
  let link = '';


  const { updatedUserData, profileImage, editProfile } = useContext(AppContext) || {};


  useEffect(() => {
      updatedUserData()
  }, [])


  if (isCurrentUserAnAdmin()){
    link = '/admin'
  }
  else{
    link = '/'
  } 
  
  // if (isCurrentUserACustomer() || isCurrentUserABusinessUser() || isCurrentUserAnOwner()) link = `/`;
  // if (isCurrentUserAnOwner()) link = '/dashboard';
  // if (isCurrentUserAnOwner()) link = '/';


  const handleClick = () => {
    localStorage.setItem('email', email);
    history.push('/user/reset-password');
  };
  const handleClickHome = () => {
  
    history.push('/');
  }

  //   const [editProfile, setEditProfile] = useState({
  //     firstName: "",
  //     lastName: "",
  // });
  // const [profileImage, setProfileImage] = useState('');

  // const fetchUserProfileData = ()=>{
  //   (async () => {
  //     try {
  //       const { data } = await UserAPIs.getUser(id);
  //       const { user } = data;
  //       setEditProfile({
  //         ...editProfile,
  //         firstName: user.firstName,
  //         lastName: user.lastName,
  //         email: user.email,
  //       })
  //       setProfileImage(`${process.env.REACT_APP_S3_BUCKET_URL}${user.profilePic}`)
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   })();
  // }

  // useEffect(() => {
  //   fetchUserProfileData()
  // },[])

  return (
    <div className="px-2 md:px-20 flex flex-wrap justify-betw sm:justify-between items-center">
      <div className="flex items-center justify-center float-left h-full py-0">
        <Link
          to={link}
          className="mr-4 font-semibold bg-transparent text-primary text-h4 hover:text-opacity-80 active:opacity-80 disabled:bg-disabled"
        >
          <img className="w-24 site-logo" src={require('../../assets/autohound-logo-orange.png').default} alt="logo" />
        </Link>
      </div>
      <div className="float-right py-0 ">
        <div className="grid top--grid wk-dropdown">
          <Dropdown
            arrow={true}
            trigger={['click']}
            overlay={
              <Menu
              selectable ={true}>
                <Menu.Divider className="visible_sml" />
                <Menu.Item>
                  <AiOutlineHome className="mr-2" />
                  <a onClick={handleClickHome}>Home</a>
                </Menu.Item>
                <Menu.Item>
                  <AiFillAppstore className="mr-2" />
                  <Link to={isCurrentUserAnAdmin() ? '/admin/dashboard' : isCurrentUserACustomer() || isCurrentUserABusinessUser() ? `/customer/${id}`:'/dashboard'}>Dashboard
                  </Link>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item>
                  <FiUnlock className="mr-2" />
                  <a onClick={handleClick}>Reset Password</a>
                </Menu.Item>
                <Menu.Item>
                  <SettingOutlined />
                  <Link to={isCurrentUserAnAdmin() ? "/admin/settings" : "/settings"}>Settings</Link>
                </Menu.Item>
               
                <Menu.Divider />
                <Menu.Item>
                  <PoweroffOutlined className="text-danger" />
                  <a className="text-danger" onClick={() => logout()}>
                    Logout
                  </a>
                </Menu.Item>
              </Menu>
            }
            placement="bottomRight"
          >
            <button
              type="button"
              className="waves-effect waves-block waves-light profile-button flex"
              onClick={e => e.preventDefault()}
            >

              <span className="user-avatar avatar-status avatar-online">
                <img className="w-10" src={profileImage && editProfile.profilePic ? profileImage : require('../../assets/avatar_user.jpg').default} alt="avatar" />
              </span>
              {localStorage.getItem('Fname') === null ? (
                <span className="ml-3 mt-2">
                  <span className="text-truncate">{name}</span>
                </span>
              ) : isCurrentUserACustomer() || isCurrentUserABusinessUser() ? (
                <span className="ml-3 mt-2">
                  <span className="text-truncate">
                    {localStorage.getItem('Fname') + ' ' + localStorage.getItem('Lname')}
                  </span>
                </span>
              ) : (
                <span className="ml-3 mt-2">
                  <span className="text-truncate">{editProfile?.firstName + ' ' + editProfile?.lastName}</span>
                </span>
              )}
              <span className="ml-3 mt-2 drop--outer">
                <span className="text-truncate">
                  <DownOutlined />
                </span>
              </span>
            </button>
          </Dropdown>
        </div>
      </div>
      {/* {isResetPassword ? <EnterNewPassword resetPasswordStatus={isResetPassword} /> : ''} */}
    </div>
  );
};

LoggedinNavbar.propTypes = {
  logout: PropTypes.func,
  name: PropTypes.string,
};

export default LoggedinNavbar;
