/* eslint-disable prettier/prettier */
import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

const SignupNavbar = () => {
  const history = useHistory();
  const path = history.location.pathname.split('/')[1];
  return (
    <div className="container flex flex-wrap justify-center sm:justify-between items-center">
      <div className="flex items-center justify-center float-left h-full py-0">
        <Link
          to="/"
          className="mr-4 font-semibold bg-transparent text-text text-h4 hover:text-opacity-80 active:opacity-80 disabled:bg-disabled"
        >
          <img className="w-24" src={require('../../assets/autohound-logo-orange.png').default} alt="logo" />
        </Link>
      </div>
      <div className="float-right py-0">
        {path && path == 'signup' ? (
          <>
            <p className="inline-block mr-6 font-normal text-text"> Already a member ?</p>
            <Link
              to="/signin"
              className="inline-block mr-4 font-semibold bg-transparent text-primary text-button hover:text-opacity-80 active:text-accent-dark disabled:bg-disabled"
            >
              Log In
            </Link>
          </>
        ) : (
          <>
            <p className="inline-block mr-6 font-normal text-text">Don&apos;t have an account ?</p>
            <Link
              to="/signup"
              className="inline-block mr-4 font-semibold bg-transparent text-primary text-button hover:text-opacity-80 active:text-accent-dark disabled:bg-disabled"
            >
              Sign Up
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default SignupNavbar;
