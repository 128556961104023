/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import InvoiceAPIs from '../../../api/invoice';

import AdminInvoiceFields from './invoiceFields';
import { useAlert } from 'react-alert';
import { handleErrorMessages, handleSuccessMessages } from '../../../helpers/flashMessages';
import { keyupValidation } from '../../../helpers/validationMessages';
import VehicleAPIs from '../../../api/vehicle';

const AddAdminInvoice = () => {
  const [errors, setErrors] = useState({
    carUser: '',
    car: '',
    invoiceType: '',
    date: '',
    invoiceNumber: '',
    mileage: '',
    oilChange: '',
    repairDescription: '',
    totalAmount: '',
  });
  const history = useHistory();
  const alert = useAlert();
  const [vehicles, setVehicles] = useState([]);
  const [adminInvoice, setAdminInvoice] = useState({
    carUser: '',
    car: '',
    invoiceType: '',
    date: '',
    invoiceNumber: '',
    mileage: '',
    repairDescription: '',
    discount: '',
    totalAmount: '',
  });

  useEffect(() => {
    (async () => {
      try {
        if (adminInvoice.carUser){
          const userVehicles = await VehicleAPIs.getCustomerVehicles(adminInvoice.carUser);
          const { vehicles } = userVehicles.data.data.userWithVehicles.customerId;
          const userHasVehicles = !!vehicles && vehicles.length > 0;
          if (!userHasVehicles) {
            setErrors({ message: 'User has no vehicles and therefore cannot add an invoice.' });
          } else {
            setVehicles([...vehicles]);
          }
        }       
      } catch (e) {
        setErrors(e);
      }
    })();
  }, [adminInvoice.carUser]);

  const updateState = (e, type) => {
  
    const key = e.target.name;
    let value = e.target.value;
    const keyName = ['oilChangeDiscount', 'discount', 'totalAmount'].includes(key);
    if (keyName) {
      value =
        value.indexOf('.') >= 0
          ? value.substr(0, value.indexOf('.')) + value.substr(value.indexOf('.'), 3)
          : value;
    }
    setAdminInvoice({ ...adminInvoice, [key]: value });
    let error = keyupValidation(e, type);
    if (error) {
      setErrors({ ...errors, [key]: error });
      return error;
    } else {
      setErrors({ ...errors, [key]: '' });
      return error;
    }
  };

  const handleErrors = () => {
    let err = { ...errors };
    if (!adminInvoice.carUser) err.carUser = '* Choose the car user';
    else if (adminInvoice.carUser) err.carUser = '';
    if (!adminInvoice.car) err.car = '* Choose the car';
    else if (adminInvoice.car) err.car = '';
    if (adminInvoice.invoiceType == '') err.invoiceType = '* Choose the type of invoice';
    if (adminInvoice.date == '') err.date = '* Select the date';
    if (adminInvoice.repairDescription == '') err.repairDescription = '* Enter repair description';
    if (adminInvoice.totalAmount == '') err.totalAmount = '* Enter total amount';
    if (adminInvoice.invoiceType == 'Autohound Repairs') {
      if (adminInvoice.invoiceNumber == '') err.invoiceNumber = '* Enter invoice number';
      if (adminInvoice.mileage == '') err.mileage = '* Enter mileage';
      if (adminInvoice.oilChange == '') err.oilChange = '* Enter oil change count';
    } else if (adminInvoice.invoiceType != 'Autohound Repairs') {
      err.invoiceNumber = '';
      err.mileage = '';
      err.oilChange = '';
    }
    setErrors(err);
  };

  const addInvoice = async (userId, chosenCarId, prevCarId, invoiceData,oilChange) => {
    try {
      
      let copyObj;
      if(oilChange)
      {
        let numberOilChange = Number(oilChange)
         copyObj = {...invoiceData ,oilChange:numberOilChange};
      }
      else{
        copyObj ={...invoiceData}
      }

      const response = await InvoiceAPIs.addInvoice(userId, chosenCarId, copyObj);
      handleSuccessMessages(response, alert);
      history.push(`/super-admin/invoices`);
    } catch (e) {
      const message = handleErrorMessages(e, alert);
      setErrors({ message });
    }
  };

  // const addInvoice = async () => {
  //   handleErrors();
  //   try {
  //     if (adminInvoice.invoiceType == 'Autohound Repairs') {
  //       if (
  //         (adminInvoice.car &&
  //           adminInvoice.carUser &&
  //           adminInvoice.invoiceType &&
  //           adminInvoice.mileage &&
  //           adminInvoice.invoiceNumber.trim() &&
  //           adminInvoice.oilChange &&
  //           adminInvoice.repairDescription &&
  //           adminInvoice.totalAmount &&
  //           adminInvoice.date) != '' &&
  //         errors.carUser == '' &&
  //         errors.car == '' &&
  //         errors.date == '' &&
  //         errors.mileage == '' &&
  //         errors.invoiceNumber == '' &&
  //         errors.oilChange == '' &&
  //         errors.repairDescription == '' &&
  //         errors.totalAmount == '' &&
  //         errors.invoiceType == ''
  //       ) {
  //         if (
  //           adminInvoice.invoiceType != 'Initial Vehicle Purchase' &&
  //           adminInvoice.totalAmount.toString().includes('-')
  //         ) {
  //           return handleErrorMessages({ customMessage: 'Total amount can not be less than 0' }, alert);
  //         }
  //         const invoicePropsData = {
  //           type: adminInvoice.invoiceType,
  //           date: adminInvoice.date,
  //           number: adminInvoice.invoiceNumber,
  //           mileage: adminInvoice.mileage,
  //           repairDescription: adminInvoice.repairDescription,
  //           discount: adminInvoice.discount,
  //           totalAmount: adminInvoice.totalAmount,
  //           oilChange: adminInvoice?.oilChange,
  //           oilChangeDiscount: adminInvoice?.oilChangeDiscount,
  //         };
  //         const response = await InvoiceAPIs.addInvoice(
  //           adminInvoice.carUser,
  //           adminInvoice.car,
  //           invoicePropsData
  //         );
  //         handleSuccessMessages(response, alert);
  //         history.push(`/super-admin/invoices`);
  //       }
  //     } else if (adminInvoice.invoiceType != 'Autohound Repairs') {
  //       if (
  //         (adminInvoice.invoiceType &&
  //           adminInvoice.repairDescription &&
  //           adminInvoice.totalAmount &&
  //           adminInvoice.invoiceType) != '' &&
  //         (errors.carUser,
  //         errors.car &&
  //           errors.date &&
  //           errors.repairDescription &&
  //           errors.totalAmount &&
  //           errors.invoiceType) == ''
  //       ) {
  //         if (
  //           adminInvoice.invoiceType != 'Initial Vehicle Purchase' &&
  //           adminInvoice.totalAmount.toString().includes('-')
  //         ) {
  //           return handleErrorMessages({ customMessage: 'Total amount can not be less than 0' }, alert);
  //         }
  //         const invoicePropsData = {
  //           type: adminInvoice.invoiceType,
  //           date: adminInvoice.date,
  //           number: adminInvoice.invoiceNumber,
  //           mileage: adminInvoice.mileage,
  //           repairDescription: adminInvoice.repairDescription,
  //           discount: adminInvoice.discount,
  //           totalAmount: adminInvoice.totalAmount,
  //         };
  //         const response = await InvoiceAPIs.addInvoice(
  //           adminInvoice.carUser,
  //           adminInvoice.car,
  //           invoicePropsData
  //         );
  //         handleSuccessMessages(response, alert);
  //         history.push(`/super-admin/invoices`);
  //       }
  //     }
  //   } catch (error) {
  //     const message = handleErrorMessages(error, alert);
  //     setErrors(message);
  //   }
  // };

  return (
    <>
    
    <AdminInvoiceFields
      operation="add"
      updateState={updateState}
      setAdminInvoice={setAdminInvoice}
      handler={addInvoice}
      data={adminInvoice}
      cars={vehicles}
      errors={errors}
      setErrors={setErrors}
    />
    </>
  );
};

export default AddAdminInvoice;