/* eslint-disable prettier/prettier */
import React, { useState, useCallback, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import nhtsa from 'nhtsa';

import TextInput from '../../components/TextInput';
import OnePage from '../../components/Pages/OnePage';
import Row from '../../components/Row';
import FormHeader from '../../components/FormHeader';
import TextArea from '../../components/TextArea';
import { BasicError } from '../../components/Error';
import {
  isCurrentUserACustomer,
  isCurrentUserAnAdmin,
  isCurrentUserABusinessUser,
  isCurrentUserAnOwner,
} from '../../utilities/authentication';
import { validationcommon, keyupValidation } from '../../helpers/validationMessages';

const VehicleFields = ({ title, operation = 'add', submitHandler, vehicle, vinNumber,parentError }) => {
  const history = useHistory()
  const [error, setError] = useState(null);
  const [vinEditError,setVinEditError] = useState('');
  const vehicleInitialState = {
    driverName: vehicle?.driverName ?? '',
    plateNumber: vehicle?.plateNumber ?? '',
    vin: vehicle?.vin ?? '',
    make: vehicle?.make ?? '',
    model: vehicle?.model ?? '',
    color: vehicle?.color ?? '',
    body: vehicle?.body ?? '',
    transmission: vehicle?.transmission ?? '',
    engine: vehicle?.engine ?? '',
    yearManufactured: vehicle?.yearManufactured ?? '',
    ac: vehicle?.ac ?? true,
    active: vehicle?.active ?? true,
    trim: vehicle?.trim ?? '',
    notes: vehicle?.notes ?? '',
    customerPrivateNotes: vehicle?.customerPrivateNotes ?? '',
  };
  const [vehicleData, setVehicleData] = useState(vehicleInitialState);
  const [errors, setErrors] = useState({
    plateNumber: '',
    vin: '',
    make: '',
    model: '',
    engine: '',
    yearManufactured: '',
  });

  const { customerId } = useParams();

  const handleErrors = () => {
    let err = { ...errors };
    if (vehicleData.plateNumber.trim() == '') err.plateNumber = '* Enter plate number';
    if (vehicleData.yearManufactured == '') err.yearManufactured = '* Enter year of manufacturing';
    if (vehicleData.make.trim() == '') err.make = '* Enter make of vehicle';
    if (vehicleData.model.trim() == '') err.model = '* Enter model of vehicle';
    if (vehicleData.engine == '') err.engine = '* Enter capacity of engine';
    if (vehicleData.vin.trim() == '') err.vin = '* Enter V.I.N.';
    setErrors(err);
  };

  const updateState = (e, type) => {
    const key = e.target.name;
    let value = e.target.value;
    if(key =='vin' && operation =='edit')
    {
      setVinEditError('old vin number should not be replaced with new vin number.')
    }
    if (key == 'plateNumber') {
      value = value.toUpperCase();
    } else if (key == 'vin') {
      value = value.toUpperCase();
    }
    
    setVehicleData({ ...vehicleData, [key]: value });
    let error = keyupValidation(e, type);
    if (error) {
      setErrors({ ...errors, [key]: error });
      return error;
    } else {
      setErrors({ ...errors, [key]: '' });
      return error;
    }
  };
  const handleVin=(e)=>{
    const {name,value}=e.target;

    if(vehicleData.vin == vinNumber)
    {
      setVinEditError('')
    }
  }

  const addVehicle = e => {
    e.preventDefault();
    handleErrors();
    if (
      (vehicleData.plateNumber.trim() &&
        vehicleData.vin.trim() &&
        vehicleData.make.trim() &&
        vehicleData.model.trim() &&
        vehicleData.engine &&
        vehicleData.yearManufactured) != '' &&
      errors.engine == '' &&
      errors.yearManufactured == '' &&
      errors.plateNumber == '' &&
      errors.engine == '' &&
      errors.vin == '' &&
      errors.model == ''
    )
      submitHandler(vehicleData);
  };

  const findVinAndUpdateVehicleDataState = useCallback(async (vin, vehicleInfo) => {
    try {
      const { data } = await nhtsa.decodeVinExtendedFlatFormat(vin);
 

      if (data) {
        const { Make, Model, BodyClass, TransmissionStyle, DisplacementL, ModelYear } = data.Results[0];
        setVehicleData({
          ...vehicleInfo,
          make: Make,
          model: Model,
          body: BodyClass,
          transmission: TransmissionStyle,
          engine: DisplacementL,
          yearManufactured: ModelYear,
        });
      }

      if (data.Results[0].ErrorText) {
        if (data.Results[0].ErrorText.includes('Manufacturer is not registered with NHTSA')) {
          setError(
            'The VIN belongs to a vehicle that is not registered with NHTSA. Unfortunately, details about the vehicle cannot be found'
          );
        }
      }
    } catch (error) {
      setError({ ...error });
    }
  }, []);

  useEffect(() => {
    const isAddOperation = operation === 'add';
    const isValidVin = vehicleData?.vin !== '';
    const hasNoResultsYet = vehicleData?.make?.length === 0;

    if (isAddOperation && isValidVin && hasNoResultsYet && !error) {
      findVinAndUpdateVehicleDataState(vehicleData.vin, vehicleData);
    }
  }, [vehicleData.vin, operation, vehicleData, findVinAndUpdateVehicleDataState, error]);


  const handleOnCancelClick = e => {
    e.preventDefault();
    if(isCurrentUserACustomer() || isCurrentUserAnOwner() ||isCurrentUserABusinessUser()) {
      history.push(`/customer/${customerId}`)
    }
  }
  return (
    <OnePage background="background">
      <div className="px-0 sm:px-5 md:px-20 pt-10 h-hero">
        <div className="flex flex-col h-auto px-2 w-100">
     
          <FormHeader title={title} backLink={`/customer/${customerId}`} />
          {/* {error?.length > 0 && <BasicError customError={error} />} */}
          {/* {parentError?.length > 0 && <BasicError customError={parentError} />} */}
          <form
            className="flex flex-col px-5 py-6 bg-white flex-wrap app_form"
            onSubmit={e => addVehicle(e)}
            noValidate
          >
            <Row className="grid gap-4 grid-cols-1 md:grid-cols-3 sm:grid-cols-2 lg:grid-cols-4">
              <div>
                <TextInput
                  label="Plate Number"
                  name="plateNumber"
                  value={vehicleData.plateNumber}
                  onChange={e => updateState(e, 'plateNumber')}
                  required={true}
                  placeholder="eg: CMMK345"
                  maxLength={15}
                />
                {errors && errors.plateNumber !== '' ? (
                  <p className="text-error font text-sm">{errors.plateNumber}</p>
                ) : null}
              </div>
              <div>
                <TextInput
                  label="Year Manufactured"
                  name="yearManufactured"
                  value={vehicleData.yearManufactured}
                  onChange={e => updateState(e, 'year')}
                  required={true}
                  placeholder="eg: 2021"
                  maxLength={4}
                />
                {errors && errors.yearManufactured !== '' ? (
                  <p className="text-error font text-sm">{errors.yearManufactured}</p>
                ) : null}
              </div>
              <div>
                <TextInput
                  label="Make"
                  name="make"
                  value={vehicleData.make}
                  onChange={e => updateState(e, 'make')}
                  required={true}
                  placeholder="eg: SUBARU"
                />
                {errors && errors.make !== '' ? (
                  <p className="text-error font text-sm">{errors.make}</p>
                ) : null}
              </div>
              <div>
                <TextInput
                  label="Model"
                  name="model"
                  value={vehicleData.model}
                  onChange={e => updateState(e, 'model')}
                  required={true}
                  placeholder="Forester"
                />
                {errors && errors.model !== '' ? (
                  <p className="text-error font text-sm">{errors.model}</p>
                ) : null}
              </div>
              <TextInput
                label="Trim"
                name="trim"
                value={vehicleData?.trim}
                onChange={e => updateState(e)}
                placeholder="eg: CE"
              />
              <div>
                <TextInput
                  label="Engine"
                  name="engine"
                  value={vehicleData.engine}
                  onChange={e => updateState(e, 'engine')}
                  required={true}
                  placeholder="eg: 2.0"
                />
                {errors && errors.engine !== '' ? (
                  <p className="text-error font text-sm">{errors.engine}</p>
                ) : null}
              </div>
              <TextInput
                label="Transmission"
                name="transmission"
                value={vehicleData.transmission}
                onChange={e => updateState(e)}
                required={false}
                placeholder="eg: Automatic"
              />
              <TextInput
                label="Body"
                name="body"
                value={vehicleData.body}
                onChange={e => updateState(e)}
                required={false}
                placeholder="eg: Wagon"
              />
              <div>
                {operation === 'edit' ?(
                  <TextInput
                   label="VIN"
                   name="vin"
                   value={vehicleData.vin}
                   onChange={e => updateState(e, 'VIN')}
                   required={true}
                   placeholder="eg: JH5676FG56GDE6789"
                   onBlur={handleVin}
                    //maxLength={17}
                    />
                ) :(
                  <TextInput
                  label="VIN"
                  name="vin"
                  value={vehicleData.vin}
                  onChange={e => updateState(e, 'VIN')}
                  required={true}
                  placeholder="eg: JH5676FG56GDE6789"
                  //maxLength={17}
                />
                )}
              
              
                {vinEditError !== '' ? <p ><b>{vinEditError}</b></p> : null}
                {errors && errors.vin !== '' ? <p className="text-error font text-sm">{errors.vin}</p> : null}
              </div>
              <TextInput
                label="Color"
                name="color"
                value={vehicleData.color}
                onChange={e => updateState(e)}
                required={false}
                placeholder="eg: Red"
              />
              <TextInput
                label="Driver Name"
                name="driverName"
                value={vehicleData.driverName}
                onChange={e => updateState(e)}
                required={false}
                placeholder="eg: John Doe"
              />
              <div className="flex flex-col w-full">
                <label className="text-small text-text test3">Status</label>
                <div className="flex flex-row mt-3 mb-4">
                  <div className="flex items-center mr-5 radio-item-active">
                  <label className="text-small text-text"> <input
                      defaultChecked={vehicleData.active === true}
                      onChange={e => updateState(e)}
                      value="true"
                      type="radio"
                      name="active"
                      className="mr-2 text-primary"
                      required={false}
                    />
                    Active</label>
                  </div>
                  <div className="flex items-center mr-5 radio-item-inactive">
                  <label className="text-small text-text radio-item-inactive"> <input
                      defaultChecked={vehicleData.active === false}
                      onChange={e => updateState(e)}
                      value="false"
                      type="radio"
                      name="active"
                      className="mr-2 text-primary"
                      required={false}
                    />
                   Inactive</label>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full">
                <label className="text-small text-text">A/C</label>
                <div className="flex flex-row mt-3 mb-4">
                  <div className="flex items-center mr-5">
                    <input
                      defaultChecked={vehicleData.ac === true}
                      onChange={e => updateState(e)}
                      value="true"
                      type="radio"
                      name="ac"
                      className="mr-2 text-primary"
                      required={false}
                    />
                    <label className="text-small text-text">Yes</label>
                  </div>
                  <div className="flex items-center mr-5">
                    <input
                      defaultChecked={vehicleData.ac === false}
                      onChange={e => updateState(e)}
                      value="false"
                      type="radio"
                      name="ac"
                      className="mr-2 text-primary"
                      required={false}
                    />
                    <label className="text-small text-text">No</label>
                  </div>
                </div>
              </div>
              {isCurrentUserAnOwner() && (
                <TextArea
                  label="Shop's Private Notes"
                  onChange={e => updateState(e)}
                  name="notes"
                  value={vehicleData?.notes}
                  required={false}
                  placeholder="Enter relevant notes"
                />
              )}
              {(isCurrentUserACustomer() || isCurrentUserABusinessUser()) && (
                <TextArea
                  label="Customer Private Notes"
                  onChange={e => updateState(e)}
                  name="customerPrivateNotes"
                  value={vehicleData?.customerPrivateNotes}
                  required={false}
                  placeholder="Enter relevant notes"
                />
              )}
            </Row>
            <div className="text-right">
            <button
                onClick={handleOnCancelClick}
                className="px-5 py-3 mt-5 mx-2 text-white rounded-sm w-36 text-button bg-primary hover:bg-opacity-80"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-5 py-3 mt-5 text-white rounded-sm w-36 text-button bg-primary hover:bg-opacity-80"
              >
                {operation === 'add' ? 'Add Vehicle' : 'Save Changes'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </OnePage>
  );
};

VehicleFields.propTypes = {
  title: PropTypes.string,
  operation: PropTypes.string,
  submitHandler: PropTypes.func,
  vehicle: PropTypes.object,
  vinNumber: PropTypes.string,
  parentError: PropTypes.string,
};

export default VehicleFields;