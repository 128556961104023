/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import VehicleAPIs from '../../api/vehicle';

import VehicleFields from './VehicleFields';
import { useAlert } from 'react-alert';
import { handleErrorMessages, handleSuccessMessages } from '../../helpers/flashMessages';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';

const EditVehicle = () => {
  const { confirm } = Modal;
  const [vehicle, setVehicle] = useState({});
  const [vinNumber,setVinNumber] = useState('');
  const [error, setError] = useState('');
  const [requiredfields, setRequiredfields] = useState({
    plateNumber: '',
    yearManufactured: '',
    vin: '',
    model: '',
    make: '',
    engine: '',
  });

  const { customerId, vehicleId } = useParams();
  const history = useHistory();
  const alert = useAlert();

  const LINK_TO_CUSTOMER_PROFILE = `/customer/${customerId}`;

  const onSubmit = vehicleUpdates => {
    const compareVehicleData = _.isEqual(requiredfields, {
      plateNumber: vehicleUpdates.plateNumber,
      yearManufactured: vehicleUpdates.yearManufactured,
      vin: vehicleUpdates.vin,
      model: vehicleUpdates.model,
      make: vehicleUpdates.make,
      engine: vehicleUpdates.engine,
      active: vehicleUpdates.active,
    });
    if (!compareVehicleData) {
      showDeleteConfirm(vehicleUpdates);
    } else {
      updateVehicle(vehicleUpdates);
    }
  };

  const updateVehicle = async vehicleUpdates => {
    try {
      const response = await VehicleAPIs.updateVehicle(
        {
          driverName: vehicleUpdates.driverName,
          vin: vehicleUpdates.vin,
          make: vehicleUpdates.make,
          model: vehicleUpdates.model,
          color: vehicleUpdates.color,
          body: vehicleUpdates.body,
          transmission: vehicleUpdates.transmission,
          engine: vehicleUpdates.engine,
          yearManufactured: vehicleUpdates.yearManufactured,
          ac: vehicleUpdates.ac,
          active: vehicleUpdates.active,
          plateNumber: vehicleUpdates.plateNumber,
          trim: vehicleUpdates.trim,
          notes: vehicleUpdates.notes,
          customerPrivateNotes: vehicleUpdates.customerPrivateNotes,
        },
        vehicleId
      );
      handleSuccessMessages(response, alert);
      history.push(LINK_TO_CUSTOMER_PROFILE);
    } catch (e) {
      const message = handleErrorMessages(e, alert);
      setError(message);
    }
  };

  function showDeleteConfirm(vehicleUpdates) {
    confirm({
      title: 'Are you sure ?',
      icon: <ExclamationCircleOutlined />,
      content: 'These changes will effect invoice and reports data.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        updateVehicle(vehicleUpdates);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  useEffect(() => {
    (async () => {
      try {
        const response = await VehicleAPIs.getVehicle(vehicleId);
        setVehicle(response?.data?.data?.vehicle);
        setVinNumber(response?.data?.data?.vehicle.vin);
        setRequiredfields({
          plateNumber: response.data.data.vehicle.plateNumber,
          yearManufactured: response.data.data.vehicle.yearManufactured,
          vin: response.data.data.vehicle.vin,
          model: response.data.data.vehicle.model,
          make: response.data.data.vehicle.make,
          engine: response.data.data.vehicle.engine,
        });
      } catch (e) {
        setError(e?.response?.data?.error);
      }
    })();
  }, [vehicleId]);

  return (
    <>
      {Object.entries(vehicle).length > 0 ? (
        <VehicleFields
          title="Edit Vehicle"
          operation="edit"
          submitHandler={onSubmit}
          parentError={error}
          vehicle={vehicle}
          vinNumber={vinNumber}
        />
      ) : (
        <div className="py-10">
          <p className="text-base text-center text-error">{error}</p>
        </div>
      )}
    </>
  );
};

export default EditVehicle;
