/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';

const alertStyle = {
  color: 'white',
  padding: '15px',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
  fontFamily: 'Arial',
  width: '300px',
  boxSizing: 'border-box',
  opacity: '0.8',
  fontSize: '15px',
};

const buttonStyle = {
  marginLeft: '20px',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  color: '#FFFFFF',
};

const AlertTemplate = ({ message, options, style, close }) => {
  let alertColors = {};
  let crossColor = {};
  if (options.type === 'success') {
    alertColors = {
      color: '#155724',
      backgroundColor: '#d4edda',
      borderColor: '#c3e6cb',
    };
    crossColor = { color: '#155724' };
  } else {
    alertColors = {
      color: '#721c24',
      backgroundColor: '#f8d7da',
      borderColor: '#f5c6cb',
    };
    crossColor = { color: '#155724' };
  }
  return (
    <div style={{ ...alertStyle, ...style, ...alertColors }}>
      <span style={{ flex: 2 }}>{message}</span>
      <button onClick={close} style={{ buttonStyle, ...crossColor }}>
        X
      </button>
    </div>
  );
};
AlertTemplate.propTypes = {
  message: PropTypes.string,
  options: PropTypes.object,
  style: PropTypes.object,
  close: PropTypes.func,
};

export default AlertTemplate;
