/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';

const RadioInput = ({ label, name, value, onClick, defaultChecked, ...rest }) => (
  <div className="mr-5 flex items-center">
    <input
      checked={defaultChecked}
      onClick={onClick}
      value={value}
      type="radio"
      name={name}
      className="mr-2 text-primary"
      {...rest}
    />
    <label className="text-small text-text radio-item-inactive">{label}</label>
  </div>
);

RadioInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func,
  defaultChecked: PropTypes.bool,
};

export default RadioInput;
