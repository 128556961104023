/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import UserAPIs from '../../api/user';
import OnePage from '../../components/Pages/OnePage';
import { PageError } from '../../components/Error';
import BasicInfo from './BasicInfo';
import Vehicles from './Vehicles';
import { Invoices } from './Invoice';
import useTitle from '../../hooks/useTitle';

const CustomerProfile = () => {
  const [error, setError] = useState({});
  const [userData, setUserData] = useState({});
  const [separatedInvoices, setSeparatedInvoices] = useState({});

  useTitle('Profile');

  const { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const response = await UserAPIs.getCustomer(id);
        const { user } = response.data;

        setUserData({ ...user });
      } catch (e) {
        setError({ ...e });
      }
    })();
  }, [id]);

  const filterVehiclesWithInvoices = useCallback(vehicles => {
    const vehiclesWithInvoices = vehicles.filter(vehicle => vehicle?.invoices?.length !== 0);

    vehiclesWithInvoices.forEach(vehicleWithInvoice => {
      vehicleWithInvoice?.invoices.forEach(invoice =>
        createVehiclePropertyWithinInvoice(vehicleWithInvoice, invoice)
      );
    });

    const totalInvoices = vehiclesWithInvoices.map(vehicle => vehicle?.invoices).flat();
    const invoicesGroupedByModel = _.groupBy(totalInvoices, invoice => invoice.vehicle);
    setSeparatedInvoices({ ...invoicesGroupedByModel });
  }, []);

  const createVehiclePropertyWithinInvoice = (
    { make, model, plateNumber, vin, yearManufactured, active },
    invoice
  ) => {
    invoice.vehicle = `${active}/${plateNumber}/${yearManufactured}/${make}/${model}/${vin}`;
  };

  useEffect(() => {
    const isValidUserData = Object.entries(userData)?.length > 0 && userData.constructor === Object;

    if (isValidUserData) {
      const hasVehicles = userData?.customerId?.vehicles?.length > 0;

      if (hasVehicles) {
        filterVehiclesWithInvoices(userData.customerId.vehicles);
      }
    }
  }, [filterVehiclesWithInvoices, userData]);

  return (
    <>
      <OnePage>
        {Object.entries(error).length > 0 && (
          <PageError message={'An error occurred while processing your request.'} />
        )}
        {userData.customerId && (
          <div className="container container-inner px-20">
            <div className="grid grid-cols-4 min-h-screen bg-background">
              <BasicInfo user={userData} />
              <div className="col-span-4 sm:col-span-4 md:col-span-4 lg:col-span-3 pl-0 lg:pl-5">
                <Vehicles vehicles={userData.customerId.vehicles} id={id} />
                <Invoices invoices={separatedInvoices} vehicles={userData?.customerId?.vehicles}/>
              </div>
            </div>
          </div>
        )}
      </OnePage>
    </>
  );
};

export default CustomerProfile;
