/* eslint-disable prettier/prettier */
import React, { useContext } from 'react';

import { SignupContext } from './Signup';
import Box from '../../components/Box/Box';
import { FiBriefcase, FiUserPlus, FiTool } from 'react-icons/fi';
import AppContext from '../../AppContext';

const EnterCode = () => {
  const { step, data, goToNextStep, updateUserData } = useContext(SignupContext);
  const { setPlan } = useContext(AppContext);

  const handleSelect = role => {
    updateUserData('role', role);
    if (role == 'customer') {
      setPlan('Basic License');
    }
    goToNextStep();
  };
  return (
    <Box title="Select Role" subtitle="Select the role you would like to sign up as" progress={step}>
      <div className="mt-10">
        <div className="max-w-sm rounded overflow-hidden shadow-lg bg-primary cursor-pointer ">
          <div className="px-6 py-4 text-center hover:bg-primary-light">
            <div className="font-bold text-xl text-white flex" onClick={() => handleSelect('customer')}>
              <FiUserPlus className="m-2" /> <span className="mt-1">Customer</span>
            </div>
          </div>
        </div>
        <div className="max-w-sm rounded overflow-hidden shadow-lg mt-6 bg-secondary_disabled cursor-not-allowed">
          <div className="px-6 py-4 text-center">
            <div className="font-bold text-xl text-white flex">
              <FiBriefcase className="m-2" /> <span className="mt-1">Business user</span>
            </div>
            {<div className="font-bold text-white">coming soon...</div>}
          </div>
        </div>
        <div className="max-w-sm rounded overflow-hidden shadow-lg mt-6 bg-secondary_disabled cursor-not-allowed">
          <div className="px-6 py-4 text-center">
            <div className="font-bold text-xl text-white flex disable">
              <FiTool className="m-2" /> <span className="mt-1">Shop Owner</span>
            </div>
            {<div className="font-bold text-white">coming soon...</div>}
          </div>
        </div>
      </div>
    </Box>
  );
};

export default EnterCode;
