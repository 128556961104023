/* eslint-disable prettier/prettier */
import React from 'react';

const TermsUsage = () => {
  const title = ` Autohound Website Terms of Use`;
  const website = 'Website';
  const netarf = 'NETARF';
  const intro = `These Terms of Use apply to the Autohound website and services available from it (collectively, the “${website}”) provided by Nurse's Emission Testing & Repair Facility Inc. o/o
  by Nurse's Emission Testing & Repair Facility Inc. (“${netarf}”). By accessing and using this Website you agree to these Terms of Use. If you do not agree with any part of these Terms of Use, you should immediately stop using this website and any services it provides.`;

  const prohibitedUses = [
    'Use the Website for any purpose or use other than in association with your dealings with NETARF.',
    'Decompile, modify or reverse engineer any aspect of the Website.',
    'Provide your user Id or password to any other person, if the Website has provided these to you.',
    'Distribute, rent, outsource or sublicense any aspect of the Website to any person.',
    'Provide any false identity in relation to yourself or others to the Website;',
    `Intentionally interfere with any other person's use of the Website.`,
    'Use the Website to publish or distribute any false or defamatory statements or information.',
  ];

  const privacy = [
    'You hereby give your consent to NETARF to collect your personal information, such as name, address, phone numbers, in association with information related to automotive service and repair and repair history, or other information you provide to the Website. Information which is anonymized shall not be considered to be personal information.',
    'You acknowledge that NETARF may use this personal information to provide services to me, to generate analytics about the NETARF business and automotive repairs, to improve its operations, to create new products and services, and to offer me additional products and services.',
    'You acknowledge that the Website may use cookies, beacons, or other technology to understand your use of the Website and store information about you.',
    'NETARF shall comply with all applicable privacy legislation.',
    'You may contact NETARF if you wish to review any of your personal information we retain, and we shall correct any such information upon request by you.',
     ];

  const confidentiality = [
    'All intellectual property rights in relation to the Website are retained by NETARF. Except as expressly set out in these Terms of Use, you are granted no license or rights to such NETARF intellectual property.',
  ];

  const disclaimer = [
    'NETARF disclaims any implied warranties, such as warranties of fitness for purpose or merchantability in relation to the Website, or that the Website will be error-free or provide uninterrupted operation.',
    'NETARF reserves the right to change the information and recommendations on the Website, without any notice to you. Vehicle repairs and maintenance should only be carried out by qualified, licensed service technicians, with reference to the OEM’s specifications, recommendations and service bulletins. Information on this site that relates to how to carry out various repairs should only be used by qualified, licensed service technicians.',
    'You acknowledge that you are responsible for correct input of data to the Website. You acknowledge that NETARF does not check on the accuracy of any data that are provided by you. NETARF disclaims all responsibility for the operation of your networks, hardware, operating systems, etc.',
    'NETARF disclaims any warranty that the Website will provide any particular outcome, including repair costs or vehicle performance.',
    'NETARF’S MAXIMUM LIABILITY TO YOU, IN RELATION TO THESE TERMS OF USE, OR YOUR USE OF THE WEBSITE SHALL BE TEN DOLLARS ($10.00) REGARDLESS OF THE CAUSE OF ACTION. YOU SHALL NOT BE ENTITLED TO RECEIVE ANY INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES IN RELATION TO THESE TERMS OF USE, THE WEBSITE.',
  ];

  const terms = [
    'NETARF may terminate your access to the Website for any reason, and may terminate your access to the Website immediately if you breach any part of these Terms of Use.',
  ];

  const misc = [
    {
      bold: 'Entire Agreement.',
      rest:
        'These Terms of Use are the entire agreement between the parties in relation to its subject matter and supersedes all prior agreements, negotiations, discussions, communications or representations between the parties.',
    },
    {
      bold: 'Relationship of Parties.',
      rest:
        'It is understood that in agreeing to perform its obligations under this Agreement, NETARF shall be acting and shall act at all times as an independent contractor, and not as your employee for any purpose whatsoever, including without limitation, for purposes relating to taxes, workplace safety insurance, payments required by statute or any other withholdings or remittances to any governmental agency or authority.  Neither these Terms of Use, the relationship created between the Parties pursuant to these Terms of Use, nor any course of dealing between the Parties is intended to create, or shall create, an employment relationship, a joint venture, partnership or any similar relationship.  NETARF does not have, nor shall NETARF hold itself out as having, any right, power or authority to create any contract or obligation, either express or implied, with or on you.',
    },
    {
      bold: 'Governing Law.',
      rest:
        'These Terms of Use shall be governed, construed and interpreted in accordance with the laws of the Province of Ontario. For any dispute arising in connection with these Terms of Use, the Parties hereby irrevocably attorn to the jurisdiction of the courts of the Province of Ontario and to any other court having jurisdiction over a Party solely to enforce a judgement of a court of the Province of Ontario.',
    },
    {
      bold: 'Assignment.',
      rest:
        'You shall not assign any rights or obligations under these Terms of Use without the prior written consent of NETARF.',
    },
    {
      bold: 'Severability.',
      rest:
        'If any provision of these Terms of Use is declared void or unenforceable, such provision shall be deemed severed from these Terms of Use, and the remaining terms and conditions shall remain enforceable.',
    },
    {
      bold: 'Force Majeure.',
      rest:
        'If the performance of any part of these Terms of Use by any Party will be affected for any length of time by fire or other casualty, government restrictions, war, terrorism, riots, strikes or labor disputes, lock out, transportation delays, electronic disruptions, internet, telecommunication or electrical system failures or interruptions, and acts of nature, or any other cause which is beyond control of a Party (financial inability excepted), such Party will not be responsible for delay or failure of performance of these Terms of Use for such length of time, provided, however, (a) the affected Party will cooperate with and comply with all reasonable requests of the non-affected Party to facilitate performance of these Terms of Use to the extent possible, and (b) the obligation of one Party to pay amounts due to any other Party will not be subject to the provisions of this Section.',
    },
    {
      bold: 'Language of Terms of Use.',
      rest:
        'The Parties have expressly required that this agreement and all deeds, documents and notices relating to this agreement be drafted in the English language.',
    },
  ];
  return (
    <>
      <h1 className="font-bold text-2xl text-center mb-5">{title}</h1>
      <p className="my-5 text-justify">{intro}</p>
      <ol className="list-decimal list-inside">
        <li>
          <b>Prohibited Uses.</b> You shall not:
        </li>
        <ol className="ml-5 my-5 list-alpha list-inside">
          {prohibitedUses.map((p, i) => (
            <li key={i} className="my-2">
              {p}
            </li>
          ))}
        </ol>
        <li>
          <b>Privacy.</b>
        </li>
        <ol className="ml-5 my-5 list-alpha list-inside">
          {privacy.map((p, i) => (
            <li key={i} className="my-2">
              {p}
            </li>
          ))}
        </ol>
        <li>
          <b>Confidentiality and Intellectual Property.</b>
        </li>
        <ol className="ml-5 my-5 list-alpha list-inside">
          {confidentiality.map((p, i) => (
            <li key={i} className="my-2">
              {p}
            </li>
          ))}
        </ol>
        <li>
          <b>Disclaimer of Warranties and Limitation of Liability.</b>
        </li>
        <ol className="ml-5 my-5 list-alpha list-inside">
          {disclaimer.map((p, i) => (
            <li key={i} className="my-2">
              {p}
            </li>
          ))}
        </ol>
        <li>
          <b>Term and Termination.</b>
        </li>
        <ol className="ml-5 my-5 list-alpha list-inside">
          {terms.map((p, i) => (
            <li key={i} className="my-2">
              {p}
            </li>
          ))}
        </ol>
        <li>
          <b>Miscellaneous.</b>
        </li>
        <ol className="ml-5 my-5 list-alpha list-inside">
          {misc.map(({ bold, rest }, i) => (
            <li key={i} className="my-2">
              <b>{bold}</b> {rest}
            </li>
          ))}
        </ol>
      </ol>
    </>
  );
};

export default TermsUsage;
