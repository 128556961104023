/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Table, Button, Checkbox, Collapse, Select, Input, Tooltip, Modal } from 'antd';
import AdminAPIs from '../../../../api/admin';
import { FilterOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { AiFillCalendar } from 'react-icons/ai';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { readFile, utils } from 'xlsx';
import { FiPlus } from 'react-icons/fi';
import { useAlert
 } from 'react-alert';
// import ExportReactCSV from '../../../../helpers/export';
import { CSVLink } from 'react-csv';
import {
  DownloadOutlined,
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
  SearchOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { handleSuccessMessages } from '../../../../helpers/flashMessages';

const Customer = () => {
  const [typingTimeout, setTypingTimeout] = useState(0);
  const { Search } = Input;
  const [collapse, setCollapse] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [garages, setGarages] = useState([]);
  const [garage, setGarage] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [table, setTable] = useState([]);
  const alert = useAlert();
  const [exportData, setExportData] = useState([]);
  const { Option, OptGroup } = Select;
  const suffix = (
    <SearchOutlined
      style={{
        fontSize: 16,
        color: '#F0734F',
      }}
    />
  );
  const editOnClick = (e, user) => {};
  const [options, setOptions] = useState({
    search: '',
    status: '',
    garage: '605b5b226ec4715ce5e0e00d',
    pagination: { current: 1, pageSize: 10, pageSizeOptions: [10, 20, 50] },
    sortFilters: {},
    start: '',
    end: '',
  });
  const { confirm } = Modal;
  const { Panel } = Collapse;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const datePicker = () => {
  const onChangedate = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setOptions({
      ...options,
      start: moment(start).format('YYYY-MM-DD'),
      end: moment(end).add(1, 'days').format('YYYY-MM-DD'),
    });
  };

  const showDeleteConfirm = (e, userId) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'The deleted record will not be recovered.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
        const response = AdminAPIs.deleteUser(userId);
        handleSuccessMessages({customMessage: `User deleted successfully.`}, alert);
        setTimeout(()=>{
          getCustomerList(options);
        },1000)
        
        setSelectedRows([]);
      },

      onCancel() {
        setSelectedRows([]);
      },
    });
  };

  const showDelete = (e, selectedRows) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'The deleted record will not be recovered.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
        
        const response = AdminAPIs.deleteMultipleUsers([selectedRows]);
        handleSuccessMessages({customMessage: `User deleted successfully.`}, alert);
        setTimeout(()=>{
          getCustomerList(options);
        },1000)
        setSelectedRows([]);
      },

      onCancel() {
        getCustomerList(options);
        setSelectedRows([]);
      },
    });
  };

  const onSelectAll = e => {
    if (e.target.checked) {
      const test = [...new Set([...selectedRows, customers.map(n => n.userId)].flat())];
      setSelectedRows(test);
    } else {
      const selectedIds = selectedRows.filter(n => !customers.map(x => x.userId).includes(n));
      setSelectedRows(selectedIds);
    }
  };

  const onSelectRow = async (e, userId) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, userId]);
    } else {
      const selectedIds = selectedRows.filter(n => n !== userId);
      setSelectedRows(selectedIds);
    }
  };

  const [dataState, setDataState] = useState([]);
  const handleFile = async e => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = readFile(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsondata = utils.sheet_to_json(worksheet);
    setDataState(
      jsondata.map((e, i) => ({
        role: e.role,
        firstName: e.firstName,
        lastName: e.lastName,
        email: e.email,
        mobile: e.mobile,
      }))
    );
    setTable(
      jsondata.map((e, i) => ({
        role: e.role,
        firstName: e.firstName,
        lastName: e.lastName,
        email: e.email,
        mobile: e.mobile,
        createdAt: e.createdAt,
      }))
    );
  };
  const collapseShowHideFunc = key => {
    setCollapse(key);
  };
  useEffect(() => {
    (async () => {
      try {
        const response = await AdminAPIs.getGarages();
        const { garages } = response.data;
        setGarages(garages);
        if (garages.length) {
          setGarage(garages[0]);
          setOptions({...options, garage: garages[0]._id});
          getCustomerList({...options, garage: garages[0]._id});
        }
      } catch (e) {
        console.error(e);
        // const message = handleErrorMessages(e);
        // setError(message);
      }
    })();
  }, []);
  const getAllCustomerList = async options => {
    try {
      setLoading(true);
      const response = await AdminAPIs.getAllCustomerExport(options);
      // setExportData(response.data.customers?.customers?.docs || []);
      let finaldata = response.data.customers?.customers?.docs || [] 
      finaldata.map((data)=>{
      ['_id','password','profilePic','customerId','isDeleted','__v','customers'].map(item=>delete data[item])
      data.createdAt=moment(data.createdAt).format('DD-MM-yyyy')
     })
      setExportData(finaldata);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
    setLoading(false);
  };

  const getAllCustomerListWithSalesData = async (options) => {
    try {
      setLoading(true);
      options.role = 'customer';   
      options.sortOrder = '';
      const response = await AdminAPIs.getAllCustomerExportWithSalesData(options);
      if (response.data.users.length > 0) {
        let finaldata = response.data.users || [];
        let updateData = [];
        finaldata.forEach((user) => {
          const { firstName, lastName, postalCode, city, email, salesData } = user;
          const { breakdown, lifetimeDiscount, lifetimeAmount, lifetimeOilChange } = salesData;
          updateData.push({
            "First Name": firstName,
            "Last Name": lastName,
            "Postal Code": postalCode,
            "City": city,
            // "Email": email,
            "Total Amount": `$${lifetimeAmount}`,
            // "Total Discount": `$${lifetimeDiscount}`,
            // "Total Service Discount": lifetimeOilChange,
          });
        
          const rows = breakdown.map(row => {
            const { year, discount, discountLevel, oilChangeDiscount, total } = row
            updateData.push({
              // "Discount": discount,
              // "Service Discount": oilChangeDiscount,
              "Year": year,
              "Discount Level": discountLevel,
              "Total": `$${total}`,
            });
          })
        })
        setExportData(updateData);
        document.getElementById('csvLinkSalesData').click();
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
    setLoading(false);
  };

  const handleExport = async options => {
    try {
      setLoading(true);
      options.role='customer';
      options.sortOrder='lastName';
      options.status='true'
      const response = await AdminAPIs.getAllUsers(options);
      if(response.data.users.length >0)
       {
        let finaldata = response.data.users || [];        
       let updateData = [];
       finaldata.forEach((el) => {
         const { user,province,postalCode,city,email,street} = el;
         updateData.push({
          FirstName: user.firstName,
          LastName: user.lastName,
          Address: street,
          CityProvince: `${city ? city :''} ${province ? `/${province}` :''}`,
          PostalCode: postalCode,
        });
       });
       setExportData(updateData);
       document.getElementById('customerLink').click();
       }
    } catch (e) {
      setLoading(false);
      console.error('e');
    }
    setLoading(false);
  };

  const handleExportAll = async optoins =>{
    try {
      setLoading(true);
      options.role='customer';
      options.sortOrder='lastName';
      options.status=''
      const response = await AdminAPIs.getAllUsers(options);
      if(response.data.users.length >0)
       {
        let finaldata = response.data.users || [];        
       let updateData = [];
       finaldata.forEach((el) => {
         const { user,province,postalCode,city,email,street} = el;
         updateData.push({
          FirstName: user.firstName,
          LastName: user.lastName,
          Address: street,
          CityProvince: `${city ? city :''} ${province ? `/${province}` :''}`,
          PostalCode: postalCode,
        });
       });
       setExportData(updateData);
       document.getElementById('customerLink').click();
       }
    } catch (e) {
      setLoading(false);
      console.error('e');
    }
    setLoading(false);
  }
  const getCustomerList = async options => {
    try {
      setLoading(true);
      const response = await AdminAPIs.getAllCustomer(options);
      const { customers } = response.data;
      setCustomers(customers.customers.docs);
      setStats(customers.customers.stats)
      setOptions({
        ...options,
        pagination: {
          current: response.data.customers.customers.page,
          pageSize: response.data.customers.customers.limit,
          total: response.data.customers.customers.totalDocs,
          showSizeChanger: true,
        },
      });
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
    setLoading(false);
  };
  const handleFilterClick = () => {
    setCollapse([]);
    setOptions({ ...options, garage: garage });
    getCustomerList(options);
    // getAllCustomerList(options);
  };
  const onSelect = e => {
    const newOptions = {
      search: options.search,
      status: options.status,
      garage: e,
      pagination: options.pagination,
      sortFilters: options.sortFilters,
      start: options.start,
      end: options.end,
    };
    setOptions({ ...options, garage: e });
    getCustomerList(newOptions);
  };
  const handleClearAllClick = () => {
    setCollapse([]);
    const updatedOptions = {
      ...options,
      status: '',
      pagination: { ...options.pagination, current: 1 },
      start: '',
      end: '',
    };
    setStartDate(null);
    setEndDate(null);
    setOptions(updatedOptions);
    getCustomerList(updatedOptions);
  };

  useEffect(() => {
    getCustomerList(options);
    // getAllCustomerList(options);
  }, []);
  function onChange(pagination, filters, sorter, extra, garage) {
    const updatedOptions = { ...options, pagination };
    setOptions(updatedOptions);

    getCustomerList({
      ...updatedOptions,
      sortFilters: {
        sortField: sorter?.columnKey,
        sortOrder: sorter?.order,
      },
    });
  }
  const filterSortedTable = e => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    const query = e.target.value;
    const updatedOptions = {
      ...options,
      search: query.trim(),
      pagination: { ...options.pagination, current: 1 },
    };
    setOptions(updatedOptions);
    setTypingTimeout(() =>
      setTimeout(() => {
        getCustomerList(updatedOptions);
      }, 1000)
    );
  };
  const onSearch = value => console.log(value);
  function handleChange(value) { }

  const columns = [
    {
      title: (
        <Checkbox
          onChange={e => onSelectAll(e)}
          checked={
            selectedRows.length > 0 &&
            _.isEqual(
              _.sortBy(table.map(x => x.user.userId)),
              _.sortBy(
                _.intersectionWith(
                  selectedRows,
                  table.map(x => x.user.userId),
                  _.isEqual
                )
              )
            )
          }
        />
      ),
      dataIndex: 'userId',
      key: 'select',
      // eslint-disable-next-line react/display-name
      render: userId => (
        <Checkbox onChange={e => onSelectRow(e, userId)} checked={selectedRows.includes(userId)} />
      ),
    },

    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: {
        compare: (a, b) => a.firstName - b.firstName,
        multiple: 3,
      },
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: {
        compare: (a, b) => a.lastName - b.lastName,
        multiple: 3,
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: {
        compare: (a, b) => a.email - b.email,
        multiple: 2,
      },
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      // defaultSortOrder: 'descend',
      // sortDirections: ['ascend'],
      render: createdAt => (createdAt ? moment(createdAt).format('MMMM Do YYYY') : 'NA'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: status => `${status ? 'Active' : 'Inactive'}`,
    },
    {
      title: 'Actions',
      dataIndex: 'userId',
      // eslint-disable-next-line react/display-name
      render: userId => (
        <>
          <Link to={`/admin/customer/edit/${userId}`} onClick={e => editOnClick(e, userId)}>
            <Tooltip placement="top" title="edit">
              <EditOutlined />
            </Tooltip>
          </Link>
          <Tooltip title="delete">
            <DeleteOutlined className="ml-2" onClick={e => showDeleteConfirm(e, userId)} />
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="filter--outer mb-4">
        <div className="d-flex top--heading1">
          <h2 className="text-2xl font-semibold text-gray-700 dark:text-gray-200">Customer</h2>
          <div className="right--btn">
            <button className="bg-primary hover:bg-opacity-80 py-2 px-4 text-white rounded-sm ">
              <Link to="/admin/customer/add">
                <FiPlus className="inline-block align-middle mr-2 text-base" />
                <p className="inline-block align-middle">Add Customer</p>
              </Link>
            </button>
          </div>
        </div>
        <div className="d-flex filter--wrap my-5  align-center">
          <div className="d-flex mb-0 mb-sm-0 flex-wrap gap-2">
            <div className="del--icon" style={selectedRows.length === 0 ? { cursor: 'default' } : {}}>
              <DeleteOutlined
                onClick={e => (selectedRows.length === 0 ? {} : showDelete(e, selectedRows))}
                style={selectedRows.length === 0 ? { cursor: 'default' } : {}}
              />
            </div>
            <Select
              style={{ width: 200 }}
              showSearch
              placeholder="select garage"
              optionFilterProp="children"
              onSelect={e => {
                onSelect(e);
              }}
              onChange={e => {
                setGarage(garages.find(selectedGarage => selectedGarage._id === e));
                setOptions({ ...options, garage: e });
              }}
              onSearch={onSearch}
              value={garage ? garage._id : ''}
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            >
              {garages?.length > 0
                ? garages.map((garage, i) => (
                  <option key={i.toString()} value={garage._id}>
                    {_.startCase(garage.name)}
                  </option>
                ))
                : ''}
            </Select>

            <div className="filter_content w-auto d-flex">
              <Collapse activeKey={collapse} onChange={collapseShowHideFunc}>
                <Panel
                  showArrow={false}
                  header={
                    <React.Fragment>
                      <FilterOutlined className="mr-2" style={{ marginBottom: '1px' }} />
                      <span className="anticon anticon-filter">Filters</span>
                    </React.Fragment>
                  }
                  key="1"
                  className="site-collapse-custom-panel"
                >
                  <div className="card card-body">
                    <div className="row">
                      <div className="col-12 filter_header d-flex flex-wrap position-relative">
                        <h4 className="title">Filter By</h4>
                        <button className="clearfilter_btn btn-default" onClick={handleClearAllClick}>
                          Clear all
                        </button>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4 col-xl-2 fltr_cell">
                        <label className="label">Status</label>
                        <Select
                          className="text-1 font-normal rounded-r-none active-inactive-filter"
                          name="status"
                          defaultValue="all"
                          value={options.status}
                          onChange={e => setOptions({ ...options, status: e })}
                        >
                          <Option value="">All</Option>
                          <Option value={true}>Active</Option>
                          <Option value={false}>Inactive</Option>
                        </Select>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4 col-xl-3 fltr_cell">
                        <label className="label">Date</label>
                        <label>
                          <DatePicker
                            className="date-picker"
                            selected={startDate}
                            onChange={onChangedate}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange={true}
                            withPortal
                            placeholderText="MM/DD/YYYY"
                            // inline
                            isClearable={false}
                            maxDate={new Date()}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                          />
                        </label>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4 col-xl-2 fltr_cell d-flex justify-content-end">
                        <label className="label"></label>
                        <button
                          type="button"
                          className="bg-primary hover:bg-opacity-80 py-2 px-4 text-white rounded-sm"
                          onClick={handleFilterClick}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </Panel>
              </Collapse>
            </div>
          </div>
          <div className="d-flex flex-wrap gap-2">
            <label className="custom-field">
              <input type="file" name="file" onChange={e => handleFile(e)} />
              <span className="placeholder ant-btn ant-btn-primary">
                <DownloadOutlined /> Import
              </span>
            </label>
            <Button className="mr-2 placeholder ant-btn ant-btn-primary" type="primary" icon={<UploadOutlined className='mr-1' />} onClick={() => handleExport(options)}>
              Export
            </Button>
            <CSVLink
              id="customerLink"
              data={exportData}
              filename={'Customer.csv'}
              style={{ display: 'none' }}
            >
              Export
            </CSVLink>
            <Button className="mr-2 placeholder ant-btn ant-btn-primary" type="primary" icon={<UploadOutlined className='mr-1' />} onClick={() => handleExportAll(options)}>
              Export All
            </Button>
            <CSVLink
              id="csvLinkAll"
              data={exportData}
              filename={'Customer.csv'}
              style={{ display: 'none' }}
            >
              Export All
            </CSVLink>
            <Button className="mr-2 placeholder ant-btn ant-btn-primary" type="primary" icon={<UploadOutlined className='mr-1' />} onClick={() => getAllCustomerListWithSalesData(options)}>
              Export With Sales Data
            </Button>
            <CSVLink
              id="csvLinkSalesData"
              data={exportData}
              filename={'customer_data.csv'}
              style={{ textDecoration: 'none', allColumns: true, align: 'left' }}
            >
            </CSVLink>
            <Search
              className="input-search-box search--wrap mt-0 mt-sm-0"
              placeholder="input search text"
              // enterButton="Search"
              size="large"
              onChange={e => filterSortedTable(e)}
              // addonBefore={suffix}
              onSearch={onSearch}
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center px-3 pb-3">
        <p className="mr-3 font-weight-bold">Total Customers: <span className="text-primary">{stats.totalCustomers}</span></p>
        <p className="mr-3 font-weight-bold">Active Customers: <span style={{ color: '#008000' }}>{stats.totalActive}</span></p>
        <p className="font-weight-bold">Inactive Customers: <span className="text-danger">{stats.totalInactive}</span></p>
      </div>


      <main className="overflow-y-auto main--table">
        <div className="grid mx-auto">
          <div className="w-full mb-8 overflow-hidden shadow-xs">
            <div className="w-full overflow-x-auto">
              <Table
                columns={columns}
                dataSource={dataState.length > 0 ? table : customers}
                sortDirections={['ascend', 'descend']}
                onChange={onChange}
                pagination={options.pagination.total > 10 ? options.pagination : false}
                loading={loading}
                scroll={{ scrollToFirstRowOnChange: true }}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default Customer;
