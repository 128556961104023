/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';

import OnePage from '../../components/Pages/OnePage';
import Box from '../../components/Box/Box';
import { Checkbox } from 'antd';

import UserAPIs from '../../api/user';
import { setToken, isTokenValid } from '../../utilities/token';

import useTitle from '../../hooks/useTitle';
import { TailSpin } from 'react-loading-icons';
import { AiFillEye } from 'react-icons/ai';
import { useAlert } from 'react-alert';
import { keyupValidation } from '../../helpers/validationMessages';
import { handleErrorMessages, handleSuccessMessages } from '../../helpers/flashMessages';
import AdminAPIs from '../../api/admin';

const Signin = () => {
  useTitle('Signin');
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [error, setError] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies(['checked', 'email', 'password']);
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  const history = useHistory();
  const alert = useAlert();

  const resetState = () => {
    setCredentials({ email: '', password: '' });
    setRememberMe({ checkbox: false });
  };
  const handleRememberMeChange = e => {
    setRememberMe(!rememberMe);
  };

  const handleErrors = () => {
    let err = { ...errors };
    if (credentials.email == '') err.email = '* Enter email';
    if (credentials.password == '') err.password = '* Enter password';
    setErrors(err);
  };

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const updateState = (e, type) => {
    const key = e.target.name;
    const value = e.target.value;
    setCredentials({ ...credentials, [key]: value });
    let error = keyupValidation(e, type);
    if (error) {
      setErrors({ ...errors, [key]: error });
      return error;
    } else {
      setErrors({ ...errors, [key]: '' });
      return error;
    }
  };
  useEffect(() => {
    try {
      if (cookies.email != '' && cookies.password != '') {
        var email_decrypted = atob(cookies.email);
        var password_decrypted = atob(cookies.password);
        setCredentials({ ...credentials, email: email_decrypted, password: password_decrypted });
        setRememberMe(cookies.rememberMe);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const login = async e => {
    e.preventDefault();
    handleErrors();
    if ((credentials.email && credentials.password) != '') {
      try {
        setLoading(true);
        const response = await UserAPIs.loginUser(credentials);
        const { user } = response.data.data;
        const { _id } = user;
        localStorage.setItem('LoginId', _id);
        setToken(user.token);
        const hasValidToken = isTokenValid(user.token);
        if (rememberMe) {
          let email_encrypted = btoa(credentials.email);
          let password_encrypted = btoa(credentials.password);
          setCookie('rememberMe', rememberMe, { secure: true, maxAge: 604800 });
          setCookie('email', email_encrypted, { secure: true, maxAge: 604800 });
          setCookie('password', password_encrypted, { secure: true, maxAge: 604800 });
        } else if (!rememberMe) {
          try {
            removeCookie('rememberMe');
            removeCookie('email');
            removeCookie('password');
          } catch (error) {
            console.log(error);
          }
        }
        if (!hasValidToken) {
          setError({ message: 'no valid token. ' });
        }
        const isCustomer = user.role === 'customer';
        const isBusinussUser = user.role === 'business_user';
        const isGarageOwner = user.role === 'owner';
        const isFacilityStaff = user.role === 'admin';
        resetState();
        handleSuccessMessages(response, alert);
        if (isCustomer || isBusinussUser) history.push(`/customer/${user._id}`);
        if (isGarageOwner) {
          history.push(`/dashboard`);
          const users = await AdminAPIs.getAllUsersName();
          localStorage.setItem('usersName', [JSON.stringify(users.data.usersName)]);
        }
        if (isFacilityStaff) {
          history.push(`/admin`);
          const users = await AdminAPIs.getAllUsersName();
          localStorage.setItem('usersName', [JSON.stringify(users.data.usersName)]);
        }
      } catch (err) {
        console.log({ err });
        const message = handleErrorMessages(err, alert);
        setError(message);
      }
    }
    setLoading(false);
  };
  return (
    <OnePage className="plans">
      <div className="container px-8 h-hero mt-10">
        <Box title="Log In">
          <div className="mt-10">
            <form className="flex flex-col" onSubmit={e => login(e)} noValidate>
              <label className="text-small text-text">Email</label>
              <div>
                <input
                  name="email"
                  onChange={e => updateState(e, 'email')}
                  value={credentials.email}
                  type="email"
                  className="px-4 py-3 mt-1 bg-background text-body-2 text-subtext"
                  required
                  placeholder="johndoe@mail.com"
                />
                {errors && errors.email !== '' ? (
                  <p className="text-error font text-sm">{errors.email}</p>
                ) : null}
              </div>
              <label className="mt-2 text-small text-text">Password</label>
              <div className="input-group post">
                <input
                  onChange={e => updateState(e, 'password')}
                  value={credentials.password}
                  type={passwordShown ? 'text' : 'password'}
                  className="px-4 py-3 mt-1 w-95 bg-background text-body-2 text-subtext"
                  name="password"
                  required
                  placeholder="**********"
                  minLength="7"
                />
                <span className="z-10 absolute text-center text-subtext absolute bg-transparent rounded text-base items-center justify-center w-8 p-2.5 h-100">
                  <button className="btn-viewPass" onClick={togglePasswordVisiblity} type="button">
                    <AiFillEye className="text-sm" />
                  </button>
                </span>
                {errors && errors.password !== '' ? (
                  <p className="text-error font text-sm">{errors.password}</p>
                ) : null}
              </div>
              <span>
                <br />
                <Checkbox
                  type="checkbox"
                  className="w-4"
                  name="checkbox"
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                />
                <label className="px-2 py-3 mt-4 text-body-2 text-subtext"> Remember me </label>
              </span>
              <button
                type="submit"
                className="px-5 py-3 mt-10 text-white rounded-sm bg-primary hover:bg-primary-light"
                disabled={isLoading}
              >
                <span className="flex justify-center">{isLoading ? <TailSpin height={24} /> : 'Log In'}</span>
              </button>
              <Link
                to="/forgot-password"
                className="mt-3 text-sm font-medium text-center underline text-gray-1"
              >
                Forgot/Reset Password?
              </Link>
            </form>
            {/* {error && (
              <div className="mt-5">
                <p className="text-sm text-center text-error">{error}</p>
              </div>
            )} */}
          </div>
        </Box>
      </div>
    </OnePage>
  );
};

Signin.propTypes = {
  history: PropTypes.object,
};

export default Signin;
