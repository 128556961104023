/* eslint-disable prettier/prettier */
import React from 'react';
import { Button, Result } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';

import OnePage from '../components/Pages/OnePage';

const NoMatch = () => {
  const location = useLocation();
  const history = useHistory();

  return (
    <OnePage text="no-match">
      <Result
        status="404"
        title="Page Not Found!"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={() => history.goBack()}>
            Go Back
          </Button>
        }
      />
    </OnePage>
  );
};

export default NoMatch;
