/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import EmailAPIs from '../../api/email';
import _ from 'lodash';
import { FiSearch } from 'react-icons/fi';
import moment from 'moment';
import { isCurrentUserAnOwner } from '../../utilities/authentication';
import { ExclamationCircleOutlined,DeleteOutlined } from '@ant-design/icons';
import OnePage from '../../components/Pages/OnePage';
import useTitle from '../../hooks/useTitle';
import { Table,Tooltip ,Modal,Checkbox} from 'antd';
import NoMatch from '../NoMatch';
import UserAPIs from '../../api/user';
import { handleErrorMessages, handleSuccessMessages } from '../../helpers/flashMessages';
import { useAlert } from 'react-alert';

const EmailLogs = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [emailLog, setEmailLog] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [options, setOptions] = useState({
    search: '',
    pagination: { current: 1, pageSize: 10, pageSizeOptions: [10, 20, 50] },
    sortFilters: {},
  });

  useTitle('Email Logs');
  const { confirm } = Modal;
  const alert = useAlert();

  const columns = [
    {
      title: (
        <Checkbox
          onChange={e => onSelectAll(e)}
          checked={
            selectedRows.length > 0 &&
            _.isEqual(
              _.sortBy(emailLog.map(x => x._id)),
              _.sortBy(
                _.intersectionWith(
                  selectedRows,
                  emailLog.map(x => x._id),
                  _.isEqual
                )
              )
            )
          }
        />
      ),
      dataIndex: '_id',
      key: 'select',
      // eslint-disable-next-line react/display-name
      render: _id => <Checkbox onChange={e => onSelectRow(e, _id)} checked={selectedRows.includes(_id)} />,
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      width: '30%',
      sorter: true,
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      className: 'email-subject',
      key: 'subject',
      width: '30%',
      sorter: true,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '30%',
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend'],
      render: createdAt => (createdAt ? moment(createdAt).format('MMMM Do YYYY, h:mm:ss a') : '-'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      width: '10%',
      // eslint-disable-next-line react/display-name
      render: status => {
        return (
          <p
            className={
              status == 'delivered'
                ? 'badge delivered'
                : status == 'processed'
                ? 'badge processed'
                : status == 'sent'
                ? 'badge sent'
                : status == 'failed' || status == 'deferred' || status == 'dropped'
                ? 'badge failed'
                : 'badge NA'
            }
          >{`${status ? status : 'NA'}`}</p>
        );
      },
    },
    { 
      title: 'Actions',
      dataIndex: '_id',
      // eslint-disable-next-line react/display-name
      render: _id => (
        <>
             <Tooltip title="delete">
            <DeleteOutlined className="ml-2" onClick={e => showDeleteConfirm(e, _id)} />
          </Tooltip>
        </>
      ),
    },
  ];

  const ID = localStorage.getItem('LoginId');
  
  const showDeleteConfirm = (e, _id) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'The deleted record will not be recovered.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
        if(_id !=ID)
        {
          const response = UserAPIs.deleteEmaillogs(_id);
          handleSuccessMessages({customMessage: `Email Logs removed successfully.`}, alert);
        }
        else if(_id ==ID)
        {
          handleErrorMessages({customeMessage:`Email logs can not be deleted`,alert})
        } 
        setTimeout(()=>{
          fetchEmailLogs(options);
        },1000)
        
        setSelectedRows([]);
      },

      onCancel() {
        setSelectedRows([]);
      },
    });
  }; 
  
  const fetchEmailLogs = async options => {
    try {
      setLoading(true);
      const response = await EmailAPIs.getEmailLogs(options);
      const { emailLogs } = response.data;
      setEmailLog(emailLogs.docs);
      setOptions({
        ...options,
        pagination: {
          current: emailLogs.page,
          pageSize: emailLogs.limit,
          total: emailLogs.totalDocs,
          showSizeChanger: true,
        },
      });
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchEmailLogs(options);
  }, []);

  const onSelectAll = e => {
    if (e.target.checked) {
      
      const test = [...new Set([...selectedRows, emailLog.map(n => n._id)].flat())];
      setSelectedRows(test);
    } else {
      const selectedIds = selectedRows.filter(n => !emailLog.map(x => x._id).includes(n));
      setSelectedRows(selectedIds);
    }
  };
  const onSelectRow = async (e, _id) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, _id]);
    } else {
      const selectedIds = selectedRows.filter(n => n !== _id);
      setSelectedRows(selectedIds);
    }
  };

  const filterSortedTable = e => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    const query = e.target.value;
    const updatedOptions = {
      ...options,
      search: query.trim(),
      pagination: { ...options.pagination, current: 1 },
    };
    setOptions(updatedOptions);
    setTypingTimeout(() =>
      setTimeout(() => {
        fetchEmailLogs(updatedOptions);
      }, 1000)
    );
  };
  const showDelete = (e, selectedRows) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'The deleted record will not be recovered.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
        if (!selectedRows.includes(ID)) {
          const response = UserAPIs.deleteMultipleEmaillogs([selectedRows]);
          handleSuccessMessages({customMessage: `Emails logs deleted successfully.`}, alert);
          } else if (selectedRows.includes(ID)) {
            const newIDs = selectedRows.filter(item => item !== ID)
            const response = UserAPIs.deleteMultipleEmaillogs([newIDs]);
            handleErrorMessages({customMessage: `Email logs can not be deleted.`}, alert);
            
          }
          setTimeout(()=>{
            fetchEmailLogs(options);
          },1000)
          setSelectedRows([]);
        },
  
        onCancel() {
          setSelectedRows([]);
        },

    });
  };

  const handleChange = (pagination, filters, sorter) => {
    const emailLog = async () => {
      try {
        const updatedOptions = { ...options, pagination };
        setOptions({
          ...updatedOptions,
          sortFilters: {
            sortField: sorter?.columnKey,
            sortOrder: sorter?.order,
          },
        });
        fetchEmailLogs({
          ...updatedOptions,
          sortFilters: {
            sortField: sorter?.columnKey,
            sortOrder: sorter?.order,
          },
        });
      } catch (e) {
        setError(e);
      }
    };
    emailLog();
  };

  return (
    <>
      <OnePage background="background">
        {isCurrentUserAnOwner() ? (
          <div className="px-5 md:px-20">
            <h1 className="text-h5 text-text font-semibold my-4">Email Logs</h1>
            <div className="flex flex-wrap justify-start items-center">
              <div className="float-left mr-auto">
                <span className="z-10 absolute text-center text-subtext absolute bg-transparent rounded text-base items-center justify-center w-8 p-2.5 h-100">
                  <FiSearch className="text-sm" />
                </span>
                <input
                  onChange={e => filterSortedTable(e)}
                  name="emails"
                  placeholder="Search"
                  className="pl-8 py-2 px-4 text-gray-1 font-normal rounded-r-none"
                />
              </div>
              <div className='float-left ml-auto'>
              <div className="del--icon email-logs" style={selectedRows.length === 0 ? { cursor: 'default' } : {}}>
              <DeleteOutlined onClick={(e) => selectedRows.length === 0  ? {} : showDelete(e, selectedRows)} style={selectedRows.length === 0 ? { cursor: 'default' } : {}}/>
                </div>
              </div>
             
            </div>
            <div className="mt-10">
              <Table
                columns={columns}
                dataSource={emailLog}
                error={error}
                onChange={handleChange}
                loading={loading}
                sortDirections={['ascend', 'descend']}
                pagination={options.pagination.total > 10 ? options.pagination : false}
                scroll={{ scrollToFirstRowOnChange: true }}
              />
            </div>
          </div>
        ) : (
          <NoMatch></NoMatch>
        )}
      </OnePage>
    </>
  );
};

export default EmailLogs;
