/* eslint-disable prettier/prettier */
import axios from 'axios';

import { getToken, isCurrentTokenValid } from '../utilities/token';

export default class HttpService {
  constructor(endpoint) {
    this.endpoint = endpoint;
    this.config = {
      headers: {
        Authorization: getToken(),
      },
    };
    this.url = `${process.env.REACT_APP_API_URL}${this.endpoint}`;
  }

  unsecuredGet() {
    return axios.get(this.url);
  }

  get() {
    if (isCurrentTokenValid()) {
      return axios.get(this.url, this.config);
    } else {
      return Promise.reject(new Error('Session is expired. Please login again'));
    }
  }

  put(body) {
    if (isCurrentTokenValid()) {
      return axios.put(this.url, body, this.config);
    } else {
      return Promise.reject(new Error('Session is expired. Please login again'));
    }
  }

  delete() {
    if (isCurrentTokenValid()) {
      return axios.delete(this.url, this.config);
    } else {
      return Promise.reject(new Error('Session is expired. Please login again'));
    }
  }

  unsecuredPost(body) {
    return axios.post(this.url, body);
  }

  post(body) {
    return axios.post(this.url, body, this.config);
  }
}
