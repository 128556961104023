/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { Button } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import { AiFillPrinter } from 'react-icons/ai';

import Invoice from './Invoice';
/* eslint-disable react/display-name */
const InvoiceCarModel = React.forwardRef(({ invoicesForTheCarModel, carModel, vehiclesDetail }, ref) => {
  const [clicked, setClicked] = useState(false);
  const vehicleData = carModel.split('/');
  const { id } = useParams();
  invoicesForTheCarModel.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  });
  const handleChange=()=>{
    setClicked(!clicked)
  }

  return (
    <div className="cursor-pointer w-100">
      <div
        className="p-5 text-secondary bg-secondary hover:bg-opacity-80 d-flex"
        onClick={handleChange}
      >
        <p className="text-subtitle-2 text-text font-semibold green_row_wrap">
          {vehicleData[0] == 'true' ? (
            <span className={`rounded-full h-1.5 w-1.5 bg-green mr-2 d-inline-flex`}></span>
          ) : (
            <span className={`rounded-full h-1.5 w-1.5 bg-error mr-2 d-inline-flex`}></span>
          )}
          {` ${vehicleData[1] ? `${vehicleData[1]}/` : ''}  ${vehicleData[2] ? `${vehicleData[2]}/` : ''}  ${vehicleData[3] ? `${vehicleData[3]}/` : ''} ${vehicleData[4] ? `${vehicleData[4]}/` : ''}  ${vehicleData[5] ? `${vehicleData[5]}` : ''}`}
        </p>
      </div>

      <div className={`bg-white h-auto ${clicked ? '' : 'hidden'}`}>
        <div className="w-full table-auto table-striped appData_table" ref={ref}>
          <div style={{ padding: '20px' }} className='print-block hidden'>
            <h3 style={{ fontWeight: 'bold', fontSize: '22px' }}>Vehicle Details:</h3>
            {
              vehiclesDetail?.vin ?
                <div style={{ width: '50%', float: 'left' }}>
                  <label style={{ fontWeight: 'bold' }}>VIN:</label>
                  <h4 style={{ fontSize: '18px' }}>{vehiclesDetail?.vin}</h4>
                </div> : ''
            }
            {
              vehiclesDetail?.yearManufactured ?
                <div style={{ width: '50%', float: 'left' }}>
                  <label style={{ fontWeight: 'bold' }}>Year Manufactured:</label>
                  <h4 style={{ fontSize: '18px' }}>{vehiclesDetail?.yearManufactured}</h4>
                </div> : ''
            }
            {
              vehiclesDetail?.make ?
                <div style={{ width: '50%', float: 'left' }}>
                  <label style={{ fontWeight: 'bold' }}>Make:</label>
                  <h4 style={{ fontSize: '18px' }}> {vehiclesDetail?.make}</h4>
                </div> : ''
            }

            {vehiclesDetail?.model ?
              <div style={{ width: '50%', float: 'left' }}>
                <label style={{ fontWeight: 'bold' }}>Model:</label>
                <h4 style={{ fontSize: '18px' }}> {vehiclesDetail?.model}</h4>
              </div> : ''
            }
            {vehiclesDetail?.engine ?
              <div style={{ width: '50%', float: 'left' }}>
                <label style={{ fontWeight: 'bold' }}>Engine:</label>
                <h4 style={{ fontSize: '18px' }}> {vehiclesDetail?.engine}</h4>
              </div> : ''
            }
            {vehiclesDetail?.transmission ?
              <div style={{ width: '50%', float: 'left' }}>
                <label style={{ fontWeight: 'bold' }}>Transmission:</label>
                <h4 style={{ fontSize: '18px' }}> {vehiclesDetail?.transmission}</h4>
              </div> : ''
            }
            {vehiclesDetail?.body ?
              <div style={{ width: '50%', float: 'left' }}>
                <label style={{ fontWeight: 'bold' }}> Body:</label>
                <h4 style={{ fontSize: '18px' }}>{vehiclesDetail?.body}</h4>
              </div> : ''
            }

            {
              vehiclesDetail?.color ?
                <div style={{ width: '50%', float: 'left' }}>
                  <label style={{ fontWeight: 'bold' }}>Color:</label>
                  <h4 style={{ fontSize: '18px' }}>{vehiclesDetail?.color}</h4>
                </div>
                : ''
            }
          </div>
          <table className='w-full table-auto table-striped'>
            <tbody>
              {invoicesForTheCarModel.map((invoice, x) => (
                <Invoice key={x.toString()} customerId={id} invoice={invoice} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});

InvoiceCarModel.propTypes = {
  invoicesForTheCarModel: PropTypes.array,
  carModel: PropTypes.string,
  ref: PropTypes.object,
  vehiclesDetail:PropTypes.object
};

export default InvoiceCarModel;
