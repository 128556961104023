/* eslint-disable prettier/prettier */
import React from 'react';
import { Button, Result } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

import OnePage from '../components/Pages/OnePage';

const ComingSoon = () => {
  // const location = useLocation();
  const history = useHistory();

  return (
    <div className="coming-soon">
      <Result
        // icon={<LoadingOutlined />}
        status="info"
        title="Coming Soon..."
        subTitle="Sorry, the page you visited isn't available now but it's coming very soon."
        extra={
          <Button type="primary" onClick={() => history.goBack()}>
            Go Back
          </Button>
        }
      />
    </div>
  );
};

export default ComingSoon;
