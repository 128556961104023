/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';

import RequiredAsterisk from './RequiredAsterisk';

const SelectCarUserInput = ({ label, name, value, onChange, required,requiredfield, defaultOption, children, ...rest }) => (
  <div className="flex flex-col">
    <label className="inline text-small text-text mb-1">
      {label}
      {required === true && <RequiredAsterisk />}
      {requiredfield === true && <RequiredAsterisk />}
      
    </label>
    <select
      name={name}
      className="px-4 py-3 pr-8 bg-white text-body-2 text-subtext appearance-none focus:outline-none custom-downarrow rounded"
      value={value}
      onChange={onChange}
      required={required}
      {...rest}
    >
      {children}
    </select>
  </div>
);

SelectCarUserInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  requiredfield: PropTypes.bool,
  required: PropTypes.bool.isRequired,
  defaultOption: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default SelectCarUserInput;
