/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Form, Row } from 'antd';
import { FiArrowLeft } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { Button, Select, AutoComplete } from 'antd';
// import FormHeader from '../../../components/FormHeader';
import TextInput from '../../../../components/TextInput';
import PropTypes from 'prop-types';
import RadioInput from '../../../../components/RadioInput';
import RequiredAsterisk from '../../../../components/RequiredAsterisk';
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import CreatableSelect from 'react-select/creatable';
import { handleErrorMessages, handleSuccessMessages } from '../../../../helpers/flashMessages';
import AdminAPIs from '../../../../api/admin';
import { useAlert } from 'react-alert';
import _ from 'lodash';
import ReactQuill from 'react-quill';
const GarageOwnerFields = ({
  operation,
  submitHandler,
  data,
  updateState,
  setOwner,
  setAdmin,
  phone,
  setPhone,
  admin,
  owner,
}) => {
  const customStyles = {
    valueContainer: base => ({
      ...base,
      padding: '8px 10px',
    }),
  };
  const history = useHistory();
  const alert = useAlert();
  const updatedData = data;
  const [garages, setGarages] = useState([]);
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [options, setOptions] = useState([]);
  const [lastNameSuggestions, setLastNameSuggestions] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    garage: '',
  });
  const [adminData, setAdminData] = useState({
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
    mobile: data?.mobile ?? '',
    email: data?.email ?? '',
    garage: data?.garage ?? '',
    role: 'owner',
    status: data?.status ?? '',
  });
  const { Option, OptGroup } = Select;
  const [fname, setFName] = useState([]);
  const [lname, setLName] = useState([]);
  const NotesRef = useRef();
  const modules = {
    toolbar: false,
  };
  // const [phone, setPhone] = useState('');
  data.mobile = phone;
  useEffect(() => {
    const results = localStorage.getItem('usersName');
    var names = [JSON.parse(results)];
    for (let i = 0; i < names[0]?.length; i++) {
      fname.push(names[0][i]?.firstName);
      lname.push(names[0][i]?.lastName);
    }
    setFName(names[0]);
    setLName(names[0]);
  }, []);
  const checkCharacterCount = (event, refValue) => {
    const unprivilegedEditor = refValue.current.editor;
    if (unprivilegedEditor.getLength() > 600 && event.key !== 'Backspace') event.preventDefault();
  };
  useEffect(() => {
    if (data?.ownerId?.notes) {
      setOwner({ ...owner, notes: data?.ownerId?.notes });
    }
  }, [data?.ownerId?.notes]);

  const firstNameOptions = fname.map((items, i) => {
    return { label: [items.firstName], value: items.firstName, data: items };
  });
  const lastNameOptions = lname.map((items, i) => {
    return { label: [items.lastName], value: items.lastName, data: items };
  });
  const getOwner = async val => {
    const response = await AdminAPIs.getOwner(val);
    const { getOwner } = response.data;
    setPhone(getOwner.mobile);
    setOwner({
      firstName: getOwner.firstName,
      lastName: getOwner.lastName,
      email: getOwner.email,
    });
  };
  const emailString = /^\s*(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
  const handleErrors = e => {
    let err = { ...errors };
    if (data.email == '') err.email = '* Enter email';
    else if (!data.email.match(emailString)) err.email = '* Enter a valid email';
    else if (data.email != '') err.email = '';
    if (data.firstName == '') err.firstName = '* Enter first name';
    if (data?.firstName != '' && !isValidName(data.firstName)) err.firstName = '* Please enter a valid name';
    else if (data.firstName != '') err.firstName = '';
    if (data.lastName == '') err.lastName = '* Enter last name';
    if (data?.lastName != '' && !isValidName(data.lastName)) err.lastName = '* Please enter a valid name';
    else if (data.lastName != '') err.lastName = '';
    if (data.garage == '') err.garage = '* Enter garage';
    if (data.garage != '') err.garage = '';
    if (phone == undefined || (phone == '' && data.mobile == '')) err.mobile = '* Enter mobile number';
    else if (!isValidPhoneNumber(phone || data.mobile)) err.mobile = '* Enter valid phone number';
    else if (isValidPhoneNumber(phone || data.mobile)) err.mobile = '';
    setErrors(err);
  };

  const onChangeError = event => {
    event.persist();

    if (event.target.name === 'firstName') {
      if (event.target.value == '')
        setErrors(prev => ({ ...prev, [event?.target?.name]: `* Enter first name` }));
      else if (data?.firstName != '' && !isValidName(event.target.value))
        setErrors(prev => ({ ...prev, [event?.target?.name]: `* Please enter a valid name` }));
      else if (event.target.value != '') setErrors(prev => ({ ...prev, [event?.target?.name]: '' }));
    }

    if (event.target.name === 'lastName') {
      if (event.target.value == '')
        setErrors(prev => ({ ...prev, [event?.target?.name]: `* Enter last name` }));
      else if (data?.lastName != '' && !isValidName(event.target.value))
        setErrors(prev => ({ ...prev, [event?.target?.name]: `* Please enter a valid name` }));
      else if (event.target.value != '') setErrors(prev => ({ ...prev, [event?.target?.name]: '' }));
    }

    if (event.target.name === 'mobile') {
      if (event.target.value == '' || phone == undefined || (phone == '' && data.mobile == ''))
        setErrors(prev => ({ ...prev, [event?.target?.name]: `* Enter mobile number` }));
      else if (!isValidPhoneNumber(phone || data.mobile))
        setErrors(prev => ({ ...prev, [event?.target?.name]: `* Enter valid phone number` }));
      else if (isValidPhoneNumber(phone || data.mobile))
        setErrors(prev => ({ ...prev, [event?.target?.name]: '' }));
    }

    if (event.target.name === 'email') {
      if (data.email == '') setErrors(prev => ({ ...prev, [event?.target?.name]: `* Enter email` }));
      else if (!data.email.match(emailString))
        setErrors(prev => ({ ...prev, [event?.target?.name]: `* Enter a valid email` }));
      else if (data.email != '') setErrors(prev => ({ ...prev, [event?.target?.name]: `` }));
    }

    if (event.target.name === 'garage') {
      if (event.target.value == '' || data.garage == '')
        setErrors(prev => ({ ...prev, ['garage']: ` * Select garage` }));
      else if (data.garage != '') setErrors(prev => ({ ...prev, ['garage']: `` }));
    }
  };

  const nameChange = val => {
    const value = val?.value;
    const isNew = val?.__isNew__;
    {
      !isNew ? getOwner(value) : '';
    }
  };
  function handleChange(value) {}
  // const updateState = e => {
  //   const key = e.target.name;
  //   let value = e.target.value;
  //   setAdminData({ ...adminData, [key]: value });
  // };

  // // To disable submit button at the beginning.
  // useEffect(() => {
  //   forceUpdate({});
  // }, []);
  const [collapsed, setCollapsed] = useState('false');
  /********/

  useEffect(() => {
    (async () => {
      try {
        const response = await AdminAPIs.getGarages();
        const { garages } = response.data;
        setGarages(garages);
      } catch (e) {
        // const message = handleErrorMessages(e);
        // setError(message);
      }
    })();
  }, []);

  const isValidName = name => {
    return name && /^[a-zA-Z ]{2,30}$/.test(name);
  };

  const handleSearch = async value => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    if (value) {
      setTypingTimeout(() =>
        setTimeout(async () => {
          const response = await AdminAPIs.getFirstNameSuggestions(value);
          const { usersName } = response.data;
          let firstNames = [];
          let lastNames = [];
          firstNames =
            usersName &&
            usersName?.map((data, i) => {
              lastNames.push({ key: i, value: data.lastName });
              return { key: i, value: data.firstName, obj: data };
              //  return {'key': i.toString(), 'value' : data.firstName}
            });
          setOptions(firstNames);
          setLastNameSuggestions(lastNames);
          // setFirstInputName(value)
        }, 1000)
      );
    }
  };

  const handlePhone = e => {
    setPhone(e);
    handlePhoneErrors(e);
  };
  const handlePhoneErrors = e => {
    let err = { ...errors };
    let phone = e;
    if (phone == undefined || (phone == '' && data.mobile == '')) err.mobile = '* Enter mobile number';
    else if (!isValidPhoneNumber(phone)) err.mobile = '* Enter valid phone number';
    else if (isValidPhoneNumber(phone)) err.mobile = '';
    setErrors(err);
  };

  const handleSubmit = e => {
    e.preventDefault();
    data.mobile = phone || data.mobile;
    setIsValidFirstName(isValidName(data?.firstName));
    setIsValidLastName(isValidName(data?.lastName));
    handleErrors();
    if (
      errors.firstName == '' &&
      errors.lastName == '' &&
      errors.email == '' &&
      errors.garage == '' &&
      isValidPhoneNumber(data.mobile) &&
      (data.email && data.firstName && data.lastName && data.mobile && data.garage) != ''
    ) {
      if (isValidName(data?.firstName) && isValidName(data?.lastName)) {
        if (data.mobile && data.mobile.length >= 10 && isValidPhoneNumber(data.mobile)) {
          const updatedOwner = { ...data, mobile: phone };
          submitHandler(updatedOwner);
        } else {
          handleErrorMessages({ customMessage: `Please enter a valid phone number.` }, alert);
        }
      }
    }
  };

  const onChnageLastName = (val, obj) => {
    setOwner({ ...owner, lastName: val });
  };

  const handleOnCancelClick = e => {
    e.preventDefault();
    history.push('/admin/garage-owner');
  };
  return (
    <>
      <div className="filter--outer mb-4">
        <div className="d-flex top--heading1">
          <h2 className="text-2xl font-semibold text-gr  ay-700 dark:text-gray-200">
            <Link to="/admin/garage-owner">
              <FiArrowLeft className="inline-block align-middle mr-2 text-base" />
              <p className="inline-block align-middle">
                {operation === 'add' ? 'Add Garage Owner' : 'Edit Garage Owner'}
              </p>
            </Link>
            {/* </button> */}
          </h2>
        </div>
      </div>
      <div className="FormWrapper">
        <form
          className="flex flex-col px-5 py-6 bg-white app_form"
          onSubmit={e => handleSubmit(e)}
          noValidate
        >
          <Row className="grid gap-4 grid-cols-1 md:grid-cols-3 sm:grid-cols-2 lg:grid-cols-4">
            <div className="flex flex-col input__wrap">
              {/* <label className="inline text-small text-text mb-1">
                First Name
                <RequiredAsterisk />
              </label>
              <CreatableSelect
                isClearable
                options={firstNameOptions}
                inputProps={{
                  required: true,
                  pattern: '^[a-zA-Z ]{2,30}$',
                  onInvalid: 'this.setCustomValidity("First name must be alphabetical and non-empty")',
                }}
                name="firstName"
                value={{value: data?.firstName, label: data?.firstName}}
                onChange={val =>{
                  data.firstName = val?.value
                  const userData = val?.data || {};
                  const customMobile = val?.data ? val.data.mobile : "";
                  (setAdmin || setOwner)({ ...(admin || data), firstName: val?.value, ...userData});
                  setPhone(customMobile)
                }}
                styles={customStyles}
                onBlur={handleErrors}
                onKeyDown={handleErrors}
              /> */}
              <TextInput
                label="First Name"
                onChange={updateState}
                name="firstName"
                value={data?.firstName}
                required={true}
                placeholder="First name"
                onBlur={onChangeError}
                onkeypress={onChangeError}
              />

              {/* {!isValidFirstName ? (
                <p className="text-small text-danger">Please enter a valid first name</p>
              ) : null} */}
              {errors && errors.firstName !== '' ? (
                <p className="text-error font text-sm">{errors.firstName}</p>
              ) : null}
            </div>
            <div className="flex flex-col input__wrap">
              {/* <label className="inline text-small text-text mb-1">
                Last Name
                <RequiredAsterisk />
              </label>
              <CreatableSelect
                isClearable
                options={lastNameOptions}
                inputProps={{
                  required: true,
                  pattern: '^[a-zA-Z ]{2,30}$',
                  oninvalid: 'this.setCustomValidity("Last name must be alphabetical and non-empty")'
                }}
                name="lastName"
                defaultValue={data?.lastName}
                value={{value: data?.lastName, label: data?.lastName}}
                onChange={val =>{
                  data.lastName = val?.value
                  const userData = val?.data || {};
                  const customMobile = val?.data ? val.data.mobile : "";
                  (setAdmin || setOwner)({ ...(admin || data), lastName: val?.value, ...userData});
                  setPhone(customMobile)
                }}
                styles={customStyles}
                onBlur={handleErrors}
                onKeyDown={handleErrors}
              /> */}

              <TextInput
                label="Last Name"
                onChange={updateState}
                name="lastName"
                value={data?.lastName}
                required={true}
                placeholder="Last name"
                onBlur={onChangeError}
                onkeypress={onChangeError}
              />
              {/* {!isValidLastName ? (
                <p className="text-small text-danger">Please enter a valid last name</p>
              ) : null} */}
              {errors && errors.lastName !== '' ? (
                <p className="text-error font text-sm">{errors.lastName}</p>
              ) : null}
            </div>
            <div>
              <label>
                Mobile Number
                <RequiredAsterisk />
              </label>
              <PhoneInput
                label="Mobile"
                international
                countryCallingCodeEditable={true}
                defaultCountry="CA"
                onChange={e => handlePhone(e)}
                name="mobile"
                value={phone != '' ? phone : data?.mobile}
                required={true}
                placeholder="123-456-7890"
                maxLength="16"
                minLength="10"
                onBlur={onChangeError}
                // onKeyDown={handleErrors}
                // onFocus={handleErrors}
              />
              {errors && errors.mobile !== '' ? (
                <p className="text-error font text-sm">{errors.mobile}</p>
              ) : null}
            </div>
            <div>
              <TextInput
                label="Email"
                onChange={updateState}
                name="email"
                value={data?.email}
                required={true}
                placeholder="john.doe@mail.com"
                onBlur={onChangeError}
                onkeypress={onChangeError}
              />
              {errors && errors.email !== '' ? (
                <p className="text-error font text-sm">{errors.email}</p>
              ) : null}
            </div>
            <div>
              <TextInput
                label="Garage"
                onChange={updateState}
                name="garage"
                value={data?.garage}
                // value={adminData?.garage}
                required={true}
                placeholder="test"
                onBlur={onChangeError}
                onkeypress={onChangeError}
              />
              {errors && errors.garage !== '' ? (
                <p className="text-error font text-sm">{errors.garage}</p>
              ) : null}
            </div>
            <div>
              <TextInput
                label="Street"
                onChange={updateState}
                name="street"
                value={data?.ownerId?.street}
                required={false}
                placeholder="e.g 100 Bayview Ave"
              />
            </div>
            <div>
              <TextInput
                label="City"
                onChange={updateState}
                name="city"
                value={data?.ownerId?.city}
                required={false}
                placeholder="Toronto"
              />
            </div>
            <div>
              <TextInput
                label="Province"
                onChange={updateState}
                name="province"
                value={data?.ownerId?.province}
                required={false}
                placeholder="Ontario"
              />
            </div>
            <div>
              <TextInput
                label="Postal Code"
                onChange={updateState}
                name="postalCode"
                value={data?.ownerId?.postalCode}
                required={false}
                placeholder="e.g M1M 1M1"
                maxLength="11"
              />
            </div>
            <div className="flex flex-row">
              <label className="inline text-small text-text mb-1">Status</label>
              <RadioInput
                label="Active"
                defaultChecked={data?.status === true}
                onClick={e => updateState(e)}
                value="true"
                name="status"
              />
              <RadioInput
                label="Inactive"
                defaultChecked={data?.status === false}
                onClick={e => updateState(e)}
                value="false"
                name="status"
              />
            </div>
            <div className="flex flex-col  w-full col-span-full">
              <label className="text-small text-text mb-2">
                <div>
                  Notes
                  {<span className="grey_text"> (max 600 characters) </span>}
                </div>
              </label>
              <ReactQuill
                className="notes-editor-height"
                onKeyDown={e => checkCharacterCount(e, NotesRef)}
                ref={NotesRef}
                name="notes"
                modules={modules}
                onChange={value => {
                  if (operation == 'add') {
                    setOwner({
                      ...owner,
                      notes: value,
                    });
                  } else {
                    setOwner({
                      ...owner,
                      notes: value,
                    });
                    // setOwner({ ...owner, ownerId: { ...owner.ownerId, notes: value }, notes: value });
                  }
                }}
                value={data?.notes || ''}
                // defaultValue={data?.ownerId?.notes}
              />
            </div>
          </Row>
          <div className="text-right">
            <button
              onClick={handleOnCancelClick}
              className="px-5 py-3 mt-5 mx-2 w-36 text-white text-button rounded-sm bg-primary hover:bg-opacity-80"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-5 py-3 mt-5 w-36 text-white text-button rounded-sm bg-primary hover:bg-opacity-80"
            >
              {operation === 'add' ? 'Add Owner' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
GarageOwnerFields.propTypes = {
  title: PropTypes.string,
  operation: PropTypes.string,
  submitHandler: PropTypes.func,
  data: PropTypes.object,
  updateState: PropTypes.func,
  setOwner: PropTypes.func,
  setAdmin: PropTypes.func,
  admin: PropTypes.object,
  phone: PropTypes.string,
  setPhone: PropTypes.func,
  owner: PropTypes.object,
};

export default GarageOwnerFields;
