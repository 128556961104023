/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';

const DropDownText = ({ label, value }) => (
  <>
    {label && !!value && (
      <div className="flex flex-col">
        <p className="text-body-2 text-text">
          <b>{label}: </b> {value}
        </p>
      </div>
    )}
  </>
);

DropDownText.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DropDownText;
