/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import Footer from '../Footer';

const OnePage = ({ children, text }) => (
  <section className={text ? 'bg-background no-match ' : 'bg-background '}>
    <div className="outer-container">{children}</div>
    <Footer />
  </section>
);

OnePage.propTypes = {
  children: PropTypes.object.isRequired,
  background: PropTypes.string,
  text: PropTypes.string,
};

export default OnePage;
