/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { deleteToken, isCurrentTokenValid } from '../../utilities/token';

import LandingNavbar from './LandingNavbar';
import SignupNavbar from './SignupNavbar';
import LoggedinNavbar from './LoggedinNavbar';

const Navbar = ({ location, history }) => {
  const isLoggedIn = isCurrentTokenValid();
  const isOnLandingPageAndIsNotLoggedIn = location.pathname === '/' && !isLoggedIn;
  const email = localStorage.getItem('email');
  const isOnUnAuthPage =
    [
      '/signup',
      '/signin',
      '/forgot-password',
      '/forgot-password/update-password',
      `/forgot-password/verify/${email}`,
    ].includes(location.pathname);


  // if (isOnUnAuthPage && isLoggedIn) {
  //   window.location.href = '/';
  //   return null;
  // }

  const logout = () => {
    deleteToken();
    localStorage.clear();
    history.push('/');
  };

  const login = () => {
    history.push('/signin');
  };
  return (
    <div
      className={`${
        isOnLandingPageAndIsNotLoggedIn
          ? 'menu-unfold border-0 header-section position-relative'
          : 'border-b border-gray-3 bg-background'
      } w-full py-3 position-relative z-10`}
    >
      <div className="relative px-3 md:px-0 mx-auto ">
        {isOnLandingPageAndIsNotLoggedIn && <LandingNavbar />}
        {isOnUnAuthPage && !isLoggedIn && <SignupNavbar login={login} />}
        {isLoggedIn && <LoggedinNavbar logout={logout} />}
      </div>
    </div>
  );
};

Navbar.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object,
};

export default withRouter(Navbar);
