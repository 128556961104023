/* eslint-disable prettier/prettier */
import React from 'react';

const Footer = () => {
  return (
    <div className="footer">
      <div className="copyright">
        <i>&#169; Auto Hound 2023. All rights reserved.</i>
      </div>
    </div>
  );
};
export default Footer;
