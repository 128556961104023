/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Layout, Menu, Dropdown, Space, Input, Form, Row, Col } from 'antd';
import { Button, Select } from 'antd';

import {
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  PieChartOutlined,
  ShopOutlined,
  FileOutlined,
  ArrowLeftOutlined,
  SettingOutlined,
  ApartmentOutlined,
  BranchesOutlined,
  CarOutlined,
} from '@ant-design/icons';

const { Option, OptGroup } = Select;

const menu = (
  <Menu
    items={[
      {
        label: <a href="https://www.antgroup.com">1st menu item</a>,
        key: '0',
      },
      {
        label: <a href="https://www.aliyun.com">2nd menu item</a>,
        key: '1',
      },
      {
        type: 'divider',
      },
      {
        label: '3rd menu item',
        key: '3',
      },
    ]}
  />
);
function handleChange(value) {
  console.log(`selected ${value}`);
}

const UserFields = () => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);
  const [collapsed, setCollapsed] = useState('false');
  /********/
  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }
  const items = [
    getItem('Dashboard', '1', <PieChartOutlined />),
    getItem('Users', 'sub1', <UserOutlined />, [
      getItem('Customer', '3'),
      getItem('Business User', '4'),
      getItem('Garage owner', '5'),
      getItem('Super Admin', '6'),
    ]),
    getItem('Garages', '2', <ShopOutlined />),
    getItem('Vehicles', '7', <CarOutlined />),
    getItem('Network Report requests', '8', <BranchesOutlined />),
    getItem('Network Average Vehicles', '9', <ApartmentOutlined />),
    getItem('Invoices', '10', <FileOutlined />),
    getItem('Settings', '11', <SettingOutlined />),
  ];

  return (
    <>
      <div className="filter--outer mb-4">
        <div className="d-flex top--heading1">
          <h2 className="text-2xl font-semibold text-gray-700 dark:text-gray-200">
            <a href="#">
              <ArrowLeftOutlined />
            </a>{' '}
            <span>Customer</span>
          </h2>
        </div>
      </div>
      <div className="FormWrapper">
        <Form form={form} name="horizontal_login">
          <Row gutter={[16, 0]}>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Form.Item
                name="First Name"
                rules={[{ required: true, message: 'Please Enter your First Name!' }]}
              >
                <label>First Name</label>
                <Input placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Form.Item
                name="Last Name"
                rules={[{ required: true, message: 'Please Enter your Last Name!' }]}
              >
                <label>Last Name</label>
                <Input type="text" placeholder="Last Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Form.Item name="Email" rules={[{ required: true, message: 'Please Enter your Email!' }]}>
                <label>Email</label>
                <Input type="email" placeholder="Email" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Form.Item
                name="Mobile Number"
                rules={[{ required: true, message: 'Please Enter your Mobile Number!' }]}
              >
                <label>Mobile Number</label>
                <Input type="number" placeholder="Mobile Number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Form.Item name="Street" rules={[{ required: true, message: 'Please Enter your Street!' }]}>
                <label>Street</label>
                <Input type="text" placeholder="Street" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Form.Item name="City" rules={[{ required: true, message: 'Please Enter your City!' }]}>
                <label>City</label>
                <Input type="text" placeholder="City" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Form.Item name="Province" rules={[{ required: true, message: 'Please Enter your Province!' }]}>
                <label>Province</label>
                <Input type="text" placeholder="Province" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Form.Item name="Postcode" rules={[{ required: true, message: 'Please Enter your Postcode!' }]}>
                <label>Postcode</label>
                <Select defaultValue="lucy" onChange={handleChange}>
                  <Option value="jack">Jack</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="disabled" disabled>
                    Disabled
                  </Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Form.Item
                name="Discount level"
                rules={[{ required: true, message: 'Please Enter your Discount level!' }]}
              >
                <label>Discount level</label>
                <Input type="text" placeholder="Discount level" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Form.Item
                name="Labour Rate /Hour"
                rules={[{ required: true, message: 'Please Enter your Labour Rate /Hour!' }]}
              >
                <label>Labour Rate /Hour</label>
                <Input type="text" placeholder="Labour Rate /Hour" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Form.Item name="Province" rules={[{ required: true, message: 'Please Enter your Province!' }]}>
                <label>Province</label>
                <Input type="text" placeholder="Province" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Form.Item
                name="Shop Private notes"
                rules={[{ required: true, message: 'Please Enter your Shop Private notes!' }]}
              >
                <label>Shop Private notes</label>
                <Input type="text" placeholder="Shop Private notes" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={10}>
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !form.isFieldsTouched(true) ||
                      !!form.getFieldsError().filter(({ errors }) => errors.length).length
                    }
                  >
                    Save Changes
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default UserFields;
