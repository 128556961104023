/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';

const Row = ({ children, className }) => <div className={className}>{children}</div>;

Row.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Row;
