/* eslint-disable prettier/prettier */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
/* eslint-disable react/display-name */
const DiscountFormateBreakdown = React.forwardRef(({ref}) => {
  const headers = ['Spend', 'Discount off labour received'];
  return (
    <>
        <div className="table-responsive bg-white my-5 table-striped p-5" ref={ref}>
          <div className="font-bold ">Discount Format (Diagnostic & Repair labour)</div>
          <table className="table-auto">
            <thead>
              <tr className="p-5 font-medium text-left bg-white border-b text-text text-subtitle-2 border-secondary">
                {headers.map((header, i) => (
                  <th key={i.toString()} className="p-5  cursor-pointer">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
            <tr>
            <td className="p-5 font-normal  text-gray-1 text-body-2">
             over $2,500 including parts and vehicle purchase 
            </td>
            <td className="p-5 font-normal  text-gray-1 text-body-2">
             15% 
            </td>
            </tr>
            <tr>
            <td className="p-5 font-normal  text-gray-1 text-body-2">
             1,500, - $2,499 including parts and vehicle purchase
            </td>
            <td className="p-5 font-normal  text-gray-1 text-body-2">
             10% 
            </td>
            </tr>
            <tr>
            <td className="p-5 font-normal  text-gray-1 text-body-2">
             $700, - $1,499, including parts and vehicle purchase 
            </td>
            <td className="p-5 font-normal  text-gray-1 text-body-2">
             5% 
            </td>
            </tr> 
            <tr>
            <td className="p-5 font-normal  text-gray-1 text-body-2">
             Less than $700 
            </td>
            <td className="p-5 font-normal  text-gray-1 text-body-2">
             amount will be accumulated with following years purchase for discount in year after. 
            </td>
            </tr>  
            </tbody>
          </table>

          <div>
            <ul className="p-5 font-normal text-gray-1 text-body-2">
                <li>
                * Discounts are only granted from services being performed at a registered Auto Hound Facility.
                </li>
                <li>
                * Labour prices can change at any time without notice.
                </li>
                <li>
                * The year after receiving dicount you will drop down to next lowest discount, unless same amount is spent that year. 
                </li>
                <li>
                * If no purchases have accumilated in 2 years letters will not be send out.
                </li>
                <li>
                * All discounts will be credited to invoice at payment.
                </li>
                <li>
                * Charge accounts will be credited to month end statement, if payment is received within 30 days.
                </li>
            </ul>
            <h6 className="px-5">Items not included for discount.</h6>
            <ul className="px-5 py-1 font-normal text-gray-1 text-body-2">
                 <li>
                    * Safety Inspections
                 </li>
                 <li>
                    * Emission Testing
                 </li>
                 <li>
                    * Oil Changes
                 </li>
                 <li>
                    * Any menu pricing maintenance items.
                 </li>
                </ul>
                <ul className="p-5 font-normal text-gray-1 text-body-2">
                <li>
                    Discount format can change at any time or be cancelled in whole or in part without notice.
                </li>
            </ul>
          </div>
        </div>
    </>
  );
});

DiscountFormateBreakdown.propTypes = {
  ref: PropTypes.object,
};

export default DiscountFormateBreakdown;
