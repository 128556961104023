/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import PhoneInput from 'react-phone-number-input';
import RequiredAsterisk from '../RequiredAsterisk';
import Avatar from '../../assets/avatar_user.jpg';

import { isValidPhoneNumber } from 'react-phone-number-input';

const EditProfile = ({
  state,
  phone,
  setPhone,
  errors,
  handleChange,
  preview,
  profileImage,
  handleImgChange,
  handleUpdate,
  isApiCall,
  setErrors,
}) => {

  const { firstName, lastName, email ,profilePic} = state;

  const onChangeError = event => {
    const { name, value } = event.target;
    switch (name) {
      case 'firstName':
        if (value == '') setErrors(prev => ({ ...prev, [name]: `First Name is required` }));
        else if (value != '') setErrors(prev => ({ ...prev, [name]: '' }));
        break;
      case 'lastName':
        if (value == '') setErrors(prev => ({ ...prev, [name]: `Last Name is required` }));
        else if (value != '') setErrors(prev => ({ ...prev, [name]: '' }));
        break;
      case 'mobile':
        if (value == '') {
          setErrors(prev => ({ ...prev, [name]: `Mobile is required` }));
        } else if (!isValidPhoneNumber(value)) {
          setErrors(prev => ({ ...prev, [name]: `Please enter valid number` }));
        } else if (value != '') setErrors(prev => ({ ...prev, [name]: '' }));
        break;
      default:
        setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  return (
    <>
      <div className="container">
        <div className="p-2 sm:p-8">
          <h1 className="text-xl font-bold mb-5">Edit Profile</h1>
          <div className="grid grid-rows-1 grid-flow-col gap-4 gridSec">
            <div className="row-span-3  ">
             
           <img
                className="w-32 h-32 ml-4 rounded-full"
                src={
                  preview == '' || profileImage == undefined || profileImage == '' 
                    ? Avatar
                    : preview 
                    ? preview
                    : profileImage && profilePic =='' ?require('../../assets/avatar_user.jpg').default:profileImage
                }
                alt="Rounded avatar"
              />
              <div className="ml-4 mt-6">
                <Input
                  type="file"
                  className="hidden"
                  id="profile"
                  name="profileImg"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleImgChange}
                />
                <label
                  htmlFor="profile"
                  className="px-5 d-inline-flex py-3 w-36 text-white text-button rounded-sm bg-primary hover:bg-opacity-80"
                >
                  Upload Image
                </label>
              </div>
            </div>
            <div className="col-span-3">
              <div className=" grid grid-cols-2 griD-one gap-4">
                <div className="">
                  <label>
                    First Name
                    <RequiredAsterisk />
                  </label>
                  <Input
                    type="text"
                    name="firstName"
                    placeholder="Enter your First Name"
                    value={firstName}
                    onChange={handleChange}
                    onBlur={onChangeError}
                  />
                  {errors && errors.firstName !== '' ? (
                    <p className="text-error font text-sm">{errors.firstName}</p>
                  ) : null}
                </div>
                <div className="">
                  <label>
                    Last Name
                    <RequiredAsterisk />
                  </label>
                  <Input
                    type="text"
                    name="lastName"
                    placeholder="Enter your Last Name"
                    value={lastName}
                    onChange={handleChange}
                    onBlur={onChangeError}
                  />
                  {errors && errors.lastName !== '' ? (
                    <p className="text-error font text-sm">{errors.lastName}</p>
                  ) : null}
                </div>
                <div className="">
                  <label>
                    Email
                    <RequiredAsterisk />
                  </label>
                  <Input
                    type="text"
                    // disabled
                    name="email"
                    placeholder="Enter your Email"
                    value={email}
                    onChange={handleChange}
                    onBlur={onChangeError}
                  />
                </div>
                <div>
                  <label>
                    Mobile Number
                    <RequiredAsterisk />
                  </label>
                  <PhoneInput
                    style={{
                      border: '1px solid #ccc',
                      borderRadius: '3px',
                      padding: '7px 5px',
                    }}
                    label="Mobile"
                    international
                    countryCallingCodeEditable={true}
                    defaultCountry="CA"
                    onChange={setPhone}
                    name="mobile"
                    value={phone}
                    required={true}
                    placeholder="123-456-7890"
                    maxLength="16"
                    minLength="10"
                    onBlur={onChangeError}
                    onKeyPress={onChangeError}
                  />
                  {errors && errors.mobile !== '' ? (
                    <p className="text-error font text-sm">{errors.mobile}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-span-3 text-right">
              <button
                className="px-5 py-3 w-36 text-white text-button rounded-sm bg-primary hover:bg-opacity-80"
                onClick={handleUpdate}
                disabled={isApiCall}
              >
                {isApiCall ? 'Updating...' : 'Update'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
};

EditProfile.propTypes = {
  state: PropTypes.object,
  errors: PropTypes.object,
  phone: PropTypes.string,
  setPhone: PropTypes.func,
  handleChange: PropTypes.func,
  handleImgChange: PropTypes.func,
  handleUpdate: PropTypes.func,
  preview: PropTypes.string,
  profileImage: PropTypes.string,
  isApiCall: PropTypes.bool,
  setErrors: PropTypes.func,
};

export default EditProfile;
