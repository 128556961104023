/* eslint-disable prettier/prettier */
import React from 'react';

const Slogan = () => {
  const mainSlogan = `Now man has a second best friend!`;
  const nestsubSlogan = `Request your trustworthy auto shop to better serve you and become an Auto Hound affiliate.`;
  const subSlogan = `Auto Hound helps manage repair costs to prolong your vehicle's life.  Our goal is to improve your financial well-being, by reducing your lifetime car count.`;

  return (
    <>
      {/* <div className="bg-cover bg-hero bg-height">
        <div className="container pb-40 pt-32">
          <div className="w-3/4 pb-24 pt-20 sm:pt-0 mt-16 mb-8 banner-text">
            <h2 className="font-bold text-white text-h2 text-h2-new">{mainSlogan}</h2>
          </div>
        </div>
      </div> */}
      <div className="bg-cover bg-hero bg-height relative mt--10">
        <div className='for-desktop'>
          <img src={require('../../assets/garagebanner.jpg').default}></img>
        </div>
        <div className="absolute w-100 top-0">
          <div className="container pb-40 pt-32">
            <div className="w-3/4 pb-24 mt-16 mb-8">
              <h2 className="font-bold text-white text-h2 text-h2-new">{mainSlogan}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-black  p-5 ">
        <div className="container">
          <div className="d-flex items-center flex-wrap">
            <div className="sm:w-4/5">
              <p className="w-100 font-bold text-white text-subtitle-1">{subSlogan}</p>
              <p className="w-100 my-2 font-bold text-white text-subtitle-1">{nestsubSlogan}</p>
            </div>
            <div className="sm:w-1/5 text-center">
              <a
                href="#plans"
                className="top--btn float-right font-semibold px-5 text-white py-3 rounded-sm bg-primary bg-transparent text-button hover:text-opacity-80 active:bg-primary-light disabled:bg-disabled"
              >
                Choose a plan
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Slogan;
