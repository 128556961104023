import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { handleErrorMessages, handleSuccessMessages } from '../../../../helpers/flashMessages';

import AdminAPIs from '../../../../api/admin';

import GarageOwnerFields from './garageOwnerFields';

const EditGarageOwner = () => {
  const [error, setError] = useState('');
  const { id } = useParams();
  const [phone, setPhone] = useState('');
  const [owner, setOwner] = useState({});
  const history = useHistory();
  const alert = useAlert();
  const ownerIdFields = ['province', 'postalCode', 'street', 'city', 'notes'];

  useEffect(() => {
    (async () => {
      try {
        const response = await AdminAPIs.getOwner(id);
        const { getOwner } = response.data;
        setPhone(getOwner.mobile);
        setOwner({ ...getOwner, garage: getOwner.ownerId.garage.name });
      } catch (e) {
        // const message = handleErrorMessages(e);
        // setError(message);
      }
    })();
  }, [id]);

  const updateState = e => {
    const key = e.target.name;
    let value = e.target.value;
    const isOwnerId = ownerIdFields.includes(key);
    if (key === 'mobile') {
      setOwner({ ...owner, mobile: phone });
    }
    if (key === 'status') {
      value = value === 'true'; // converts the string into a Boolean value
      setOwner({ ...owner, status: value });
    }
    if (isOwnerId) {
      setOwner({ ...owner, ownerId: { ...owner.ownerId, [key]: value }, [key]: value });
    } else {
      setOwner({ ...owner, [key]: value });
    }
  };
  const onSubmit = async () => {
    try {
      const response = await AdminAPIs.updateGarageName(id, {
        firstName: owner.firstName,
        lastName: owner.lastName,
        mobile: owner.mobile,
        email: owner.email,
        garage: { garageId: owner.ownerId.garage._id, garageName: owner.garage },
        status: owner.status,
        city: owner.city ? owner.city : owner.ownerId.city,
        province: owner.province ? owner.province : owner.ownerId.province,
        street: owner.street ? owner.street : owner.ownerId.street,
        postalCode: owner.postalCode ? owner.postalCode : owner.ownerId.postalCode,
        notes: owner.notes ? owner.notes : owner.ownerId.notes,
      });
      if (response.status === 200) {
        const userName = JSON.parse(localStorage.getItem('usersName'));
        const userNameIndex = userName.findIndex(data => data.id === response.data.updatedOwner[0]._id);
        if (userNameIndex !== -1) {
          userName[userNameIndex].firstName = response.data.updatedOwner[0].firstName;
          userName[userNameIndex].lastName = response.data.updatedOwner[0].lastName;
          userName[userNameIndex].email = response.data.updatedOwner[0].email;
          userName[userNameIndex].mobile = response.data.updatedOwner[0].mobile;
          localStorage.setItem('usersName', JSON.stringify(userName));
        } else {
          let newArr = [
            ...userName,
            {
              id: response.data.updatedOwner[0]._id,
              firstName: response.data.updatedOwner[0].firstName,
              lastName: response.data.updatedOwner[0].lastName,
              email: response.data.updatedOwner[0].email,
              mobile: response.data.updatedOwner[0].mobile,
            },
          ];
          localStorage.setItem('usersName', JSON.stringify(newArr));
        }
      }
      history.push('/admin/garage-owner');
      handleSuccessMessages(response, alert);
    } catch (error) {
      const message = handleErrorMessages(error, alert);
      setError(message);
    }
  };

  return (
    <GarageOwnerFields
      operation="edit"
      updateState={updateState}
      setOwner={setOwner}
      owner={owner}
      submitHandler={onSubmit}
      data={owner}
      phone={phone}
      setPhone={setPhone}
    />
  );
};

export default EditGarageOwner;
