/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import OnePage from '../../../components/Pages/OnePage';
import TextInput from '../../../components/TextInput';
import NumberInput from '../../../components/NumberInput';
import CurrencyInput from '../../../components/CurrencyInput';
import CurrencyTotalAmountInput from '../../../components/CurrencyTotalAmountInput'
import DateInput from '../../../components/DateInput';
import FormHeader from '../../../components/FormHeader';
import Row from '../../../components/Row';
import SelectInput from '../../../components/SelectInput';
import TextArea from '../../../components/TextArea';
import { BasicError } from '../../../components/Error';
import { Modal, Button } from 'antd';
import { useAlert } from 'react-alert';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { handleErrorMessages } from '../../../helpers/flashMessages';
import { validationcommon, keyupValidation } from '../../../helpers/validationMessages';

import { isCurrentUserABusinessUser, isCurrentUserACustomer, isCurrentUserAnAdmin, isCurrentUserAnOwner } from '../../../utilities/authentication';

const InvoiceFields = ({ cars, operation, invoice, handler, title, oilChange }) => {
  const history = useHistory()
   
  const initialInvoiceState = {
    oilChange: oilChange ?? 0,
    date: invoice?.date ?? '',
    number: invoice?.number ?? '',
    mileage: invoice?.mileage ?? '',
    type: invoice?.type ?? 'Autohound Repairs',
    repairDescription: invoice?.repairDescription ?? '',
    totalAmount: invoice?.totalAmount ? Math.floor(invoice?.totalAmount * 100) / 100 : operation === 'add'  ? '' :'0',
    discount: invoice?.discount ? Math.floor(invoice?.discount * 100) / 100 : '',
    oilChangeDiscount: invoice?.oilChangeDiscount ? Math.floor(invoice?.oilChangeDiscount * 100) / 100 : '',
  };
  const [invoiceData, setInvoiceData] = useState(initialInvoiceState);
  const [chosenCar, setChosenCar] = useState('');
  const [previousCar, setPreviousCar] = useState('');
  const isValid = isCurrentUserAnAdmin() || isCurrentUserAnOwner();
  const isEditable = (isValid && operation === 'edit') ||  operation === 'add';
  const { confirm } = Modal;
  const alert = useAlert();

  const typesOfWork = [
    'Autohound Repairs',
    'Initial Vehicle Purchase',
    'Other Garage Repairs',
    'Body Repair',
    'Performance Enhancements',
  ];

  const formRequirements = {
    repairDescription: [...typesOfWork],
    totalAmount: [...typesOfWork],
    serviceDate: [...typesOfWork],
    invoiceNumber: ['Autohound Repairs'],
    mileage: ['Autohound Repairs'],
  };
  const [errors, setErrors] = useState({
    car: '',
    type: '',
    date: '',
    number: '',
    mileage: '',
    oilChange: '',
    repairDescription: '',
    totalAmount: '',
  });

  const handleErrors = () => {
    let err = { ...errors };
    if (!chosenCar) err.car = '* Choose the car';
    else if(chosenCar =='Please Select') err.car = '* Choose the car';
    else if (chosenCar) err.car = '';
    if (invoiceData.type == '') err.type = '* Choose the type of invoice';
    if (invoiceData.date == '') err.date = '* Select the date';

    if (invoiceData.repairDescription == '') err.repairDescription = '* Enter repair description';
    if (invoiceData.totalAmount === '') err.totalAmount = '* Enter TotalAmount';  
    if(invoiceData.type =='Please Select')   err.type = '* please select invoice'; 
    if (invoiceData.type == 'Autohound Repairs') {
      if (invoiceData.number == '') err.number = '* Enter invoice number';
      if (invoiceData.mileage == '') err.mileage = '* Enter mileage';
     // if (invoiceData.oilChange == '') err.oilChange = '* Enter oil change count';
    } else if (invoiceData.type != 'Autohound Repairs') {
      err.number = '';
      err.mileage = '';
      err.oilChange = '';
    }

    setErrors(err);
    return err
  };
  const onChangeError = (event) => {
   
    
    const { name , value} = event.target;
    if(name ==  'invoiceNumber')
    {
      if (value == '') setErrors((prev) => ({ ...prev, [name]: `* Enter invoice number` }));
      else if (value != '') setErrors((prev) => ({ ...prev, [name]: '' }))
    }
    if( name == 'mileage')
    {
      if (value == '') setErrors((prev) => ({ ...prev, [name]: `* Enter mileage` }));
      else if (value != '') setErrors((prev) => ({ ...prev, [name]: '' }))
    }
    if( name =='totalAmount')
    {
      if (value == '') setErrors((prev) => ({ ...prev, [name]: `* Enter TotalAmount` }));
      else if (value != '') setErrors((prev) => ({ ...prev, [name]: '' }))
    }
    if( name =='car')
    {
      if (value == '') setErrors((prev) => ({ ...prev, [name]: `* Choose the car` }));
      if (value =='Please Select') setErrors((prev) => ({ ...prev, [name]: `* Choose the car` }));
      else if (value != '') setErrors((prev) => ({ ...prev, [name]: '' }))
    }
    if( name =='type')
    {
      if (value == '') setErrors((prev) => ({ ...prev, [name]: `* Please select invoices` }));
      if (value =='Please Select') setErrors((prev) => ({ ...prev, [name]: `* Please select invoices` }));
      else if (value != '') setErrors((prev) => ({ ...prev, [name]: '' }))
    }
  };
  

  useEffect(() => {
    if (operation === 'edit') {
      const invoiceToSearchFor = invoice._id;
      const chosenCar = cars.filter(car => car.invoices.includes(invoiceToSearchFor))[0];
      setPreviousCar(chosenCar._id);

      setChosenCar(chosenCar._id);
    }
  }, [cars, invoice?._id, invoiceData?.date, operation]);

  const { userId } = useParams();

  const setRequirement = requirementsArr => {
    return requirementsArr.includes(invoiceData.type);
  };

  const updateState = (e, type) => {
    const key = e.target.name;
    let value = e.target.value;
    
    const keyName = ['oilChangeDiscount', 'discount', 'totalAmount'].includes(key);
    
    if (keyName) {
      value =
        value.indexOf('.') >= 0
          ? value.substr(0, value.indexOf('.')) + value.substr(value.indexOf('.'), 3)
          : value;
    }
    
    setInvoiceData({ ...invoiceData, [key]: value });
    let error = keyupValidation(e, type);
    if (error) {
      setErrors({ ...errors, [key]: error });
      return error;
    } else {
      setErrors({ ...errors, [key]: '' });
      return error;
    }
  };

  const handleMinDate = () => {
    if (chosenCar != '') {
      const chosenVehicleYear = cars.filter(car => car._id == chosenCar).map(car => car.yearManufactured);
      return chosenVehicleYear[0];
    } else {
      return '';
    }
  };
  const submitHandler = e => {
    e.preventDefault();
     const validation = handleErrors();
     
 if(validation.car !== '' || validation.type == '* please select invoice')
 {
  return 
 }
    if (invoiceData.type == 'Autohound Repairs') {
      if (
        (invoiceData.type &&
          invoiceData.mileage &&
          invoiceData.number.trim() &&
          invoiceData.oilChange.toString() &&
          invoiceData.repairDescription &&
          invoiceData.totalAmount.toString() &&
          invoiceData.date) != '' &&
        errors.car=='' &&
        errors.date=='' &&
        errors.mileage =='' &&
        errors.number ==''&&
        errors.oilChange  ==''&&
        errors.repairDescription =='' &&
        errors.totalAmount =='' &&
        errors.type  ==''
      ) {
        if (
          invoiceData.type != 'Initial Vehicle Purchase' &&
          invoiceData.totalAmount.toString().includes('-')
        ) {
          return handleErrorMessages({ customMessage: 'Total amount can not be less than 0' }, alert);
        }
        
        handler(chosenCar, previousCar, invoiceData);
      }
    } else if (invoiceData.type != 'Autohound Repairs') {
      if (
        (invoiceData.type && invoiceData.repairDescription && invoiceData.totalAmount && invoiceData.type) !=
          '' &&
        (errors.car && errors.date && errors.repairDescription && errors.totalAmount && errors.type) == ''
      ) {
        if (
          invoiceData.type != 'Initial Vehicle Purchase' &&
          invoiceData.totalAmount.toString().includes('-')
        ) {
          return handleErrorMessages({ customMessage: 'Total amount can not be less than 0' }, alert);
        }
        
        handler(chosenCar, previousCar, invoiceData);
      }
    }
  };

  function showDeleteConfirm(value) {
    confirm({
      title: 'Are you sure ?',
      icon: <ExclamationCircleOutlined />,
      content: 'These changes will effect invoice data',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        setChosenCar(value);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  const handleOnCancelClick = e => {
    e.preventDefault();
    if(isCurrentUserACustomer() || isCurrentUserAnOwner() || isCurrentUserABusinessUser())  {
      history.push(`/customer/${userId}`)
    }
  }
  return (
    <>
      {cars.length > 0 ? (
        <OnePage background="background">
         
          <div className="px-0 sm:px-5 md:px-20 pt-10 h-hero" key={oilChange}>
            <div className="flex flex-col h-auto px-2 w-100">
              <FormHeader title={title} backLink={`/customer/${userId}`} />
              {!isValid && invoiceData.type === 'Autohound Repairs' && (
                <BasicError customError="You do not have the authority to modify an Autohound invoice. Please choose a different type of invoice." />
              )}
              <form
                className="flex flex-col px-5 py-6 bg-white flex-wrap app_form"
                onSubmit={e => submitHandler(e)}
                noValidate
              >
                <Row className="grid gap-4 grid-cols-1 md:grid-cols-3 sm:grid-cols-2 lg:grid-cols-4">
                  <div>
                  
                    <SelectInput
                      label="Vehicle worked on"
                      name="car"
                      value={chosenCar}
                      onChange={e => {
                        if (operation === 'edit') showDeleteConfirm(e.target.value);
                        else {
                          setChosenCar(e.target.value);
                          setErrors({ ...errors, car: '' });
                        }
                      }}
                      required={true}
                      defaultOption="Select a car"
                      onBlur={onChangeError}
                    >
                      {cars.map((car, i) => (
                        <option
                          key={i.toString()}
                          value={car._id}
                          className={car.active ? 'active' : 'inactive'}
                        >
                          {car.plateNumber} / {car.yearManufactured} / {car.make} / {car.model}
                        </option>
                      ))}
                    </SelectInput>
                    {errors && errors.car !== '' ? (
                      <p className="text-error font text-sm">{errors.car}</p>
                    ) : null}
                  </div>
                  <div>
                    <SelectInput
                      label="Type of Invoice"
                      name="type"
                      value={invoiceData.type}
                      onChange={e => updateState(e, 'type')}
                      required={true}
                      defaultOption="Select type of invoice"
                      disabled={!isEditable && invoiceData.type === 'Autohound Repairs'}
                          onBlur={onChangeError}
                      
                    >
                      {typesOfWork.map((work, i) => (
                        <option key={i.toString()} value={work}>
                          {work}
                        </option>
                      ))}
                    </SelectInput>
                    {errors && errors.type !== '' ? (
                      <p className="text-error font text-sm">{errors.type}</p>
                    ) : null}
                  </div>
                  <div>
                    <DateInput
                      label="Service Date"
                      name="date"
                      value={moment(invoiceData.date).format('YYYY-MM-DD')}
                      onChange={e => updateState(e, 'date')}
                      required={true}
                      // required={setRequirement(formRequirements.serviceDate)}
                      min={handleMinDate()}
                    />
                    {errors && errors.date !== '' ? (
                      <p className="text-error font text-sm">{errors.date}</p>
                    ) : null}
                  </div>
                  <div>
                    <TextInput
                      label="Invoice Number"
                      name="number"
                      maxLength="20"                      
                      onChange={e => updateState(e, 'number')}
                      value={invoiceData.number}
                      required={setRequirement(formRequirements.invoiceNumber)}
                      placeholder='eg:2.0'
                      onBlur={onChangeError}
                    />
                    {invoiceData.type == 'Autohound Repairs' && errors && errors.number !== '' ? (
                      <p className="text-error font text-sm">{errors.number}</p>
                    ) : null}
                  </div>
                  <div>
                    <NumberInput
                      label="Mileage"
                      name="mileage"
                      onChange={e => updateState(e, 'mileage')}
                      value={invoiceData.mileage}
                      required={setRequirement(formRequirements.mileage)}
                      placeholder="eg:2"
                    />
                    {invoiceData.type == 'Autohound Repairs' && errors && errors.mileage !== '' ? (
                      <p className="text-error font text-sm">{errors.mileage}</p>
                    ) : null}
                  </div>
               
                  {invoiceData.type === 'Autohound Repairs' && (
                    <>
                      <div>
                        {<SelectInput
                          label="Current Service Count"
                          name="oilChange"
                          value={invoiceData?.oilChange.toString()}
                          onChange={e => updateState(e, 'oilChange')}
                          required={true}
                          defaultOption="Select current oil change count"
                          disabled={!isEditable && invoiceData.type === 'Autohound Repairs'}
                        >
                          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((count, i) => (
                            <option key={i.toString()} value={count}>
                              {count}
                            </option>
                          ))}
                        </SelectInput>}
                        {/*<NumberInput
                          label="Current Service Count"
                          name="oilChange"
                          onChange={e => updateState(e, 'oilChange')}
                          value={invoiceData.oilChange}
                          required={true}
                          disabled={!isEditable && invoiceData.type === 'Autohound Repairs'}
                          max={10}
                          min={0} */}
                        
                        {invoiceData.type == 'Autohound Repairs' && errors && errors.oilChange !== '' ? (
                          <p className="text-error font text-sm">{errors.oilChange}</p>
                        ) : null}
                      </div>
                      {isValid && (
                        <CurrencyInput
                          label="Service Discount"
                          onChange={e => updateState(e, 'oilChangeDiscount')}
                          value={invoiceData.oilChangeDiscount}
                          name="oilChangeDiscount"
                          step="0.01"
                        />
                      )}
                      {/* {invoiceData.oilChange == 10 && (
                        <SelectInput
                          label="Free oil change?"
                          name="freeOilChange"
                          value={invoiceData.freeOilChange}
                          onChange={e => updateState(e)}
                          required={false}
                          defaultOption="Choose Yes or No"
                          disabled={!isEditable && invoiceData.type === 'Autohound Repairs'}
                        >
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </SelectInput>
                      )} */}
                    </>
                  )}
                  <div className="flex flex-col w-full col-span-full">
                    <TextArea
                      label="Repair Description"
                      name="repairDescription"
                      onChange={e => updateState(e, 'repairDescription')}
                      value={invoiceData.repairDescription}
                      required={setRequirement(formRequirements.repairDescription)}
                      placeholder='Repair Description'
                      onBlur={onChangeError}
                    />
                    {errors && errors.repairDescription !== '' ? (
                      <p className="text-error font text-sm">{errors.repairDescription}</p>
                    ) : null}
                  </div>
                  {isValid && (
                    <CurrencyTotalAmountInput
                      label="Discounts Applied"
                      onChange={e => updateState(e)}
                      value={invoiceData.discount}
                      name="discount"
                      // step="0.01"
                      placeholder='Discounts Applied'
                    />
                  )}
                  <div>
                                        <CurrencyTotalAmountInput
                      label="Total Amount"
                      onChange={e => updateState(e, 'totalAmount')}
                      value={invoiceData.totalAmount.toString()}
                      name="totalAmount"
                      required={ setRequirement(formRequirements.totalAmount)}
                      // step="0.01"
                      placeholder='Total amount'
                      onBlur={onChangeError}
                    />
                    {errors && errors.totalAmount !== '' ? (
                      <p className="text-error font text-sm">{errors.totalAmount}</p>
                    ) : null}
                  </div>
                </Row>
                <div className="text-right">
                <button
                onClick={handleOnCancelClick}
                className="px-5 py-3 mt-5 mx-2 text-white rounded-sm w-36 text-button bg-primary hover:bg-opacity-80"
              >
                Cancel
              </button>
                  <button
                    type="submit"
                    className={`px-5 py-3 mt-5 w-36 text-white text-button rounded-sm hover:bg-opacity-80 ${
                      !isValid && invoiceData.type === 'Autohound Repairs'
                        ? 'bg-gray-2 hover:bg-opacity-100 cursor-default'
                        : 'bg-primary'
                    }`}
                    disabled={!isValid && invoiceData.type === 'Autohound Repairs'}
                  >
                    {operation === 'add' ? 'Add Invoice' : 'Save Changes'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </OnePage>
      ) : (
        <h1>error</h1>
      )}
    </>
  );
};

InvoiceFields.propTypes = {
  cars: PropTypes.array,
  garages: PropTypes.array,
  title: PropTypes.string,
  operation: PropTypes.string,
  handler: PropTypes.func,
  invoice: PropTypes.object,
  id: PropTypes.string,
  oilChange: PropTypes.number,
  discount: PropTypes.number,
  oilChangeDiscount: PropTypes.number,
  initialCost: PropTypes.number,
};

export default InvoiceFields;