/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { handleErrorMessages, handleSuccessMessages } from '../../../helpers/flashMessages';

import AdminAPIs from '../../../api/admin';

import VehicleFields from './vehicleFields';
const AddAdminVehicle = () => {
  const [error, setError] = useState(null);
  const history = useHistory();
  const alert = useAlert();

  const addVehicle = async (body, id) => {
    body.make = body.make.trim();
    body.model = body.model.trim();
    try {
      const response = await AdminAPIs.addVehicle(body, id);
      handleSuccessMessages(response, alert);
      history.push(`/admin/vehicles`);
    } catch (error) {
      const message = handleErrorMessages(error, alert);
      setError(message);
      console.error(message);
    }
  };

  return (
    <VehicleFields title="Add A Vehicle" operation="add" submitHandler={addVehicle} parentError={error} />
  );
};

export default AddAdminVehicle;
