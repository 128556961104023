/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import OnePage from '../../../components/Pages/OnePage';
import TextInput from '../../../components/TextInput';
import NumberInput from '../../../components/NumberInput';
import CurrencyInput from '../../../components/CurrencyInput';
import CurrencyTotalAmountInput from '../../../components/CurrencyTotalAmountInput'
import DateInput from '../../../components/DateInput';
import FormHeader from '../../../components/FormHeader';
import Row from '../../../components/Row';
import SelectInput from '../../../components/SelectInput';
import SelectCarUserInput from '../../../components/SelectCarUserInput'
import TextArea from '../../../components/TextArea';
import { BasicError } from '../../../components/Error';
import { Modal, Button } from 'antd';
import { useAlert } from 'react-alert';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { handleErrorMessages } from '../../../helpers/flashMessages';
import { validationcommon, keyupValidation } from '../../../helpers/validationMessages';
import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import VehicleAPIs from '../../../api/vehicle';
import AdminAPIs from '../../../api/admin';

import { isCurrentUserABusinessUser, isCurrentUserACustomer, isCurrentUserAnAdmin, isCurrentUserAnOwner } from '../../../utilities/authentication';

const AdminInvoiceFields = ({
  cars,
  title,
  operation,
  handler,
  data,
  updateState,
  setAdminInvoice,
  adminInvoice,
  errors,
  setErrors
  }) => {
      
  const history = useHistory();
  
  // const [vehicles, setVehicles] = useState([]);
  // const initialInvoiceState = {
  //   oilChange: oilChange ?? 0,
  //   date: invoice?.date ?? '',
  //   number: invoice?.number ?? '',
  //   mileage: invoice?.mileage ?? '',
  //   type: invoice?.type ?? '',
  //   repairDescription: invoice?.repairDescription ?? '',
  //   totalAmount: invoice?.totalAmount ? Math.floor(invoice?.totalAmount * 100) / 100 : '',
  //   discount: invoice?.discount ? Math.floor(invoice?.discount * 100) / 100 : '',
  //   oilChangeDiscount: invoice?.oilChangeDiscount ? Math.floor(invoice?.oilChangeDiscount * 100) / 100 : '',
  // };
  // const [invoiceData, setInvoiceData] = useState(initialInvoiceState);
  // const [carUser, setCarUser] = useState('');
  //const [chosenCar, setChosenCar] = useState('');
  const [previousCar, setPreviousCar] = useState('');
  const isValid = isCurrentUserAnAdmin() || isCurrentUserAnOwner();
  const isEditable = (isValid && operation === 'edit') || operation === 'add';
  // const { confirm } = Modal;
  const [nameOptions, setNameOptions] = useState([])
  const alert = useAlert();

  const typesOfWork = [
    'Autohound Repairs',
    'Initial Vehicle Purchase',
    'Other Garage Repairs',
    'Body Repair',
    'Performance Enhancements',
  ];

  const formRequirements = {
    repairDescription: [...typesOfWork],
    totalAmount: [...typesOfWork],
    serviceDate: [...typesOfWork],
    invoiceNumber: ['Autohound Repairs'],
    mileage: ['Autohound Repairs'],
  };
  // const [errors, setErrors] = useState({
  //   carUser: '',
  //   car: '',
  //   type: '',
  //   date: '',
  //   mileage: '',
  //   oilChange: '',
  //   repairDescription: '',
  //   totalAmount: '',
  //   invoiceType: ''
  // });

  // const results = localStorage.getItem('usersName');
  // var names = [JSON.parse(results) || []];
  // const nameOptions = names[0].map((items, i) => {
  //   return { label: [items.firstName + ' ' + items.lastName], value: items.id };
  // });

  // const getAllVehicles = useCallback(async () => {
  //   if(data.carUser){
  //     const userVehicles = await VehicleAPIs.getCustomerVehicles(data.carUser);
  //     if(userVehicles.data.data.userWithVehicles){
  //       const vehiclesList = userVehicles.data.data.userWithVehicles.customerId.vehicles
  //       setVehicles(vehiclesList);
  //     }
  //   }
  // }, [data.carUser]);

  // useEffect(() => {
  //   getAllVehicles();
  // }, [getAllVehicles])


  // const handleSubmit = e => {
  //   e.preventDefault();
  //     submitHandler(data);
  // };
  const onChange = name => {
    data.garage = name;
  };

  const onSearch = value => {
    // console.log('search:', value);
  };

  const handleErrors = () => {
    let err = { ...errors };
    
    if (!data.carUser) err.carUser = '* Choose the car user';
    if(data.carUser === 'Please Select') err.car = '* Choose the car';
    else if (data.carUser) err.carUser = '';
    if (!data.car) err.car = '* Choose the car';
    if(data.car === 'Please Select') err.car = '* Choose the car';
    else if (data.car) err.car = '';
    if (data.invoiceType == '') err.invoiceType = '* Choose the type of invoice';
     if(data.invoiceType == '* Please select invoice.') err.invoiceType = '* Choose the type of invoice';
    if (data.date == '') err.date = '* Select the date';
     if (data.date !== '') err.data ='';
     if(data.invoiceType == 'Autohound Repairs' || data.invoiceType == 'Initial Vehicle Purchase' ||
     data.invoiceType == 'Other Garage Repairs' || data.invoiceType == 'Body Repair' || data.invoiceType == 'Performance Enhancements' )
     {
     if (data.repairDescription == '') err.repairDescription = '* Enter repair description';
      else if(data.repairDescription !== '') err.repairDescription ='';
       
      if (data.totalAmount === '') err.totalAmount = '* Enter total amount';
      else if (data.totalAmount !== '') err.totalAmount = '';
     }
    if (data.invoiceType == 'Autohound Repairs') {
      if (data.repairDescription == '') err.repairDescription = '* Enter repair description';
      if(data.repairDescription !== '') err.repairDescription ='';
       
      if (data.totalAmount === '') err.totalAmount = '* Enter total amount';
      if (data.totalAmount !== '') err.totalAmount = '';
      if (data.invoiceNumber == '') err.invoiceNumber = '* Enter invoice number';
      if(data.invoiceNumber !== '') err.invoiceNumber ='';
      if (data.mileage == '') err.mileage = '* Enter mileage';
      if(data.mileage !== '') err.mileage ='';
      if (data.oilChange == '') err.oilChange = '* Enter oil change count';
      else if(data.oilChange =='Please Select') err.oilChange = '* Enter oil change count';
      if(data.oilChange !=='') err.oilChange = '';

    } else if (data.invoiceType != 'Autohound Repairs') {
      err.invoiceNumber = '';
      err.mileage = '';
      err.oilChange = '';
    }
    setErrors(err);
  };

  useEffect(() => {
    if (operation === 'edit') {
      
      const invoiceToSearchFor = data._id;
      const chosenCar = cars.filter(car => car.invoices.includes(invoiceToSearchFor))[0];
      setPreviousCar(chosenCar ? chosenCar._id : '');
    }
  }, [cars, data._id, operation]);

  useEffect(() => {
    (async () => {
      try {
        const response = await AdminAPIs.getCustomersName();
        const { usersName } = response.data;
        setNameOptions(usersName);
      } catch (e) {
        console.error('error in catch', e);
      }
    })();
  }, []);

  // const { userId } = useParams();

  const setRequirement = requirementsArr => {
    return requirementsArr.includes(data.invoiceType);
  };

  // const updateState = (e, type) => {
  //   const key = e.target.name;
  //   let value = e.target.value;
  //   const keyName = ['oilChangeDiscount', 'discount', 'totalAmount'].includes(key);
  //   if (keyName) {
  //     value =
  //       value.indexOf('.') >= 0
  //         ? value.substr(0, value.indexOf('.')) + value.substr(value.indexOf('.'), 3)
  //         : value; 
  //   }
  //   setInvoiceData({ ...invoiceData, [key]: value });
  //   let error = keyupValidation(e, type);
  //   if (error) {
  //     setErrors({ ...errors, [key]: error });
  //     return error;
  //   } else {
  //     setErrors({ ...errors, [key]: '' });
  //     return error;
  //   }
  // };

  const handleMinDate = () => {
    if (data.car != '') {
      const chosenVehicleYear = cars.filter(vehicle => vehicle._id == data.car).map(vehicle => vehicle.yearManufactured);
      return chosenVehicleYear[0];
    } else {
      return '';
    }
  };
  const onChangeError = (event)=>{
  
    const { name , value} = event.target; 
    if(name ==  'invoiceNumber')
    {
      if (value == '') setErrors((prev) => ({ ...prev, [name]: `* Enter invoice number` }));
      else if (value != '') setErrors((prev) => ({ ...prev, [name]: '' }))
    }
    if( name == 'mileage')
    {
      if (value == '') setErrors((prev) => ({ ...prev, [name]: `* Enter mileage` }));
      else if (value != '') setErrors((prev) => ({ ...prev, [name]: '' }))
    }
    if( name =='totalAmount')
    {
      if (value == '') setErrors((prev) => ({ ...prev, [name]: `* Enter TotalAmount` }));
      else if (value != '') setErrors((prev) => ({ ...prev, [name]: '' }))
    }
    if( name =='oilChange')
    {
      if (value == '') setErrors((prev) => ({ ...prev, [name]: `* Please select oilChange` }));
      if (value =='Please Select') setErrors((prev) => ({ ...prev, [name]: `* Please select oilChange` }));
      else if (value != '') setErrors((prev) => ({ ...prev, [name]: '' }))
    }

    if( name =='invoiceType')
    {
      if (value == '') setErrors((prev) => ({ ...prev, [name]: `* Please select invoice.` }));
      if (value =='Please Select') setErrors((prev) => ({ ...prev, [name]: `* Please select invoice.` }));
      else if (value != '') setErrors((prev) => ({ ...prev, [name]: '' }))
    }

    if( name =='car')
    {
      if (value == '') setErrors((prev) => ({ ...prev, [name]: `* Choose the car` }));
      if (value =='Please Select') setErrors((prev) => ({ ...prev, [name]: `* Choose the car` }));
      else if (value != '') setErrors((prev) => ({ ...prev, [name]: '' }))
    }
    
    
    // if( name =='oilChange')
    // {
    //   if (value == '') setErrors((prev) => ({ ...prev, [name]: `* Please select oilChange` }));
    //   if (value =='Please Select') setErrors((prev) => ({ ...prev, [name]: `* Please select oilChange` }));
    //   else if (value != '') setErrors((prev) => ({ ...prev, [name]: '' }))
    // }


  }

  const handleDateError=(data)=>{
  
    if(data.oilChange == undefined || data.oilChange == null)
    {
      return data.oilChange = ''
    }
    
   else if( data?.oilChange?.toString() !== '')
   {
     return data
   }
   else{
     return data.oilChange = ''
   }
   
  }
  const submitHandler = e => {
    e.preventDefault();
    
     if(data.invoiceType == 'Autohound Repairs')
     {
      handleDateError(data);
      if(errors.oilChange =="* Please select oilChange")
      {
       return
      }
     

     }
    
    handleErrors();
    if( errors.invoiceType == '* Please select invoice.' || errors.car == "* Choose the car")
    {
      return 
    }

    try {
      if (data.invoiceType == 'Autohound Repairs') {
           
        if (
          (data.car &&
            data.carUser &&
            data.invoiceType &&
            data.mileage &&
            data.invoiceNumber.trim() &&
            data.oilChange.toString() &&
            data.repairDescription &&
            data.totalAmount.toString() &&
            data.date) != '' &&
          (errors.carUser &&
          errors.car  &&
          errors.date  && 
          errors.mileage &&
          errors.invoiceNumber  &&
          errors.oilChange  &&
          errors.repairDescription &&
          errors.totalAmount  &&
          errors.invoiceType) == ''
        ) {
          if (
            data.invoiceType != 'Initial Vehicle Purchase' &&
            data.totalAmount.toString().includes('-')
          ) {
            return handleErrorMessages({ customMessage: 'Total amount can not be less than 0' }, alert);
          }
        
          let   invoicePropsData = {
            type: data.invoiceType,
            date: data.date,
            number: data.invoiceNumber,
            mileage: data.mileage,
            repairDescription: data.repairDescription,
            discount: data.discount,
            totalAmount: data.totalAmount,
            oilChangeDiscount: data?.oilChangeDiscount
          };
          
          handler(data.carUser, data.car, previousCar, invoicePropsData,data?.oilChange);
        }
      } else if (data.invoiceType != 'Autohound Repairs') {
      
        if (
          (data.invoiceType &&
            data.repairDescription &&
            data.totalAmount.toString() &&
            data.invoiceType) != '' &&
          (errors.carUser,
          errors.car &&
            errors.date &&
            errors.repairDescription &&
            errors.totalAmount &&
            errors.invoiceType) == ''
        ) {
          if (
            data.invoiceType != 'Initial Vehicle Purchase' &&
            data.totalAmount.toString().includes('-')
          ) {
            return handleErrorMessages({ customMessage: 'Total amount can not be less than 0' }, alert);
          }
 
          const invoicePropsData = {
            type: data.invoiceType,
            date: data.date,
            number: data.invoiceNumber,
            mileage: data.mileage,
            repairDescription: data.repairDescription,
            discount: data.discount,
            totalAmount: data.totalAmount,
          };
        
          handler(data.carUser, data.car, previousCar, invoicePropsData);
        }
      }
    } catch (error) {
      
      const message = handleErrorMessages(error, alert);
      setErrors(message);
    }
  };


  // const submitHandler = e => {
  //   e.preventDefault();
  //   handleErrors();
  //   if (invoiceData.type == 'Autohound Repairs') {
  //     if (
  //       (invoiceData.type &&
  //         invoiceData.mileage &&
  //         invoiceData.number.trim() &&
  //         invoiceData.oilChange &&
  //         invoiceData.repairDescription &&
  //         invoiceData.totalAmount &&
  //         invoiceData.date) != '' &&
  //       errors.carUser == '' &&
  //       errors.car == '' &&
  //       errors.date == '' &&
  //       errors.mileage == '' &&
  //       errors.number == '' &&
  //       errors.oilChange == '' &&
  //       errors.repairDescription == '' &&
  //       errors.totalAmount == '' &&
  //       errors.type == ''
  //     ) {
  //       if (
  //         invoiceData.type != 'Initial Vehicle Purchase' &&
  //         invoiceData.totalAmount.toString().includes('-')
  //       ) {
  //         return handleErrorMessages({ customMessage: 'Total amount can not be less than 0' }, alert);
  //       }
  //       handler(carUser, chosenCar, previousCar, invoiceData);
  //     }
  //   } else if (invoiceData.type != 'Autohound Repairs') {
  //     if (
  //       (invoiceData.type && invoiceData.repairDescription && invoiceData.totalAmount && invoiceData.type) !=
  //         '' &&
  //       (errors.carUser, errors.car && errors.date && errors.repairDescription && errors.totalAmount && errors.type) == ''
  //     ) {
  //       if (
  //         invoiceData.type != 'Initial Vehicle Purchase' &&
  //         invoiceData.totalAmount.toString().includes('-')
  //       ) {
  //         return handleErrorMessages({ customMessage: 'Total amount can not be less than 0' }, alert);
  //       }
  //       handler(carUser, chosenCar, previousCar, invoiceData);
  //     }
  //   }
  // };

  // function showDeleteConfirm(value) {
  //   confirm({
  //     title: 'Are you sure ?',
  //     icon: <ExclamationCircleOutlined />,
  //     content: 'These changes will effect invoice data',
  //     okText: 'Yes',
  //     okType: 'danger',
  //     cancelText: 'No',
  //     onOk() {
  //       setChosenCar(value);
  //     },
  //     onCancel() {
  //       console.log('Cancel');
  //     },
  //   });
  // }
  const handleOnCancelClick = e =>{
    e.preventDefault();
    history.push("/super-admin/invoices")
  }

  return (
    <>
      <div className="filter--outer mb-4">
        <div className="d-flex top--heading1">
          <h2 className="text-2xl font-semibold text-gr  ay-700 dark:text-gray-200">
            <Link to="/super-admin/invoices">
              <FiArrowLeft className="inline-block align-middle mr-2 text-base" />
              <p className="inline-block align-middle">{operation === 'add' ? 'Add Invoice' : 'Edit Invoice'}</p>
            </Link>
          </h2>
        </div>
      </div>
      <div className="FormWrapper">
        <form className="flex flex-col px-5 py-6 bg-white app_form" onSubmit={e => submitHandler(e)}>
          <Row className="grid gap-4 grid-cols-1 md:grid-cols-3 sm:grid-cols-2 lg:grid-cols-4">
          {operation =='add' ? ( <>
            <div>
              <SelectInput
                label="Car User"
                name="carUser"
                value={data.carUser}
                onChange={e => {
                  // if (operation === 'edit') showDeleteConfirm(e.target.value);
                  // else {
                    updateState(e, 'carUser')
                    setErrors({ ...errors, carUser: '' });
                  // }
                }}
                required={true}
                onBlur={onChangeError}
              // onkeypress={onChangeError}
                defaultOption="Select a car user"
              >
                {nameOptions.map((customer, i) => (
                  <option
                  key={i.toString()}
                  value={customer.id}
                  >
                    {_.startCase(customer.firstName).concat(' ').concat(customer.lastName)}
                  </option>
                ))}
              </SelectInput>
              {errors && errors.carUser !== '' ? (
                <p className="text-error font text-sm">{errors.carUser}</p>
              ) : null}
            </div>
          </>) :(<>
            <div>
              <SelectCarUserInput
                label="Car User"
                name="carUser"
                value={data.carUser}
                onChange={e => {
                  // if (operation === 'edit') showDeleteConfirm(e.target.value);
                  // else {
                    updateState(e, 'carUser')
                    setErrors({ ...errors, carUser: '' });
                  // }
                }}
                required={true}
                onBlur={onChangeError}
              // onkeypress={onChangeError}
                defaultOption="Select a car user"
              >
            
                {nameOptions.map((customer, i) => (
                <>
                { customer.id == data.carUser ?   <option
                  key={i.toString()}
                  value={customer.id}
                  >
                    {_.startCase(customer.firstName).concat(' ').concat(customer.lastName)}
                  </option>:''}
                
                  </>
                ))}
              </SelectCarUserInput>
              {errors && errors.carUser !== '' ? (
                <p className="text-error font text-sm">{errors.carUser}</p>
              ) : null}
            </div>
          
          
          
          </>)}
           
            <div>
            
              <SelectInput
                label="Vehicle worked on"
                name="car"
                value={data.car}
                disabled={data.carUser === ""}
                onChange={e => {
                  // if (operation === 'edit') showDeleteConfirm(e.target.value);
                  // else {
                    updateState(e, 'car');
                    setErrors({ ...errors, car: '' });
                  // }
                }}
                onBlur={onChangeError}
                // onkeypress={onChangeError}
                required={true}
                defaultOption="Select a car"
              >
                {/* {vehicles.map((user, i) => (
                  <option
                  key={i.toString()}
                  value={user._id}
                  >
                    {user.label[0]}
                  </option>
                ))} */}
                {cars.map((car, i) => (
                  <option
                    key={i.toString()}
                    value={car._id}
                    className={car.active ? 'active' : 'inactive'}
                  >
                    {car.plateNumber} / {car.yearManufactured} / {car.make} / {car.model}
                  </option>
                ))}
              </SelectInput>
              {errors && errors.car !== '' ? (
                <p className="text-error font text-sm">{errors.car}</p>
              ) : null}
            </div>
       
            <div>
              <SelectInput
                label="Type of Invoice"
                name="invoiceType"
                value={data.invoiceType}
                onChange={e => updateState(e, 'invoiceType')}
                required={true}
                defaultOption="Select type of invoice"
                onBlur={onChangeError}
                // onkeypress={onChangeError}
                disabled={!isEditable && data.invoiceType === 'Autohound Repairs'}
              >
                {typesOfWork.map((work, i) => (
                  <option key={i.toString()} value={work}>
                    {work}
                  </option>
                ))}
              </SelectInput>
              {errors && errors.invoiceType !== '' ? (
                <p className="text-error font text-sm">{errors.invoiceType}</p>
              ) : null}
            </div>
            <div>
              <DateInput
                label="Service Date"
                name="date"
                value={moment(data.date).format('YYYY-MM-DD')}
                onChange={e => updateState(e, 'date')}
                // required={setRequirement(formRequirements.serviceDate)}
                requiredfield={true}
                min={handleMinDate()}
              />
              {errors && errors.date !== '' ? (
                <p className="text-error font text-sm">{errors.date}</p>
              ) : null}
            </div>
            <div>
              
              <TextInput
                label="Invoice Number"
                name="invoiceNumber"
                onChange={e => updateState(e, 'invoiceNumber')}
                value={data.invoiceNumber}
                // required={data.invoiceType === 'Autohound Repairs' ? true:false}
                requiredfield={setRequirement(formRequirements.mileage)}
                 required={false}
                 onBlur={data.invoiceType == 'Autohound Repairs' ? onChangeError :''}
              // onkeypress={data.invoiceType == 'Autohound Repairs' ? onChangeError :''}
              />
              {data.invoiceType == 'Autohound Repairs' && errors && errors.invoiceNumber !== '' ? (
                <p className="text-error font text-sm">{errors.invoiceNumber}</p>
              ) : null}
            </div>
            <div>
              <NumberInput
                label="Mileage"
                name="mileage"
                onChange={e => updateState(e, 'mileage')}
                value={data.mileage}
                requiredfield={setRequirement(formRequirements.mileage)}
                required={false}
                onBlur={data.invoiceType == 'Autohound Repairs' ? onChangeError :''}
              // onkeypress={data.invoiceType == 'Autohound Repairs' ? onChangeError :''}
              />
              {data.invoiceType == 'Autohound Repairs' && errors && errors.mileage !== '' ? (
                <p className="text-error font text-sm">{errors.mileage}</p>
              ) : null}
            </div>
          
            {data.invoiceType === 'Autohound Repairs' && (
              <>
                <div>
                  <SelectInput
                    label="Current Service Count"
                    name="oilChange"
                    value={data.oilChange}
                    onChange={e => updateState(e, 'oilChange')}
                    required={false}
                    requiredfield={true}
                    defaultOption="Select current oil change count"
                    onBlur={data.invoiceType == 'Autohound Repairs' ? onChangeError :''}
                    // onkeypress={data.invoiceType == 'Autohound Repairs' ? onChangeError :''}
                    disabled={!isEditable && data.invoiceType === 'Autohound Repairs'}
                  >
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((count, i) => (
                      <option key={i.toString()} value={count}>
                        {count}
                      </option>
                    ))}
                  </SelectInput>
                  {data.invoiceType == 'Autohound Repairs' && errors && errors.oilChange !== '' ? (
                    <p className="text-error font text-sm">{errors.oilChange}</p>
                  ) : null}
                </div>
                
                {isValid && (
                  <CurrencyInput
                    label="Service Discount"
                    onChange={e => updateState(e, 'oilChangeDiscount')}
                    value={data.oilChangeDiscount}
                    name="oilChangeDiscount"
                    step="0.01"
                  />
                )}
                {/* {invoiceData.oilChange == 10 && (
                  <SelectInput
                    label="Free oil change?"
                    name="freeOilChange"
                    value={invoiceData.freeOilChange}
                    onChange={e => updateState(e)}
                    required={false}
                    defaultOption="Choose Yes or No"
                    disabled={!isEditable && invoiceData.type === 'Autohound Repairs'}
                  >
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </SelectInput>
                )} */}
              </>
            )}
            <div className="flex flex-col w-full col-span-full">
              <TextArea
                label="Repair Description"
                name="repairDescription"
                onChange={e => updateState(e, 'repairDescription')}
                value={data.repairDescription}
                required={false}
                requiredfield={setRequirement(formRequirements.repairDescription)}
                onBlur={ onChangeError }
              // onkeypress={onChangeError}
                
              />
              {errors && errors.repairDescription !== '' ? (
                <p className="text-error font text-sm">{errors.repairDescription}</p>
              ) : null}
            </div>
            {isValid && (
              <CurrencyTotalAmountInput
                label="Discounts Applied"
                onChange={e => updateState(e)}
                value={data.discount}
                name="discount"
                // step="0.01"
              />
            )}
            <div>
            
              <CurrencyTotalAmountInput
                label="Total Amount"
                onChange={e => updateState(e, 'totalAmount')}
                value={data.totalAmount}
                name="totalAmount"
                requiredfield={setRequirement(formRequirements.totalAmount)}
                required={false}
                onBlur={data.invoiceType == 'Autohound Repairs' ? onChangeError :''}
              // onkeypress={data.invoiceType == 'Autohound Repairs' ? onChangeError :''}
                // step="0.01"
              />
              {errors && errors.totalAmount !== '' ? (
                <p className="text-error font text-sm">{errors.totalAmount}</p>
              ) : null}
            </div>
          </Row>
          <div className="text-right">
            <button onClick={handleOnCancelClick} className="px-5 py-3 mt-5 mx-2 w-36 text-white text-button rounded-sm bg-primary hover:bg-opacity-80">
                Cancel
            </button>
            <button
              type="submit"
              className="px-5 py-3 mt-5 w-36 text-white text-button rounded-sm bg-primary hover:bg-opacity-80"
            >
              {operation === 'add' ? 'Add Invoice' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

AdminInvoiceFields.propTypes = {
  cars: PropTypes.array,
  title: PropTypes.string,
  operation: PropTypes.string,
  handler: PropTypes.func,
  data: PropTypes.object,
  updateState: PropTypes.func,
  setAdminInvoice: PropTypes.func,
  adminInvoice: PropTypes.object,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
};

export default AdminInvoiceFields;