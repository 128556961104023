/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useState, createContext } from 'react';
import { Helmet } from 'react-helmet';
import { Table, Button, Checkbox, Select, Input, Tooltip, Modal, Pagination } from 'antd';
import { Link } from 'react-router-dom';
import { FiPlus, FiSearch, FiEdit2 } from 'react-icons/fi';
import { useHistory } from 'react-router';
import { set } from 'lodash-es';
import AdminAPIs from '../../../api/admin';
const DemoContext = createContext();
//import ExportReactCSV from '../../../../helpers/export';
import { ExclamationCircleOutlined, FilterOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { readFile, utils } from 'xlsx';
import {
  DownloadOutlined,
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
  SearchOutlined,
  EditOutlined,
} from '@ant-design/icons';

import PropTypes from 'prop-types';
// import SuperAdminFields from './superAdminFields';
import _ from 'lodash';
const Garage = () => {
  // const { sidebarContent, setRenderContent } = useContext(RenderContentContext);
  const history = useHistory();
  const [garages, setGarages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [garagesList, setGaragesList] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [collapse, setCollapse] = useState([]);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [table, setTable] = useState([]);
  const { Search } = Input;
  // const { Panel } = Collapse;
  const { Option, OptGroup } = Select;
  const suffix = (
    <SearchOutlined
      style={{
        fontSize: 16,
        color: '#F0734F',
      }}
    />
  );
  const [dataState, setDataState] = useState([]);
    const getGarageList = async options => {
      try {
        setLoading(true);
        const response = await AdminAPIs.getAllCustomer(options);
        const { customers } = response.data;
        setGaragesList(customers.customers.docs);

        // setTable(customers.customers.docs);
        setOptions({
          ...options,
          pagination: {
            current: response.data.customers.customers.page,
            pageSize: response.data.customers.customers.limit,
            total: response.data.customers.customers.totalDocs,
            showSizeChanger: true,
          },
        });
      } catch (error) {
        console.error('Customer Error', error);
      }
      setLoading(false);
    };
  const handleFile = async e => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = readFile(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsondata = utils.sheet_to_json(worksheet);
    setDataState(
      jsondata.map((e, i) => ({
        role: e.role,
        firstName: e.firstName,
        lastName: e.lastName,
        email: e.email,
        mobile: e.mobile,
      }))
    );
    setTable(
      jsondata.map((e, i) => ({
        role: e.role,
        firstName: e.firstName,
        lastName: e.lastName,
        email: e.email,
        mobile: e.mobile,
      }))
    );
  };
  const [options, setOptions] = useState({
    search: '',
    role: '',
    pagination: { current: 1, pageSize: 10, pageSizeOptions: [10, 20, 50] },
    sortFilters: {},
    start:'', 
    end: '',
    status:'',
  });
  const { confirm } = Modal;
  const columns = [
    {
      title: (
        <Checkbox
          onChange={e => onSelectAll(e)}
          checked={
            selectedRows.length > 0 &&
            _.isEqual(
              _.sortBy(garages.map(x => x._id)),
              _.sortBy(
                _.intersectionWith(
                  selectedRows,
                  garages.map(x => x._id),
                  _.isEqual
                )
              )
            )
          }
        />
      ),
      dataIndex: '_id',
      key: 'select',
      // eslint-disable-next-line react/display-name
      render: _id => <Checkbox onChange={e => onSelectRow(e, _id)} checked={selectedRows.includes(_id)} />,
    },

    // {
    //   title: 'User Type',
    //   dataIndex: 'role',
    //   key: 'role',
    //   sorter: true,
    // },

    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile',
      sorter: true,
    },
    {
      title: 'Garage',
      dataIndex: 'garages',
      key: 'garages',
      sorter: true,
      // eslint-disable-next-line react/display-name
      render: garages => <p>{garages[0]?.name}</p>,
    },
    {
      title: 'Actions',
      dataIndex: '_id',
      // eslint-disable-next-line react/display-name
      render: _id => (
        <>
          <Link to={`/admin/garage/edit/${_id}`} onClick={e => editOnClick(e, _id)}>
            <Tooltip placement="top" title="edit">
              <EditOutlined />
            </Tooltip>
          </Link>
          <Tooltip title="delete">
            <DeleteOutlined className="ml-2" onClick={e => showDeleteConfirm(e, _id)} />
          </Tooltip>
        </>
      ),
    },
  ];

  // const onSwitchChange = async (checked, status, id) => {
  //   try {
  //     const data = { status: checked };
  //     const response = await AdminAPIs.updateAdmin(id, data);
  //     getUserList(options);
  //   } catch (error) {
  //   }
  // };

  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'The deleted record will not be recovered.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
        setGaragesList(options)
         setSelectedRows([]);
      },

      onCancel() {
         setSelectedRows([]);
      },
    });
  };
  const collapseShowHideFunc = key => {
    setCollapse(key);
  };
  const handleFilterClick = () => {
    setCollapse([]);
    getUserList(options);
    // getUserList({ ...options, pagination: { ...options.pagination, current: 1 } });
  };
  const handleClearAllClick = () => {
    setCollapse([]);
    const updatedOptions = {
      ...options,
      status: '',
      role: '',
      pagination: { ...options.pagination, current: 1 },
    };
    setOptions(updatedOptions);
    getUserList(updatedOptions);
  };
  const editOnClick = (e, user) => {};
  function handleChange(value) {}

  const getUserList = async options => {
    try {
      setLoading(true);
      const response = await AdminAPIs.getGarageOwners(options);
      const { owners } = response.data;
      // const { name } = response.data.owners.docs.garages;
      // let { count } = response.data.garage;
      setGarages(owners.docs);
      setTable(owners.docs);
      setOptions({
        ...options,
        pagination: {
          current: owners.page,
          pageSize: owners.limit,
          total: owners.totalDocs,
          showSizeChanger: true,
        },
      });
    } catch (e) {
      console.error('e');
    }
    setLoading(false);
  };

  useEffect(() => {
    getUserList(options);
  }, []);

  function onChange(pagination, filters, sorter, extra) {
    const updatedOptions = { ...options, pagination };
    setOptions(updatedOptions);
    getUserList({
      ...updatedOptions,
      sortFilters: {
        sortField: sorter?.columnKey,
        sortOrder: sorter?.order,
      },
    });
  }

  const onSelectAll = e => {
    if (e.target.checked) {
      const test = [...new Set([...selectedRows, garages.map(n => n._id)].flat())];
      setSelectedRows(test);
    } else {
      const selectedIds = selectedRows.filter(n => !garages.map(x => x._id).includes(n));
      setSelectedRows(selectedIds);
    }
  };
  const onSelectRow = async (e, _id) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, _id]);
    } else {
      const selectedIds = selectedRows.filter(n => n !== _id);
      setSelectedRows(selectedIds);
    }
  };

  const filterSortedTable = e => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    const query = e.target.value;
    const updatedOptions = {
      ...options,
      search: query.trim(),
      pagination: { ...options.pagination, current: 0 },
    };
    setOptions(updatedOptions);
    setTypingTimeout(() =>
      setTimeout(() => {
        getUserList(updatedOptions);
      }, 1000)
    );
  };
  return (
    <>
      {/* <Helmet>
        <script
          src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.x.x/dist/alpine.min.js"
          type="text/javascript"
        />
        <script src="http://localhost:3000/js/init-alpine.js" type="text/javascript" />
      </Helmet> */}
      <div className="filter--outer mb-4">
        <div className="d-flex top--heading1">
          <h2 className="text-2xl font-semibold text-gray-700 dark:text-gray-200">Garage</h2>
          <div className="right--btn">
            <button className="bg-primary hover:bg-opacity-80 py-2 px-4 text-white rounded-sm ">
              <Link to="/admin/add-garage">
                <FiPlus className="inline-block align-middle mr-2 text-base" />
                <p className="inline-block align-middle">Add Garage</p>
              </Link>
            </button>
          </div>
        </div>
        <div className="d-flex filter--wrap my-5">
          <div className="d-flex mb-3 mb-sm-0 flex-wrap gap-2">
            {/* <Search
              className="search--wrap mt-3 mt-sm-0"
              placeholder="input search text"
              enterButton="Search"
              size="large"
              addonBefore={suffix}
              onSearch={onSearch}
            /> */}
            <div className="del--icon">
              <DeleteOutlined />
            </div>
            {/* <Select defaultValue="lucy" style={{ width: 200 }} onChange={handleChange}>
              <OptGroup label="Manager">
                <Option value="jack">Jack</Option>
                <Option value="lucy">Lucy</Option>
              </OptGroup>
              <OptGroup label="Engineer">
                <Option value="Yiminghe">yiminghe</Option>
              </OptGroup>
            </Select> */}
          </div>
          <div className="d-flex">
            {/* <label className="custom-field mr-2">
              <input type="file" name="file" onChange={e => handleFile(e)} />
              <span className="placeholder ant-btn ant-btn-primary">
                <DownloadOutlined /> Import
              </span>
            </label> */}
            {/* <ExportReactCSV csvData={garages} fileName={'Users.csv'} /> */}
            <Search
              className="search--wrap input-search-box mt-0 mt-sm-0"
              style={{ width: '270px' }}
              placeholder="input search text"
              enterButton="Filter"
              size="large"
              addonBefore={suffix}
              onChange={e => filterSortedTable(e)}
              // onSearch={onSearch}
            />
          </div>
        </div>
      </div>
      <main className="overflow-y-auto main--table">
        <div className="grid mx-auto">
          <div className="w-full mb-8 overflow-hidden shadow-xs">
            <div className="w-full overflow-x-auto">
              <Table
                columns={columns}
                rowKey={record => record._id}
                dataSource={garages}
                onChange={() => onChange}
                // onChange={(page, newPageSize) => {
                //   // setPageSize(newPageSize);
                //   //setCurrent(pageSize !== newPageSize ? 1 : page);
                // }}
                pagination={options.pagination.total > 10 ? options.pagination : false}
                sortDirections={['ascend', 'descend']}
                loading={loading}
                scroll={{ scrollToFirstRowOnChange: true }}
              />
              {/* <Pagination
                current={current}
                pageSize={pageSize}
                onChange={(page, newPageSize) => {
                  setPageSize(newPageSize);
                  setCurrent(pageSize !== newPageSize ? 1 : page);
                  setOptions({
                    ...options,
                    pagination: {
                      current: page,
                      pageSize: newPageSize,
                      showSizeChanger: true,
                    },
                  });
                }}
                showSizeChanger={true}
                total={options.pagination.total}
              /> */}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Garage;
