/* eslint-disable prettier/prettier */
import React from 'react';
import { Link } from 'react-router-dom';

const LandingNavbar = () => (
  <div className="container">
    <div className="flex items-center justify-center float-left h-full py-0 ">
      <Link
        to="/"
        className="mr-4 font-extrabold bg-transparent text-primary text-h6 text-opacity-90 hover:text-opacity-100 active:opacity-80 disabled:bg-disabled"
      >
        <img
          className="w-32 main-logo"
          src={require('../../assets/autohound-logo-orange.png').default}
          alt="logo"
        />
        {/* <img className="w-32 main-logo" src={require('../../assets/whiteLogo.svg').default} alt="logo" /> */}
      </Link>
    </div>
    <div className="float-right py-0 mt-0 sm:mt-4  mt--0">
      <>
        <Link
          to="/signin"
          className="opacity-95 hover:opacity-100  mr-2 sm:mr-4 font-semibold px-3 sm:px-5 text-dark py-2 sm:py-3  rounded-sm bg-white bg-transparent text-button hover:text-opacity-80 active:text-accent-dark disabled:bg-disabled page"
        >
          Log In
        </Link>
        <Link
          className="top--btn font-semibold px-3 sm:px-5 text-white py-2 sm:py-3 rounded-sm bg-primary bg-transparent text-button hover:text-opacity-80 active:bg-primary-light disabled:bg-disabled opacity-95 hover:opacity-100"
          to="/signup"
        >
          Sign Up
        </Link>
      </>
    </div>
  </div>
);

export default LandingNavbar;
