/* eslint-disable prettier/prettier */
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import AppContext from '../../AppContext';

import Checkmark from '../../assets/icon/check.png';

const PlansSection = () => {
  const heading = `Choose the plan that fits your needs:`;
  const subHeading = `Select the RCPA license from our plans below`;
  const plansOffered = [
    {
      name: 'Basic Customer User',
      price: `$0`,
      subtitle: 'Print your personalized reports for top dollar resale value on your vehicle.',
      features: [
        'Auto Hound tracker report (view access only)',
        'Additional Vehicle Cost Report',
        'Collision & Modification Report',
        'Enhance Resale Value',
        'Reduce Carbon Footprint',
      ],
      disabled: false,
    },
    {
      name: 'Business User (Not Available)',
      price: `Price to be announced soon`,
      subtitle: 'Easily manage your loyalty program to maintain customer retention.',
      features: [
        'Basic Customer User License & Features',
        'Business Use Expense Tracking',
        'Industry Expense Comparison',
        'Time Save Management',
        'Affordable Business Plan options',
      ],
      disabled: true,
    },
    {
      name: 'Shop Owner (Not Available)',
      price: `Price to be announced soon`,
      features: [
        'Generate Additional Revenue',
        'Easily Managed Loyalty Program',
        'Retain Customer Retention',
        'Industry Repair Comparison',
        'Enhance any Business Management Software System',
      ],
      disabled: true,
    },
  ];

  const { setPlan } = useContext(AppContext);
  const history = useHistory();

  const updatePlan = plan => {
    setPlan(plan);
    history.push('/signup');
  };

  return (
    <div className="px-8 pb-16 bg-background" id="plans">
      <div className="container pt-10">
        <div className="flex flex-col items-center justify-center pt-5 pb-8">
          <h3 className="mb-2 font-bold text-h3 text-plans-heading">{heading}</h3>
          <p className="font-regular text-subtitle-1 text-gray-1">{subHeading}</p>
        </div>
        <div className="grid gap-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {plansOffered.map((plan, index) => (
            <div
              key={`${plan.name}-${index}`}
              className="flex flex-col p-10 mt-10 mb-2 bg-white shadow-xl rounded-md divide-y divide-gray-3"
            >
              <div>
                <h6 className="font-bold text-h6 text-plans-heading">{plan.name}</h6>
                <p className="text-sm">{plan.description}</p>
                <div className="inline-block w-full mt-12">
                  <h6 className="float-left font-semibold text-price text-text_grey">{plan.price}</h6>
                  {/* <p className="float-right mt-8 font-semibold text-subtitle-1 text-text">Monthly</p> */}
                </div>
              </div>
              <div>
                <h3 className="py-3 font-semibold text-subtitle-1 text-text">Features</h3>
                <div className="items-center">
                  {plan.features.map((feature, i) => (
                    <div key={`${i.toString()}`} className="w-full my-2">
                      <img src={Checkmark} className="float-left pt-0.5 mr-2" />
                      <p className="grid text-body-2 font-regular text-text">{feature}</p>
                    </div>
                  ))}
                </div>
                <div className="w-full flex-col justify-end">
                  {plan.disabled ? (
                    <button
                      className="w-full px-5 py-3 mt-8 text-gray bg-primary opacity-95 rounded-md font disabled:bg-gray-3 disabled:opacity-50 disabled:cursor-default hover:opacity-100"
                      onClick={() => updatePlan(plan.name)}
                      disabled={plan.disabled}
                    >
                      <p className="text-button">Choose plan</p>
                    </button>
                  ) : (
                    <button
                      className="w-full px-5 py-3 mt-8 text-white bg-primary opacity-95 rounded-md font disabled:bg-gray-3 disabled:opacity-50 disabled:cursor-default hover:opacity-100"
                      onClick={() => updatePlan(plan.name)}
                      disabled={plan.disabled}
                    >
                      <p className="text-button">Choose plan</p>
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlansSection;
