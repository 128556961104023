/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';

const DataCell = ({ label, text }) => (
  <td className=" text-text font-normal text-body-2">
    <p>
      <b>{label}: </b>
      {text}
    </p>
  </td>
);

DataCell.propTypes = {
  label: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DataCell;
