/* eslint-disable prettier/prettier */
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { DataCell, LinkRow } from '../../../components/Table';
import { FiEdit2 } from 'react-icons/fi';
import { Tooltip } from 'antd';
import { twoDecimalPlaceValue } from '../../../helpers/twoDecimalPlaceValue';
import { toInteger } from 'lodash';

const Invoice = ({ invoice }) => {
  const { id } = useParams();
  const date = new Date(invoice.date);
  const formattedDate = `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`;
  const editButton = invoice => {
    return (
      <td className="editbutton absolute right-32" title="Edit Invoice">
        <Link
          to={`/customer/${id}/invoice/${invoice._id}/edit`}
          className="float-right text-sm font-semibold text-subtext"
        >
          <Tooltip placement="topLeft" title="edit">
            <FiEdit2 className="text-base" />
          </Tooltip>
        </Link>
      </td>
    );
  };
  return (
    <LinkRow>
      <DataCell label="Date" text={formattedDate} />
      <DataCell label="Repair Description" text={invoice.repairDescription} />
      <DataCell label="Repair Total" text={`$${invoice.totalAmount ? invoice.totalAmount.toFixed(2) : 0}`} />
      <DataCell label="Invoice no" text={invoice.number} />
      <DataCell label="Mileage" text={toInteger(invoice.mileage.toString())} />
      <DataCell
        label="Discount received"
        text={`$${invoice.discount ? Math.abs(invoice.discount).toFixed(2) : 0}`}
      />
      <DataCell
        label="Service Discount"
        text={`$${
          invoice.oilChangeDiscount ? Math.round((invoice.oilChangeDiscount + Number.EPSILON) * 100) / 100 : 0
        }`}
      />
      <DataCell label="Work done" text={invoice.type} />
      <DataCell label="Year" text={new Date(invoice.date).getUTCFullYear()} />
      {editButton(invoice)}
    </LinkRow>
  );
};

Invoice.propTypes = {
  invoice: PropTypes.object,
  customerId: PropTypes.string,
};

export default Invoice;
