/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print';

import OnePage from '../../components/Pages/OnePage';
import { BasicError, PageError } from '../../components/Error';

import ReportTable from './ReportTable';

import UserAPIs from '../../api/user';
import ReportAPIs from '../../api/report';

import useTitle from '../../hooks/useTitle';
import { useCallback } from 'react';
import FormHeader from '../../components/FormHeader';
import CustomerExpenseBreakdown from './CustomerExpenseBreakdown';
import DiscountFormateBreakdown from './DiscoutFormateBreakdown'
import Loader from '../../components/Loader';
import { FiInfo } from 'react-icons/fi';
import { AiFillPrinter } from 'react-icons/ai';
import { Tooltip } from 'antd';

// import { isCurrentUserACustomer } from '../../utilities/authentication';

const Reports = () => {
  useTitle('Reports');

  const componentRef = React.createRef();
  const discountRef = React.createRef();
  const [chosenReport, setChosenReport] = useState(0);
  const [chosenCar, setChosenCar] = useState('');
  const [reports, setReports] = useState({});
  const [car, setCar] = useState({});
  const [error, setError] = useState({});
  const [customerDetails, setCustomerDetails] = useState({});
  const [customerExpenses, setCustomerExpenses] = useState({});
  const [openTotalSalesAndDiscountReport, setOpenTotalSalesAndDiscountReport] = useState('');
  const [openTotalDiscountReport, setOpenTotalDiscountReport] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [receiptMessage, setReceiptMessage] = useState(false);

  const ref = useRef(null);
  //const ref = React.createRef();
  const ref1 = useRef({
    car: chosenCar
  });
  let reportTabs = [
    'Autohound Report',
    'Additional Vehicle Cost Report',
    'Collision & Modification Report',
    // 'Business Use Tracker Report',
  ];

 

  const { id } = useParams();

  const getCarReports = useCallback(async () => {
    try {
      const response = await ReportAPIs.getCarReports(customerDetails?.userId?._id, chosenCar);
      setReports(response?.data?.reports);
    } catch (e) {
      const error = e?.response?.data?.error ?? e.message;
      setError({ message: error });
    }
  }, [customerDetails?.userId?._id, chosenCar]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await UserAPIs.getCustomer(id);
        const customerExpenseResponse = await ReportAPIs.getCustomerExpenses(
          response?.data?.user?.customerId._id
        );
        setCustomerExpenses({ ...customerExpenseResponse?.data.result });
        setCustomerDetails({ ...response?.data?.user?.customerId });
        setLoading(false);
      } catch (e) {
        setError(e);
        setLoading(false);
      }
    })();
  }, [id]);

  useEffect(() => {
    const hasChosenAVehicle = customerDetails?._id && chosenCar && chosenCar !== 'Select Car';
    if (hasChosenAVehicle) {
      getCarReports();
    }
  }, [chosenCar, customerDetails?._id, getCarReports]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });



  const handlePrintReports = useReactToPrint({
    content: () => {
      if (ref.current) {
        const tableStat = ref.current.cloneNode(true);
        const PrintElem = document.createElement('div');
        PrintElem.appendChild(tableStat);
        return PrintElem;
      }
      return null;
    }

  });
  if (isLoading) return <Loader />;

  const selectCar = (e)=>{
    setChosenCar(e.target.value)
    const matchedCar = customerDetails?.vehicles?.filter((data)=>{return (data._id===e.target.value)});
    setCar(matchedCar);    
  };
  return (
    <>
      <OnePage>
        {customerDetails?.vehicles?.length > 0 &&
        customerDetails?.vehicles?.filter(vehicle => vehicle?.invoices.length > 0).length > 0 ? (
          <div className="px-5 md:px-20 flex flex-col relative pt-10">
            <div className="flex justify-between my-2">
              <FormHeader title="Reports" backLink={`/customer/${id}`} />
            </div>

            <div className={`table_check_wrap ${openTotalDiscountReport}`}>
              <div className="check_outer d-flex items-center w-100">
                <input
                  className="styled-checkbox"
                  id="styled-checkbox-4"
                  type="checkbox"
                  value="value2"
                  onChange={e => {
                    e.target.checked
                      ? setOpenTotalDiscountReport('open')
                      : setOpenTotalDiscountReport('');
                  }}
                />
                <label htmlFor="styled-checkbox-4">Discount format</label>
              </div>
              <div>
                <DiscountFormateBreakdown ref={discountRef} />
              </div>
            </div>

            <div className={`table_check_wrap ${openTotalSalesAndDiscountReport}`}>
              <div className="check_outer d-flex items-center w-100">
                <input
                  className="styled-checkbox"
                  id="styled-checkbox-2"
                  type="checkbox"
                  value="value2"
                  onChange={e => {
                    e.target.checked
                      ? setOpenTotalSalesAndDiscountReport('open')
                      : setOpenTotalSalesAndDiscountReport('');
                  }}
                />
                <label htmlFor="styled-checkbox-2">Autohound Total Sales and Discount Breakdown</label>
                {openTotalSalesAndDiscountReport == 'open' ? (
                  <>
                           <Tooltip placement="topLeft" title="print">
                  <button
                    className="bg-primary hover:bg-opacity-80 p-3 text-white text-sm rounded-r-sm ml-auto"
                    onClick={handlePrint}
                  >
                    <AiFillPrinter />
                  </button>
                  </Tooltip>
                  </>
                ) : (
                  ''
                )}
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 pt-5">
                <div className="p-4 text-text bg-white hover:bg-opacity-80 text-center">
                  <div className="font-bold">Autohound Total Fleet Expense</div>
                  <div className="text-primary">{`$${customerExpenses.lifetimeAmount}`}</div>
                </div>
                <div className="p-4 text-text bg-white hover:bg-opacity-80 text-center">
                  <div className="font-bold">Autohound Total Discounts</div>
                  <div className="text-primary">{`$${customerExpenses.lifetimeDiscount}`}</div>
                </div>
                <div className="p-4 text-text bg-white hover:bg-opacity-80 text-center">
                  <div className="font-bold">Autohound Total Discounted Services</div>
                  <div className="text-primary">{`$${customerExpenses.lifetimeOilChange}`} </div>
                </div>
              </div>
              <div>
                <CustomerExpenseBreakdown reports={customerExpenses} ref={componentRef} />
              </div>
            </div>
            <select
              className="p-4 my-4 appearance-none cursor-pointer bg-secondary text-text hover:bg-opacity-80 custom-downarrow"
              defaultValue={chosenCar}
              onChange={(e)=>selectCar(e)}
            >
              <option>Select Car</option>
              {customerDetails.vehicles.map((car, i) => (
                <option key={i.toString()} value={car._id}>
                  {car.plateNumber} / {car.yearManufactured} / {car.make} / {car.model} / {car.vin}
                </option>
              ))}
            </select>
            <p className='select-car'>  Please select a car to view report.</p>
              
            <div className="d-flex items-center" style={{ columnGap: '15px' }}>
              <div className={`w-100 grid sm:grid-cols-1 md:grid-cols-${reportTabs.length} gap-4`}>
                {reportTabs.map((tab, i) => (
                  <div key={i.toString()} className={'d-flex items-center'}>
                    <button
                      key={i.toString()}
                      onClick={() => {
                        setChosenReport(i);
                        tab !== 'Autohound Report' ? setReceiptMessage(true) : setReceiptMessage(false);
                      }}
                      className="p-4 text-text bg-secondary hover:bg-opacity-80 w-100 text-left"
                    >
                      {tab}
                    </button>
                    <Tooltip placement="topLeft" title="print">
                    <button
                      key={i.toString()}
                      className={
                        chosenReport == i
                          ? 'p-4 text-text bg-secondary hover:bg-opacity-80'
                          : 'p-4 text-text bg-secondary hover:bg-opacity-80 cursor-not-allowed'
                      }
                      style={{ height: '53px', background: '#dbded6' }}
                      onClick={() => {
                        if (chosenReport == i) {
                          handlePrintReports();
                        }
                      }}
                    >
                      <AiFillPrinter />
                    </button>
                    </Tooltip>
                  </div>
                ))}
              </div>
            </div>
            {receiptMessage && (
              <div className="report-message text-primary font-bold">
                <FiInfo />
                Receipts should be provided for accuracy of data!
              </div>
            )}
            {Object.entries(reports)?.length > 0 ? (
              <ReportTable reports={reports} chosenReport={chosenReport} carDetails ={car.length >0 && car[0]} receiptMessage={receiptMessage} ref={ref}  />
            ) : (
              <BasicError error={error} />
            )}
          </div>
        ) : (
          <PageError message="Cannot generate reports: There are no vehicles and/or invoices found" />
        )}
      </OnePage>
    </>
  );
};

Reports.propTypes = {
  cars: PropTypes.array,
};

export default Reports;
