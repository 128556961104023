/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

import PrivateRoute from './routes/PrivateRoute';
import AdminRoute from './routes/AdminRoute';
import PublicRoute from './routes/PublicRoutes';

import Profile from './containers/Customer/Profile';
import AdminDashboard from './containers/Admin/Dashboard';
import Dashboard from './containers/Dashboard/Dashboard';
import AddCustomer from './containers/Customer/AddCustomer';
import EmailLogs from './containers/Customer/EmailLogs';
import { AddInvoice, EditInvoice } from './containers/Customer/Invoice';
import EditCustomer from './containers/Customer/EditCustomer';
import CustomerProfile from './containers/Customer/Profile';
import AddVehicle from './containers/Vehicle/AddVehicle';
import EditVehicle from './containers/Vehicle/EditVehicle';

import Signup from './containers/Signup/Signup';
import Signin from './containers/Signin/Signin';
import LandingPage from './containers/LandingPage/LandingPage';
import Reports from './containers/Reports/Reports';
import NoMatch from './containers/NoMatch';

import ForgotPassword from './containers/ForgotPassword/ForgotPassword';
import VerifyPasscode from './containers/ForgotPassword/VerifyPasscode';
import EnterNewPassword from './containers/ForgotPassword/EnterNewPassword';
import EditGarageOwner from './containers/SuperAdmin/Users/GarageOwner/editGarageOwner';
import Navbar from './components/Navbar/Navbar';

import AppContext from './AppContext';
import ResetPassword from './containers/ResetPassword/resetPassword';
import UserFields from './containers/SuperAdmin/UserFields';
import SuperAdminFields from './containers/SuperAdmin/Users/SuperAdmin/superAdminFields';
import AddSuperAdmin from './containers/SuperAdmin/Users/SuperAdmin/addSuperadmin';
import EditSuperAdmin from './containers/SuperAdmin/Users/SuperAdmin/editSuperAdmin';
import CustomerFields from './containers/SuperAdmin/Users/Customer/customerFields';
import Customer from './containers/SuperAdmin/Users/Customer/customer';
import AddCustomers from './containers/SuperAdmin/Users/Customer/addCustomer';
import SuperAdmin from './containers/SuperAdmin/Users/SuperAdmin/superAdmin';
import GarageOwner from './containers/SuperAdmin/Users/GarageOwner/garageOwner';
import BusinessUser from './containers/SuperAdmin/Users/BusinessUser/businessUser';
import AddBusinessUser from './containers/SuperAdmin/Users/BusinessUser/addBusinessUser';
import EditBusinessUser from './containers/SuperAdmin/Users/BusinessUser/editBusinessUser';
import Garages from './containers/SuperAdmin/Garages/garage';
import Vehicles from './containers/SuperAdmin/Vehicles/vehicle';
import Invoices from './containers/SuperAdmin/Invoices/invoice';
import AddGarageOwner from './containers/SuperAdmin/Users/GarageOwner/addGarageOwner';
import AllUsers from './containers/SuperAdmin/Users/AllUsers/allUsers';
import AddUser from './containers/SuperAdmin/Users/AllUsers/addUser';
import EditUser from './containers/SuperAdmin/Users/AllUsers/editUser';
import EditCustomers from './containers/SuperAdmin/Users/Customer/editCustomer';
import ComingSoon from './containers/ComingSoon';
import AddGarage from './containers/SuperAdmin/Garages/addGarage';
import EditGarage from './containers/SuperAdmin/Garages/editGarage'
import SuperAdminDashboard from './containers/SuperAdmin/Dashboard/dashboard';
import AddAdminInvoice from './containers/SuperAdmin/Invoices/addInvoice';
import EditAdminInvoice from './containers/SuperAdmin/Invoices/editInvoice';
import AddAdminVehicle from './containers/SuperAdmin/Vehicles/addVehicles';
import EditAdminVehicle from './containers/SuperAdmin/Vehicles/editVehicle';
import Settings from './containers/SuperAdmin/Settings/settings';
import UserAPIs from './api/user';

import { getTokenPayload } from './utilities/token';


const App = () => {
  const [plan, setPlan] = useState('');
  const [currentUser, setCurrentUser] = useState(false);
  const navigate = useHistory();
  const [editProfile, setEditProfile] = useState({
    firstName: "",
    lastName: "",
    profilePic:""
});
const [profileImage, setProfileImage] = useState('');
const [phone, setPhone] = useState('')
const { id} = getTokenPayload();

  let updatedUserData = ()=>{

    (async () => {
      try {
        if(id)
       {
        const { data } = await UserAPIs.getUser(id);
        const { user } = data;
        setEditProfile({
          ...editProfile,
          firstName: user.firstName,
          lastName: user.lastName,
          profilePic:user.profilePic
        })
        setProfileImage(`${process.env.REACT_APP_S3_BUCKET_URL}${user.profilePic}`)
        setPhone(user.mobile || "");
       }
        
      } catch (e) {
        console.log(e);
      }
    })();
  }
  


const onBackButtonEvent = (e) => {
  if (window.location.pathname === '/signin') {
    if (localStorage.getItem("token") !== null) {
      return navigate.push('/');
    }
  }
}

useEffect(() => {
  if (window.location.pathname === '/signin') {
    if (localStorage.getItem('token') !== null) {
    return navigate.push('/');
    }
  }
},[])

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);  
    };
  }, []);
  return (
    <AppContext.Provider value={{ plan, setPlan, currentUser, setCurrentUser ,updatedUserData,editProfile,profileImage}}>
      {/* <Navbar /> */}
      <Switch>
        <PrivateRoute exact path="/profile" component={Profile} />
        {/* <AdminRoute exact path="/admin/dashboard" component={AdminDashboard} /> */}
        <AdminRoute exact path="/admin/dashboard" component={SuperAdminDashboard} />
        <AdminRoute exact path="/admin/user-fields" component={UserFields} />
        <AdminRoute exact path="/coming-soon" component={ComingSoon} />
        <AdminRoute exact path="/super-admin/all-users" component={AllUsers} />
        <AdminRoute exact path="/super-admin/add-user" component={AddUser} />
        <AdminRoute exact path="/super-admin/all-users/edit/:id" component={EditUser} />
        <AdminRoute exact path="/super-admin/customer" component={Customer} />
        <AdminRoute exact path="/super-admin/customer/add" component={AddCustomers} />
        <AdminRoute exact path="/super-admin/customer/edit/:id" component={EditCustomers} />
        <AdminRoute exact path="/super-admin" component={SuperAdmin} />
        <AdminRoute exact path="/super-admin/add" component={AddSuperAdmin} />
        <AdminRoute exact path="/super-admin/edit/:id" component={EditSuperAdmin} />
        <AdminRoute exact path="/super-admin/garage-owner" component={GarageOwner} />
        <AdminRoute exact path="/super-admin/garage-owner/add" component={AddGarageOwner} />
        <AdminRoute exact path="/super-admin/garage-owner/edit/:id" component={EditGarageOwner} />
        <AdminRoute exact path="/super-admin/add-garage" component={AddGarage} />
        <AdminRoute exact path="/super-admin/garage/edit/:id" component={EditGarage} />
        <AdminRoute exact path="/super-admin/business-user" component={BusinessUser} />
        <AdminRoute exact path="/super-admin/add-businessUser" component={AddBusinessUser} />
        <AdminRoute exact path="/super-admin/business-users/edit/:userId" component={EditBusinessUser} />
        <AdminRoute exact path="/super-admin/garages" component={Garages} />
        <AdminRoute exact path="/super-admin/vehicles" component={Vehicles} />
        <AdminRoute exact path="/super-admin/invoices" component={Invoices} />
        <AdminRoute exact path="/super-admin/invoice/add" component={AddAdminInvoice} />
        <AdminRoute exact path="/super-admin/invoices/edit/:id" component={EditAdminInvoice} />
        <AdminRoute exact path="/admin/all-users" component={AllUsers} />
        <AdminRoute exact path="/admin/add-user" component={AddUser} />
        <AdminRoute exact path="/admin/all-users/edit/:id" component={EditUser} />
        <AdminRoute exact path="/admin/customer" component={Customer} />
        <AdminRoute exact path="/admin/customer/add" component={AddCustomers} />
        <AdminRoute exact path="/admin/customer/edit/:id" component={EditCustomers} />
        <AdminRoute exact path="/admin" component={SuperAdmin} />
        <AdminRoute exact path="/admin/add" component={AddSuperAdmin} />
        <AdminRoute exact path="/admin/edit/:id" component={EditSuperAdmin} />
        <AdminRoute exact path="/admin/garage-owner" component={GarageOwner} />
        <AdminRoute exact path="/admin/garage-owner/add" component={AddGarageOwner} />
        <AdminRoute exact path="/admin/garage-owner/edit/:id" component={EditGarageOwner} />
        <AdminRoute exact path="/admin/add-garage" component={AddGarage} />
        <AdminRoute exact path="/admin/garage/edit/:id" component={EditGarage} />
        <AdminRoute exact path="/admin/business-user" component={BusinessUser} />
        <AdminRoute exact path="/admin/add-businessUser" component={AddBusinessUser} />
        <AdminRoute exact path="/admin/business-users/edit/:userId" component={EditBusinessUser} />
        <AdminRoute exact path="/admin/garages" component={Garages} />
        <AdminRoute exact path="/admin/vehicles" component={Vehicles} />
        <AdminRoute exact path="/admin/vehicle/add" component={AddAdminVehicle} />
        <AdminRoute exact path="/admin/vehicle/edit/:id" component={EditAdminVehicle} />
        <AdminRoute exact path="/admin/invoices" component={Invoices} />
        <AdminRoute exact path="/admin/settings" component={Settings} />
        <PrivateRoute exact path="/settings" component={Settings} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/customer/add" component={AddCustomer} />
        <PrivateRoute exact path="/customer/:id" component={CustomerProfile} />
        <PrivateRoute exact path="/customer/:id/update" component={EditCustomer} />
        <PrivateRoute exact path="/customer/:customerId/vehicle/add" component={AddVehicle} />
        <PrivateRoute exact path="/customer/:userId/invoice/add" component={AddInvoice} />
        <PrivateRoute exact path="/customer/:userId/invoice/:invoiceId/edit" component={EditInvoice} />
        <PrivateRoute exact path="/customer/:customerId/vehicle/:vehicleId/update" component={EditVehicle} />
        <PrivateRoute exact path="/reports/:id" component={Reports} />
        <PrivateRoute exact path="/user/reset-password" component={ResetPassword} />
        <PrivateRoute exact path="/email/emailLog" component={EmailLogs} />
        <PublicRoute exact path="/" component={LandingPage} />
        <PublicRoute exact path="/signup" component={Signup} />
        <PublicRoute exact path="/signin" component={Signin} />
        <PublicRoute exact path="/forgot-password/" component={ForgotPassword} />
        <PublicRoute exact path="/forgot-password/verify/:email" component={VerifyPasscode} />
        <PublicRoute exact path="/forgot-password/update-password" component={EnterNewPassword} />
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </AppContext.Provider>
  );
};

export default App;
