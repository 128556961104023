/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import VehicleAPIs from '../../api/vehicle';

import VehicleFields from './VehicleFields';
import { useAlert } from 'react-alert';
import { handleErrorMessages, handleSuccessMessages } from '../../helpers/flashMessages';

const AddVehicle = () => {
  const [error, setError] = useState(null);
  const { customerId } = useParams();
  const history = useHistory();
  const alert = useAlert();

  const addVehicle = async body => {
    body.make = body.make.trim();
    body.model = body.model.trim();
    // body.engine = body.engine.trim();
    try {
      const response = await VehicleAPIs.addVehicle(body, customerId);
      handleSuccessMessages(response, alert);
      history.push(`/customer/${customerId}`);
    } catch (error) {
      const message = handleErrorMessages(error, alert);
      setError(message);
      console.error(message);
    }
  };

  return (
    <VehicleFields title="Add A Vehicle" operation="add" submitHandler={addVehicle} parentError={error} />
  );
};

export default AddVehicle;
