/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';

const BasicError = ({ error, customError }) => {
  const errorMessage = customError?.length > 0 ? customError : error?.message;

  return (
    <div className="place-self-center my-2">
      <p className="text-error font-semibold text-sm">{errorMessage}</p>
    </div>
  );
};

BasicError.propTypes = {
  customError: PropTypes.string,
  error: PropTypes.object,
};

export default BasicError;
