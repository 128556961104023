/* eslint-disable prettier/prettier */
import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FiPlus, FiSearch } from 'react-icons/fi';
import InvoiceCarModel from './InvoiceCarModel';
import { AiFillPrinter } from 'react-icons/ai';
import { Tooltip } from 'antd';
import _ from 'lodash';
import { useReactToPrint, ReactToPrint } from 'react-to-print';

const Invoices = ({ invoices,vehicles }) => {
  const refs = useRef([]);
  const { id } = useParams();
  const [sortedTable, setSortedTable] = useState({});
  const [printIndex, setPrintIndex] = useState('');
  const [changeIndex, setChangeIndex] = useState(false);

  useEffect(() => {
    setSortedTable(invoices);
  }, [invoices]);
  
  useEffect(() => {
    if(printIndex || printIndex === 0){
    handlePrintSync();
    }
  },[printIndex, changeIndex]);


  const filterSortedTable = e => {
    const query = e.target.value;
    const sorted = getInvoicesWithMatchingSubstrings(query);
    setSortedTable(sorted);
  };
  const handlePrint = (index) => {
    setPrintIndex(index);
    setChangeIndex(!changeIndex);
  }
  const handlePrintSync = useReactToPrint({
    content: () => refs.current[printIndex],
  });

  const getInvoicesWithMatchingSubstrings = substring => {
    return _.pickBy(
      invoices,
      (value, key) =>
        key.split('/')[1].toUpperCase().includes(substring.toUpperCase()) ||
        key.split('/')[5].toUpperCase().includes(substring.toUpperCase())
    );
  };
  const vehicleDetailsForInvoice = (vehicleId) => {
    return vehicles.find((vehicle) => vehicle._id === vehicleId);
  };
  return (
    <div className="mt-16">
      <div className="flex flex-col">
        <div className="items-center">
          <div className="float-left my-2">
            <h6 className="font-bold text-h6 text-text">Invoices</h6>
          </div>
          <div className="float-right">
            <div className="float-left flex flex-row mr-3 sm:mb-2 mb-2">
              <span className="z-10 absolute text-center text-subtext absolute bg-transparent rounded text-base items-center justify-center w-8 p-2.5 h-100">
                <FiSearch className="text-sm" />
              </span>
              <input
                onChange={e => filterSortedTable(e)}
                name="invoice"
                placeholder="Enter VIN/License number"
                className="pl-8 py-2 px-4 text-gray-1 font-normal rounded-r-none"
              />
            </div>
            <button className="px-4 py-2 text-white rounded-sm bg-primary hover:bg-opacity-80">
              <Link to={`/customer/${id}/invoice/add`}>
                <FiPlus className="inline-block mr-2 text-base align-middle" />
                <p className="inline-block align-middle">Add Invoice</p>
              </Link>
            </button>
          </div>
        </div>
        <div className="flex flex-col custom_scroll w-100">
          {Object.entries(sortedTable)?.length > 0 ? (
            <>
              {Object.keys(sortedTable).map((carModel, i) => (
                <div className='d-flex mt-4' key={i.toString()}>
                <InvoiceCarModel
                  invoicesForTheCarModel={sortedTable[carModel]}
                  carModel={carModel}
                  vehiclesDetail={
                    vehicleDetailsForInvoice(
                      sortedTable[carModel][0]?.vehicleId 
                    )
                  }
                  ref={(carModel) => (refs.current[i] = carModel)}
                /> 
                  <Tooltip placement="topLeft" title="print">
                 <button
                  className="bg-primary hover:bg-opacity-80 p-3 text-white text-sm rounded-r-sm ml-auto"
                  onClick={() =>  handlePrint(i)}
                >
                  <AiFillPrinter />
                </button>
                </Tooltip>
                </div>
              ))}
            </>
          ) : (
            <>
              <h1 className="text-text">No invoices found.</h1>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

Invoices.propTypes = {
  invoices: PropTypes.object,
  customerId: PropTypes.string,
  vehicles: PropTypes.object,
};

export default Invoices;
