/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SelectInput from '../../components/SelectInput';
import { FiEdit2, FiSave, FiXSquare } from 'react-icons/fi';
import ReportAPIs from '../../api/report';
import { isCurrentUserAnAdmin, isCurrentUserAnOwner } from '../../utilities/authentication';
import { twoDecimalPlaceValue } from '../../helpers/twoDecimalPlaceValue';

const ExpenseBreakdownTableData = ({ report }) => {
  const [edit, setEdit] = useState(false);
  const [discountLevel, setDiscountLevel] = useState(report?.discountLevel);
  const [reportData, setReportData] = useState({ ...report });
  const discountLevels = ['A', 'B', 'C', 'D'];
  const updateState = e => {
    let value = e.target.value;
    setDiscountLevel(value);
  };

  const saveDiscountLevel = async () => {
    const response = await ReportAPIs.updateDiscountLevel(report.id, { discountLevel });
    if (response.status === 200) {
      setReportData({ ...reportData, discountLevel: response.data.discountLevel });
      setEdit(!edit);
    }
  };
  const cancelDiscountLevel = async () => {
    setDiscountLevel(reportData?.discountLevel);
    setEdit(!edit);
  };

  return (
    <tr id={reportData?.id} className="bg-white border-b hover:bg-gray-4 border-secondary">
      <td className="p-5 font-normal text-center text-gray-1 text-body-2">{reportData?.year}</td>
      <td className="p-5 font-normal text-center text-gray-1 text-body-2">
        ${twoDecimalPlaceValue(reportData?.total.toString()) || 0}
      </td>
      <td className="p-5 font-normal text-center text-gray-1 text-body-2 flex justify-center">
        {edit ? (
          <>
            <div className="app_form">
              <SelectInput
                label=""
                name="discountLevel"
                value={discountLevel}
                onChange={e => updateState(e)}
                defaultOption="-"
              >
                {discountLevels.map((level, i) => (
                  <option key={i.toString()} value={level}>
                    {level}
                  </option>
                ))}
              </SelectInput>
            </div>
            <div className="action-buttons py-4">
              <FiSave
                className="inline-block align-right ml-1 text-xl"
                title="Save"
                onClick={e => saveDiscountLevel(e)}
              />
              <FiXSquare
                className="inline-block align-right ml-1 text-xl"
                title="Cancel"
                onClick={e => cancelDiscountLevel(e)}
              />
            </div>
          </>
        ) : (
          <div>
            {reportData?.discountLevel || '-'}
            {(isCurrentUserAnAdmin() || isCurrentUserAnOwner()) && (
              <FiEdit2
                className="inline-block align-middle ml-1 text-base"
                title="Edit"
                onClick={() => setEdit(!edit)}
              />
            )}
          </div>
        )}
      </td>
      <td className="p-5 font-normal text-center text-gray-1 text-body-2">
        ${twoDecimalPlaceValue(reportData?.discount.toString()) || 0}
      </td>
      <td className="p-5 font-normal text-center text-gray-1 text-body-2">
        ${twoDecimalPlaceValue(reportData?.oilChangeDiscount.toString()) || 0}
      </td>
    </tr>
  );
};

ExpenseBreakdownTableData.propTypes = {
  report: PropTypes.object,
};

export default ExpenseBreakdownTableData;
